import React, { useState } from "react";
import { IoHomeSharp } from "react-icons/io5";
import {
  ActionBtnUser,
  AddAddressContainer,
  AddRegisterBox,
  AddressContainer,
  AddressUserContainer,
} from "../styles/MyUserStyles";
import { InputForm, LabelForm, SelectForm } from "../styles/LoginStyles";

export const MyAddress = () => {
  const [addAddress, setAddAddress] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("address");
  };

  const handleCancel = (e) => {
    setAddAddress(!addAddress);
  };

  return (
    <AddressUserContainer>
      {addAddress ? (
        <AddressContainer>
          <h3>
            Mis direcciones | <span>Agregar direcciones</span>
          </h3>
          <AddRegisterBox>
            <div className="block">
              <LabelForm htmlFor="fullname">
                Nombre de la dirección: <span>*</span>
              </LabelForm>
              <InputForm type="text" name="fullname" id="fullname" />
            </div>
            <div>
              <LabelForm htmlFor="email">
                Región / Departamento <span>*</span>
              </LabelForm>
              <SelectForm type="email" name="email" id="email">
                <option value="">Elegir una opción</option>
                <option value="DNI">DNI</option>
                <option value="RUC">RUC</option>
              </SelectForm>
            </div>
            <div>
              <LabelForm htmlFor="Provincia">
                Provincia <span>*</span>
              </LabelForm>
              <SelectForm name="Provincia" id="Provincia">
                <option value="">Elegir una opción</option>
                <option value="DNI">DNI</option>
                <option value="RUC">RUC</option>
              </SelectForm>
            </div>
            <div>
              <LabelForm htmlFor="district">
                Distrito <span>*</span>
              </LabelForm>
              <SelectForm name="district" id="district">
                <option value="">Elegir una opción</option>
                <option value="DNI">DNI</option>
                <option value="RUC">RUC</option>
              </SelectForm>
            </div>
            <div>
              <LabelForm htmlFor="address">
                Dirección <span>*</span>
              </LabelForm>
              <SelectForm name="address" id="address">
                <option value="">Elegir una opción</option>
                <option value="DNI">DNI</option>
                <option value="RUC">RUC</option>
              </SelectForm>
            </div>
            <div>
              <LabelForm htmlFor="cod">Código Postal</LabelForm>
              <InputForm type="text" name="cod" id="cod" />
            </div>
            <div>
              <LabelForm htmlFor="phone">
                Número de teléfono <span>*</span>
              </LabelForm>
              <InputForm type="number" name="phone" id="phone" />
            </div>
            <div>
              <LabelForm htmlFor="type">
                Tipo de documento <span>*</span>
              </LabelForm>
              <SelectForm name="type" id="type">
                <option value="">Elegir una opción</option>
                <option value="DNI">DNI</option>
                <option value="RUC">RUC</option>
              </SelectForm>
            </div>
            <div>
              <LabelForm htmlFor="dni">
                Número de documento <span>*</span>
              </LabelForm>
              <InputForm type="number" name="dni" id="dni" />
            </div>
            <div className="block">
              <LabelForm htmlFor="reference">
                Referencias de la ubicación:
              </LabelForm>
              <InputForm type="text" name="reference" id="reference" />
            </div>
            <div className="btn">
              <ActionBtnUser $sendData={true}>
                <button onClick={handleSubmit}>Guardar</button>
              </ActionBtnUser>
            </div>
            <div className="btn secundary">
              <ActionBtnUser>
                <button onClick={handleCancel}>Cancelar</button>
              </ActionBtnUser>
            </div>
          </AddRegisterBox>
        </AddressContainer>
      ) : (
        <AddAddressContainer>
          <h3>Mis direcciones</h3>
          <p>No existen direcciones.</p>
          <button onClick={() => setAddAddress(!addAddress)}>
            <IoHomeSharp />
            <span>Agregar direcciones</span>
          </button>
        </AddAddressContainer>
      )}
    </AddressUserContainer>
  );
};
