import styled from "styled-components";

export const ContainerDetailProduct = styled.div`
  @media screen and (min-width: 1600px) {
    padding: 10% 20%;
  }
  padding: 10% 10%;
  margin-top: 155px;
  @media screen and (max-width: 900px) {
    margin: 20% 10%;
    margin-top: 155px;
    padding-top: 20%;
  }
  font-family: "Montserrat", sans-serif;
`;

export const DetailBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 5em;
  width: 100%;
  h2 {
    display: none;
  }
  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 1em;
    h2 {
      display: block;
      white-space: pre-line;
      text-align: center;
    }
  }
  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

export const DetailImage = styled.div`
  position: relative;
  img {
    border: 2px solid #d9d9d9;
    border-radius: 1em;
    padding: 4em 4em;
    object-fit: cover;
    max-width: 500px;
    @media screen and (max-width: 1100px) {
      max-width: 400px;
    }
    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
`;

export const ProductDataBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  @media screen and (max-width: 900px) {
    align-items: center;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    align-items: center;
  }
`;

export const DetailDataProduct = styled.div`
  h1 {
    color: #00cde8;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
    font-size: 2.25em;
  }
  h2 {
    display: block;
    font-weight: 600;
    font-size: 1.5em;
  }
  p {
    font-size: 0.9em;
    font-weight: 700;
    line-height: 18px;
  }
  div {
    text-align: justify;
    white-space: pre-line;
  }
  @media screen and (max-width: 950px) {
    h2 {
      display: none;
    }
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    div {
      text-align: center;
    }
  }
`;

export const DetailActionWish = styled.div`
  margin-top: 2em;
  width: 280px;
  .btn-wishlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em 0em;
    gap: 2em;
    padding-left: 2em;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    cursor: pointer;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    p {
      font-size: 0.94em;
      font-weight: 400;
    }
  }
`;

export const DetailActionPoruct = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #00cde8;
    color: #ffffff;
    padding: 0.5em 3em;
    border-radius: 4px;
    border: none;
    gap: 1em;
    p {
      font-weight: 700;
    }
    &.disabled {
      background-color: #d4d4d4;
    }
  }
`;

export const WhatsAppBtn = styled.button`
  margin-top: 1em;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.7em 1em;
  background-color: #21cf12;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  width: 100%;
  p {
    font-size: 0.95em;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: 0.1em;
  }
`;
export const MessageUpdate = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;

  span {
    width: 100%;
    background-color: #5352b8;
    color: #ffffff;
    font-weight: 700;
    padding: 0.5em 0;
    text-align: center;
    border-radius: 5px;
  }
`;

export const NoDataContainer = styled.div`
  height: 30vh;
`;
