import styled from "styled-components";
import Barra from "../assets/barra1.png";

export const OffersAndNewsContainer = styled.div`
  background-image: url(${Barra});
  height: auto;
  width: 100%;
  margin-bottom: 5em;
`;

export const BoxMain = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2em 0;
  h5 {
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 700;
  }
  @media screen and (max-width: 1400px) {
    h5 {
      padding: 0 1em;
    }
    padding: 2em 2em;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
`;

export const BoxSearch = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
  button {
    background-color: #00d2ed;
    padding: 1em;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: 1px solid #2e2daa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const SearchInput = styled.input`
  padding: 15px 20px;
  border: 1px solid #2e2daa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none;
  font-size: 1em;
  width: 100%;
`;
