import React, { useContext, useEffect, useState } from "react";
import { HeaderFooter } from "./HeaderFooter";
import {
  BtnCuponResumen,
  BtnPurchaseSummary,
  CheckItem,
  Checkout,
  ContainerPurchase,
  ContainerTab,
  PurchaseBox,
  PurchaseTotal,
  ShippingPrice,
  TabButton,
  TabContainer,
  TabContent,
  TabContentContainer,
} from "../styles/FinalizePurchaseStyles";
import { IoCart, IoHomeSharp } from "react-icons/io5";
import { IoStorefrontSharp } from "react-icons/io5";
import { HomeDelivery } from "../components/HomeDelivery";
import { ProductContext } from "../context/product/ProductProvider";
import { PurchaseSummary } from "../components/PurchaseSummary";
import { Link } from "react-router-dom";

export const ShippingMethodPurchase = () => {
  const [toggleState, setToggleState] = useState(1);
  const [costDelivery, setCostDelivery] = useState(true);
  const {
    listCart,
    orderCart,
    resetListOrder,
    have_coupon,
    handleDeleteCupon,
  } = useContext(ProductContext);

  const [userState, setUserState] = useState({
    department: "",
    province: "",
    district: "",
    dni: "",
    address: "",
    postal_code: "",
    celphone: "",
    reference: "",
    shipping_method: "DELIVERY",
  });

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (orderCart.length !== 0) {
      resetListOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showShipping, setShowShipping] = useState(true);

  const toggleTab = (index) => {
    setToggleState(index);
    if (index === 1) {
      setCostDelivery(true);
    } else {
      setCostDelivery(false);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = {
      department: "Departamento",
      province: "Provincia",
      district: "Distrito",
      dni: "DNI",
      address: "Dirección",
      postal_code: "Codigo postal",
      celphone: "Celular",
      reference: "Referencia",
    };

    const newErrors = {};

    // Iterar sobre los campos y establecer los que estén en blanco o no cumplan con la validación como obligatorios
    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (!userState[fieldName].trim()) {
        newErrors[fieldName] = `${fieldLabel} es obligatorio`;
      } else {
        // Aquí agregamos la validación específica para dni y teléfono
        if (fieldName === "dni" && userState[fieldName].length !== 8) {
          newErrors[fieldName] = `El ${fieldLabel} debe tener 8 dígitos`;
        }
        if (fieldName === "celphone" && userState[fieldName].length !== 9) {
          newErrors[fieldName] = `El ${fieldLabel} debe tener 9 dígitos`;
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
      setTimeout(() => {
        setErrors({});
      }, 5000);
      return;
    }
    if (!isChecked) {
      setErrors({
        accept_term: "Es obligatorio",
      });
      setTimeout(() => {
        setErrors({});
      }, 5000);
      return;
    }
    setShowShipping(false);
  };

  const handleFinishCart = (e) => {
    if (!costDelivery) {
      setUserState((prevState) => ({
        ...prevState,
        shipping_method: "TIENDA",
      }));
      setShowShipping(!showShipping);
    } else {
      setUserState((prevState) => ({
        ...prevState,
        shipping_method: "DELIVERY",
      }));
      handleSubmit(e);
    }
  };

  return (
    <HeaderFooter searchModal={true}>
      <ContainerPurchase>
        {showShipping ? (
          <div>
            <h3>¿Cómo te gustaría recoger tu compra?</h3>
            <PurchaseBox>
              <ContainerTab>
                <TabContainer className="block-tabs">
                  <TabButton
                    $isSelected={toggleState === 1}
                    onClick={() => toggleTab(1)}
                  >
                    <div>
                      <IoHomeSharp size={20} />{" "}
                      <span> Lo envíamos a tu casa</span>
                    </div>
                  </TabButton>
                  <TabButton
                    $isSelected={toggleState === 2}
                    onClick={() => toggleTab(2)}
                    $isLast={true}
                  >
                    <div className="store-buy">
                      <IoStorefrontSharp size={20} />{" "}
                      <span> Recógelo en la tienda</span>
                    </div>
                  </TabButton>
                </TabContainer>
                <TabContentContainer className="content-tabs">
                  <TabContent $isVisible={toggleState === 1}>
                    <HomeDelivery
                      userState={userState}
                      setUserState={setUserState}
                      errors={errors}
                      handleSubmit={handleSubmit}
                    />
                    <p className="term">
                      (*) Todos los envíos son hechos por Olva y tienen una
                      demora máxima de 24 a 48 horas en Lima y 3 a 7 días útiles
                      a provincia.
                    </p>
                  </TabContent>
                  <TabContent $isVisible={toggleState === 2}>
                    <ShippingPrice>
                      <p>
                        Precio de envío: &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                        <span>S/0</span>
                      </p>
                      <div>
                        <BtnPurchaseSummary
                          onClick={() => setShowShipping(!showShipping)}
                          disabled={!isChecked}
                        >
                          Siguiente{" "}
                        </BtnPurchaseSummary>
                      </div>
                    </ShippingPrice>
                    <p className="term">
                      (*) Revisa los campos en caso alguien más recoja la
                      compra. Nuestro horario de atención es de Lunes a Domingo
                      - 11am a 9pm.
                    </p>
                  </TabContent>
                </TabContentContainer>
              </ContainerTab>
              <PurchaseTotal>
                <div className="resumen">
                  <h4>Resumen del pedido:</h4>
                </div>
                <div className="price-total">
                  <h4>Subtotal: </h4>
                  <p>
                    S/
                    {listCart?.cart_items_json?.final_price +
                      listCart?.cart_items_json?.discount_with_coupon}
                  </p>
                </div>
                <div className="price-total">
                  <h4>Cupón: </h4>
                  <p>
                    {have_coupon ? (
                      <>
                        <BtnCuponResumen onClick={handleDeleteCupon}>
                          Eliminar x
                        </BtnCuponResumen>{" "}
                        &nbsp;&nbsp;&nbsp;-S/
                        {listCart?.cart_items_json?.discount_with_coupon}
                      </>
                    ) : (
                      "S/0"
                    )}
                  </p>
                </div>
                <div className="price-total">
                  <h4>Envio: </h4>
                  <p>
                    S/
                    {costDelivery
                      ? listCart?.cart_items_json?.shipping_payment
                      : 0}{" "}
                  </p>
                </div>
                <div className="price-total">
                  <h4>Comisión de tarjeta: </h4>
                  <p>
                    S/
                    {(
                      listCart?.cart_items_json?.final_price_with_card -
                      listCart?.cart_items_json?.final_price
                    )?.toFixed(2)}
                  </p>
                </div>
                <div className="price-total">
                  <h4>Total: </h4>
                  <p>
                    S/
                    {costDelivery
                      ? listCart?.cart_items_json?.final_price_with_card_and_ship?.toFixed(
                          2
                        )
                      : listCart?.cart_items_json?.final_price_with_card?.toFixed(
                          2
                        )}
                  </p>
                </div>
                <div className="resumen">
                  <CheckItem>
                    <div>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="checkbox">
                        <Link to={"/terms-and-conditions"} target="_blank">
                          Acepto los términos y condiciones
                        </Link>
                      </label>
                    </div>
                  </CheckItem>
                </div>

                {listCart?.cart_items_json?.cart_items.length !== 0 && (
                  <Checkout>
                    <BtnPurchaseSummary
                      disabled={!isChecked}
                      className="btn-price"
                      onClick={handleFinishCart}
                    >
                      <IoCart size={20} /> Terminar compra
                    </BtnPurchaseSummary>
                  </Checkout>
                )}
              </PurchaseTotal>
            </PurchaseBox>
          </div>
        ) : (
          <PurchaseSummary costDelivery={costDelivery} userState={userState} />
        )}
      </ContainerPurchase>
    </HeaderFooter>
  );
};
