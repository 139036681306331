/* eslint-disable react/prop-types */
import styled from "styled-components";

const TablaSinDatos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ $listProduct }) => ($listProduct ? "0" : "20%")};
  font-size: 1em;
  color: #5352b8;
  div {
    text-align: center;
  }
`;
export const NoData = ({
  text1 = "NO SE ENCONTRARON",
  text2 = "RESULTADOS",
  listProduct = false,
}) => {
  return (
    <TablaSinDatos $listProduct={listProduct}>
      <div>
        <h2>{text1} </h2>
        <h2>{text2} </h2>
      </div>
    </TablaSinDatos>
  );
};
