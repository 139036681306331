import React from "react";
import { DropdownMenu, MenuItem } from "../styles/HeaderStyles";
import { CiUser } from "react-icons/ci";
import { IoHomeOutline } from "react-icons/io5";
import { IoGiftOutline } from "react-icons/io5";
import { IoExitOutline } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";

export const DropDownUser = ({
  menuRef = null,
  cerrarSesion,
  userDataStyle = false,
}) => {
  const location = useLocation();
  const rutaMyData = location.pathname === "/my-user";
  const rutaMyNewPassword = location.pathname === "/my-user/change-password";
  const rutaMyOrders = location.pathname === "/my-user/orders";
  const rutaMyAddress = location.pathname === "/my-user/address";

  return (
    <DropdownMenu ref={menuRef} $userData={userDataStyle}>
      <MenuItem $espace={true} $activeLink={rutaMyData}>
        <Link to={userDataStyle ? "" : "/my-user"}>
          <CiUser size={20} /> <span>Mis datos</span>
        </Link>
      </MenuItem>
      <MenuItem $activeLink={rutaMyNewPassword}>
        <Link
          to={userDataStyle ? "change-password" : "/my-user/change-password"}
        >
          <CiLock size={20} /> <span>Contraseña</span>
        </Link>
      </MenuItem>
      <MenuItem $activeLink={rutaMyOrders}>
        <Link to={userDataStyle ? "orders" : "/my-user/orders"}>
          <IoHomeOutline size={20} /> <span>Mis pedidos</span>
        </Link>
      </MenuItem>
      {false && (
        <MenuItem $activeLink={rutaMyAddress}>
          <Link to={userDataStyle ? "address" : "/my-user/address"}>
            <IoGiftOutline size={20} /> <span>Direcciones</span>
          </Link>
        </MenuItem>
      )}
      <MenuItem $exit={true} $espace={true} onClick={cerrarSesion}>
        <Link to={"/"}>
          <p>
            <IoExitOutline size={20} /> Salir
          </p>
        </Link>
      </MenuItem>
    </DropdownMenu>
  );
};
