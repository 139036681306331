import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ProductContext } from "../context/product/ProductProvider";
import { Loading } from "./Loading";

const ContainerSearchDropDown = styled.div`
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 1em 0;
  width: 100%;
  /* display: ${(props) => (props.$isOpen ? "none" : "block")}; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 800px;
  overflow-y: auto; /* Habilita el desplazamiento vertical */
  @media screen and (max-width: 900px) {
    max-height: 200px;
    overflow-y: auto; /* Habilita el desplazamiento vertical */
    z-index: 999;
  }
`;

const ContainerList = styled.div`
  h3 {
    text-align: center;
    font-weight: 500;
    padding: 1em 0;
  }
`;

const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
  gap: 3em;
  a {
    text-decoration: none;
    color: #000000;
  }
  p {
    text-overflow: ellipsis;
    white-space: nowrap; /* Evita que el texto se ajuste automáticamente */
    overflow: hidden;
  }
  img {
    width: 40px;
  }
  &:hover {
    background-color: aliceblue;
    cursor: pointer;
  }
`;

const BtnLinkProducts = styled.div`
  margin-bottom: 0.5em;
  button {
    display: block;
    cursor: pointer;
    color: #ffffff;
    width: 100%;
    background-color: #262525;
    font-weight: bolder;
    text-decoration: none;
    padding: 0.5em 2em;
    text-align: center;
  }
`;

export const SearchDropDown = ({
  loadingSearch,
  handleRedirectAndScroll,
  handleRedirectProductSearch,
}) => {
  const { productList } = useContext(ProductContext);
  const [scrolling, setScrolling] = useState(false);
  return (
    <ContainerSearchDropDown>
      {loadingSearch ? (
        <Loading height={"30px"} loadingWidth={"20px"} />
      ) : (
        <ContainerList>
          {productList.length === 0 && <h3>No se encontraron resultados</h3>}
          {productList.map((product, index) => {
            if (index < 5) {
              return (
                <ProductItem
                  key={product.id}
                  onClick={() => handleRedirectProductSearch(product.slug)}
                  onTouchEnd={() => {
                    if (!scrolling) {
                      handleRedirectProductSearch(product.slug);
                    }
                  }}
                  onTouchMove={() => setScrolling(true)} // Establece scrolling a true cuando se está desplazando
                  onTouchStart={() => setScrolling(false)} // Establece scrolling a false cuando se toca
                >
                  <p>{product.title}</p>
                  <img src={product.image_principal} alt={product.title} />
                </ProductItem>
              );
            }
            return null;
          })}
          {productList.length > 5 && (
            <BtnLinkProducts>
              <button
                onClick={handleRedirectAndScroll}
                onTouchEnd={handleRedirectAndScroll}
              >
                Ver más
              </button>
            </BtnLinkProducts>
          )}
        </ContainerList>
      )}
    </ContainerSearchDropDown>
  );
};
