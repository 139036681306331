import React, { useContext, useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import {
  ContenedorLogin,
  Sesion,
  Form,
  FormBox,
  SearchInput,
  SearchIconEmail,
  SearchIconPassword,
  ButtonSubmit,
  RegisterBox,
  ErrorFormLogin,
} from "../styles/LoginStyles";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthProvider";
import { HeaderFooter } from "./HeaderFooter";

export const Login = () => {
  const navigate = useNavigate();

  const {
    iniciarSesion,
    mensaje,
    autenticado,
    ResetError,
    errorLogin,
    // usuarioAutenticado,
    usuarioData,
  } = useContext(AuthContext);

  useEffect(() => {
    if (autenticado) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autenticado, navigate, errorLogin]);

  const [usuario, setUsuario] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [errorMensaje, setErrorMensaje] = useState("");

  const { email, password } = usuario;
  const handleChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //validar que no haya campos vacios
    if (email.trim() === "" || password.trim() === "") {
      setError(true);
      setErrorMensaje("Todos los campos son obligatorios");
      setTimeout(() => {
        setError(false);
      }, 5000);
      return;
    }
    if (password.length < 5) {
      setError(true);
      setErrorMensaje("Password es incorrecta");
      setTimeout(() => {
        setError(false);
        setErrorMensaje("");
      }, 5000);
      return;
    }
    iniciarSesion({ email, password });
    setTimeout(() => {
      ResetError();
    }, 5000);
    if (usuarioData) {
      navigate("/");
    }
  };
  return (
    <HeaderFooter searchModal={true}>
      <ContenedorLogin>
        <Sesion>
          <h2>Área de ingreso</h2>
          <Form>
            <FormBox>
              <SearchIconEmail />
              <SearchInput
                name="email"
                type="text"
                placeholder="Correo o Usuario"
                id="email"
                value={email}
                onChange={handleChange}
                autoComplete="email"
              />
            </FormBox>
            <FormBox>
              <SearchIconPassword />
              <SearchInput
                type="password"
                id="password"
                name="password"
                placeholder="Contraseñas"
                value={password}
                onChange={handleChange}
                autoComplete="current-password"
              />
            </FormBox>
            <ButtonSubmit type="submit" onClick={handleSubmit}>
              <h4>Ingresar</h4>
            </ButtonSubmit>
            {errorLogin && (
              <ErrorFormLogin>
                {" "}
                <h3>{mensaje?.non_field_errors}</h3>{" "}
              </ErrorFormLogin>
            )}
            {error && (
              <ErrorFormLogin>
                {" "}
                <h3>{errorMensaje}</h3>{" "}
              </ErrorFormLogin>
            )}
          </Form>
          <RegisterBox>
            <Link to={"/register"}>
              <p>
                <BiSolidRightArrow /> Crear un nuevo usuario
              </p>
            </Link>

            <p>
              <BiSolidRightArrow /> Recuperar Contraseña
            </p>
          </RegisterBox>
        </Sesion>
      </ContenedorLogin>
    </HeaderFooter>
  );
};
