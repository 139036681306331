import styled from "styled-components";
import { MdEmail } from "react-icons/md";
import { IoLockClosed } from "react-icons/io5";

export const ContenedorLogin = styled.div`
  width: 100%;
  background: #ffffff;
  position: relative;
  display: grid;
  place-content: center;
  overflow-x: hidden;
  min-height: 70vh;
  margin-top: 155px;
  @media (max-width: 900px) {
    margin-top: 250px;
    min-height: 100%;
    margin-bottom: 5em;
  }
`;

export const Sesion = styled.div`
  background-color: #ffffff;
  padding: 100px 70px;
  box-shadow: 0px 0px 12px 4px #0000001a;
  border-radius: 5px;
  width: 33em;
  h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: #5352b8;
    text-align: center;
  }
  @media (max-width: 900px) {
    box-shadow: none;
    padding: 20px 80px;
    h2 {
      margin-bottom: 2em;
    }
  }
  @media (max-width: 500px) {
    padding: 10px;
    width: 100%;
    h2 {
      font-size: 25px;
      margin-bottom: 1em;
    }
  }
`;
export const Form = styled.form`
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media (max-width: 500px) {
    padding-top: 0em;
  }
`;
export const FormBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  width: 100%;
`;
export const ErrorFormLogin = styled.div`
  background: linear-gradient(0deg, #2e2daa, #2e2daa),
    linear-gradient(0deg, #5352b8, #5352b8);
  border: 1px solid #2e2daa;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border: 1px;
  h3 {
    padding: 10px 10px;
    text-align: center;
    color: #fff;
    font-size: 0.7em;
  }
  &.error-orders {
    background: red;
    word-wrap: break-word;
    width: 350px;
    margin: 0 auto;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 15px 20px;
  padding-left: 6em;
  border: 1px solid #2d4aaa;
  border-radius: 4px;
  outline: none;
  font-size: 0.625em;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
`;
export const SearchIconEmail = styled(MdEmail)`
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  color: #d4d4d4;
  cursor: pointer;
  font-size: 1.5em;
`;

export const SearchIconPassword = styled(IoLockClosed)`
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  color: #d4d4d4;
  cursor: pointer;
  font-size: 1.5em;
`;

export const ButtonSubmit = styled.button`
  background: linear-gradient(0deg, #2e2daa, #2e2daa),
    linear-gradient(0deg, #5352b8, #5352b8);
  border: 1px solid #2e2daa;
  color: white;
  padding: 10px 20px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border: 1px;

  h4 {
    padding: 0.2em 1.5em;
    font-weight: 700;
    font-size: 0.875em;
    text-align: center;
  }
`;

export const RegisterBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #878787;
  margin: 0.8em;
  a {
    text-decoration: none;
    color: #878787;
  }
  P {
    font-size: 0.625em;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
  }
  svg {
    width: 5px;
    height: 5px;
  }
`;

/* Registro */

export const ContenedorRegister = styled.div`
  width: 100%;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 155px;
`;

export const SesionRegister = styled.div`
  margin-top: ${(props) => (props.$myUserData ? "0em" : "7em")};
  margin-bottom: ${(props) => (props.$myUserData ? "0em" : "7em")};
  background-color: #ffffff;
  padding: ${(props) => (props.$myUserData ? "0" : "100px 70px")};
  border-radius: 5px;
  width: 46em;
  height: 100%;
  h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: #5352b8;
    text-align: center;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 700;
  }
  @media (max-height: 800px) {
    padding: 50px 30px;
    h2 {
      font-size: 25px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    padding: ${(props) => (props.$myUserData ? "20px 0px;" : "20px 40px")};
    h2 {
      font-size: 25px;
    }
  }
  .update-user {
    display: flex;
    justify-content: center;
    span {
      background-color: #5352b8;
      color: #ffffff;
      font-weight: 700;
      padding: 0.5em 2em;
      border-radius: 5px;
    }
  }
`;

export const FormRegister = styled.form`
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  width: 100%;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`;

export const LabelForm = styled.label`
  display: block;
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 10px;
  cursor: pointer;
  color: #878787;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  span {
    color: red;
  }

  &.error-form {
    color: red;
  }

  @media (max-height: 850px) {
    font-size: 15px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const InputForm = styled.input`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #2d4aaa;
  border-radius: 4px;
  outline: none;
  /* font-size: 0.625em; */
  font-size: 0.8em;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
`;

export const SelectForm = styled.select`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #2d4aaa;
  border-radius: 4px;
  outline: none;
  /* font-size: 0.625em; */
  font-size: 0.8em;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  color: #5352b8;
  option {
    cursor: pointer;
  }
`;

export const TextAreaForm = styled.textarea`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #2d4aaa;
  border-radius: 4px;
  outline: none;
  /* font-size: 0.625em; */
  font-size: 0.8em;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  color: #000000;
`;

export const BotonForm = styled.div`
  grid-column-start: 1;
  /* grid-column-end: 4; */
  grid-column-end: 3;
  display: grid;
  place-content: center;
  input {
    padding: 1.2em 10em;
    background: linear-gradient(0deg, #2e2daa, #2e2daa),
      linear-gradient(0deg, #5352b8, #5352b8);
    color: #fff;
    font-weight: 700;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-size: 0.875em;
    transition: background-color 0.3s ease all;
    &:hover {
      background: #3257db;
    }
  }
  @media screen and (max-width: 1540px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;
