import React, { useContext, useEffect, useState } from "react";
import * as Style from "../styles/FeaturedProductsStyles";
import { IoCart } from "react-icons/io5";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Keyboard, Navigation } from "swiper/modules";
// import Product1 from "../assets/Product1.png";
// import Product2 from "../assets/Product2.png";
// import Product3 from "../assets/Product3.png";
import { useWindowSize } from "../hooks/UseWindowSize";
import { ProductContext } from "../context/product/ProductProvider";
import { Link } from "react-router-dom";
import { StockStatus } from "./StockStatus";
import { ModalAddCart } from "./ModalAddCart";

export const FeaturedProducts = () => {
  const { width } = useWindowSize();
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState({});
  const { productFeatured, addCartItem } = useContext(ProductContext);
  useEffect(() => {
    let slidesToShow = 3;
    if (width <= 1300) {
      slidesToShow = 2;
    }

    if (width <= 950) {
      slidesToShow = 1;
    }

    setSlidesPerView(slidesToShow);
  }, [width]);

  const handleAddCartUser = (product) => {
    setItem(product);
    addCartItem({
      combination: product.combination_data.id,
      quantity: 1,
    });
    setModal(true);
  };

  return (
    <Style.CarrouselFeaturedProducts>
      <ModalAddCart modal={modal} setModal={setModal} product={item} />
      <h2>Productos Destacados</h2>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        loop={productFeatured.length > 1 ? true : false}
        navigation={true}
        modules={[Keyboard, Navigation]}
        style={{
          backgroundColor: "#f4f4f4",
          borderRadius: "4px",
          "--swiper-navigation-color": "#555555",
          minHeight: "285px",
          maxHeight: "320px",
        }}
      >
        {productFeatured.map((product, index) => (
          <SwiperSlide
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "4px",
              minHeight: "285px",
              minWidth: "270px",
              padding: "1em 2em",
            }}
            key={index}
          >
            <Link to={`/product/${product.slug}`}>
              <Style.SlideData>
                <img src={product.image_principal} alt={product.title} />
                <p>{product.title}</p>
                <Style.BoxCartPricce>
                  <button>S/{product.combination_data?.price}</button>
                  <Style.BtnCart
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddCartUser(product);
                    }}
                    disabled={product.combination_data?.quantity === 0}
                    $disabled={product.combination_data?.quantity === 0}
                  >
                    <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
                  </Style.BtnCart>
                </Style.BoxCartPricce>
                <StockStatus
                  quantity={product.combination_data?.quantity}
                  listClass={true}
                />
              </Style.SlideData>
            </Link>
          </SwiperSlide>
        ))}
        {/* <SwiperSlide
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "4px",
          }}
        >
          <Style.SlideData>
            <img src={Product1} alt="Image1" />
            <p>Bundle Paldean Fates</p>
            <Style.BoxCartPricce>
              <button>S/ 30.00</button>
              <Style.BtnCart>
                <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
              </Style.BtnCart>
            </Style.BoxCartPricce>
          </Style.SlideData>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            width: "30%",
          }}
        >
          <Style.SlideData>
            <img src={Product2} alt="Image1" />
            <p>Booster Bundle 151</p>
            <Style.BoxCartPricce>
              <button>S/ 30.00</button>
              <Style.BtnCart>
                <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
              </Style.BtnCart>
            </Style.BoxCartPricce>
          </Style.SlideData>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            width: "30%",
          }}
        >
          <Style.SlideData>
            <img src={Product3} alt="Image1" />
            <p>Booster Temporal Forces</p>
            <Style.BoxCartPricce>
              <button>S/ 30.00</button>
              <Style.BtnCart>
                <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
              </Style.BtnCart>
            </Style.BoxCartPricce>
          </Style.SlideData>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            width: "30%",
          }}
        >
          <Style.SlideData>
            <img src={Product3} alt="Image1" />
            <p>Bundle Paldean Fates</p>
            <Style.BoxCartPricce>
              <button>S/ 30.00</button>
              <Style.BtnCart>
                <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
              </Style.BtnCart>
            </Style.BoxCartPricce>
          </Style.SlideData>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "4px",
          }}
        >
          <Style.SlideData>
            <img src={Product3} alt="Image1" />
            <p>Bundle Paldean Fates</p>
            <Style.BoxCartPricce>
              <button>S/ 30.00</button>
              <Style.BtnCart>
                <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
              </Style.BtnCart>
            </Style.BoxCartPricce>
          </Style.SlideData>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "4px",
          }}
        >
          <Style.SlideData>
            <img src={Product3} alt="Image1" />
            <p>Bundle Paldean Fates</p>
            <Style.BoxCartPricce>
              <button>S/ 30.00</button>
              <Style.BtnCart>
                <IoCart size={20} color="#ffffff" /> <p>Añadir</p>
              </Style.BtnCart>
            </Style.BoxCartPricce>
          </Style.SlideData>
        </SwiperSlide> */}
      </Swiper>
    </Style.CarrouselFeaturedProducts>
  );
};
