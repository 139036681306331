import styled from "styled-components";

export const UserContainer = styled.header`
  display: flex;
  gap: 8em;
  margin-top: 155px;
  padding: 10% 20%;
  @media screen and (max-width: 1600px) {
    padding: 10% 10%;
  }
  @media screen and (max-width: 900px) {
    margin: 20% 10%;
    margin-top: 200px;
    flex-direction: column;
  }
`;

export const OutletContainer = styled.div`
  width: 100%;
`;

export const ActionBtnUser = styled.div`
  width: 100%;
  button {
    cursor: pointer;
    border: none;
    width: 100%;
    padding: 1em 0;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 700;
    background-color: ${({ $sendData }) => ($sendData ? "#00CDE8" : "#555555")};
  }
`;

// Address

export const AddressUserContainer = styled.div`
  min-height: 40vh;
`;

export const AddressContainer = styled.div`
  h3 {
    font-size: 1.5em;
    span {
      font-size: 0.6em;
    }
  }
`;

export const AddRegisterBox = styled.form`
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 1em;
  label {
    display: block;
    color: #878787;
  }
  div {
    margin: 0;
    padding: 0.5em 0;
    @media screen and (max-width: 900px) {
      grid-column: 1 / 3;
    }
  }
  .block {
    grid-column: 1 / 3;
  }
  .btn {
    margin-top: 2em;
  }
  .secundary {
    @media screen and (max-width: 900px) {
      margin-top: 0.2em;
    }
  }
`;

export const AddAddressContainer = styled.div`
  h3 {
    font-size: 1.5em;
  }

  p {
    margin-top: 2em;
    color: #878787;
    font-size: 0.7em;
  }
  button {
    margin-top: 2em;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0.7em 3em;
    border: none;
    background-color: #00cde8;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    span {
      font-weight: 700;
    }
  }
`;

// Orders
export const OrdersUserContainer = styled.div`
  min-height: 40vh;
  h3 {
    font-size: 1.5em;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: 2em;
  overflow-x: auto; //--
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const TableHead = styled.div`
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 8 columnas en total */
  border: 1px solid #d9d9d9;
  white-space: nowrap; /* Evitar que el texto se rompa en varias líneas */
  @media (max-width: 1000px) {
    border-top: ${({ $first_table }) => ($first_table ? "none" : "none")};
  }
`;

export const TableHeaderCell = styled.div`
  /* flex: ${(props) => (props.colSpan ? props.colSpan : 1)}; */
  grid-column: span ${(props) => (props.colSpan ? props.colSpan : 1)};
  padding: 0.5em;
  text-align: center;
  border-right: ${({ $last_table }) =>
    $last_table ? "none" : "1px solid #D9D9D9"};
  font-size: 0.8em;
  font-weight: bold;
  overflow: hidden; /* Evitar que el contenido se desborde */
  text-overflow: ellipsis; /* Mostrar puntos suspensivos cuando el contenido se desborda */
  width: auto;
  @media (max-width: 1000px) {
    border-top: ${({ $first_table }) =>
      $first_table ? "1px solid #D9D9D9" : "none"};
  }
`;

export const TableRow = styled.div`
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 8 columnas en total */
  border: 1px solid #d9d9d9;
  border-top: none;
`;

export const TableCell = styled.div`
  /* flex: ${(props) => (props.colSpan ? props.colSpan : 1)}; */
  grid-column: span ${(props) => (props.colSpan ? props.colSpan : 1)};
  padding: ${({ $content }) => ($content ? "0.3em 1em" : "1em 0.5em")};
  text-align: ${({ $item_bold }) => ($item_bold ? "start" : "center")};
  border-right: ${({ $last_table }) =>
    $last_table ? "none" : "1px solid #D9D9D9"};
  font-size: 0.8em;
  font-weight: ${({ $item_bold }) => ($item_bold ? "700" : "400")};

  display: flex;
  align-items: center;
  justify-content: ${({ $noCenter }) => ($noCenter ? "flex-end" : "center")};
  div {
    span {
      background-color: #5352b8;
      width: 100%;
      color: #ffffff;
      padding: 0.4em 1em;
      border-radius: 5px;
      font-weight: 700;
    }
  }
  svg {
    cursor: pointer;
  }
  a {
    width: 100%;
    border-radius: 5px;
    text-decoration: none;
    background-color: #00cde8;
    color: #ffffff;
    padding: 8px 0;
    font-weight: 700;
  }
`;

export const TableMobileContainer = styled.div`
  .tabla-mobile {
    margin-top: 2em;
  }
  @media (min-width: 1001px) {
    display: none;
  }
`;

export const CuponBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2em;
  h5 {
    padding-right: 4.5em;
    text-align: end;
    font-weight: 400;
  }
  p {
    background-color: #f2f2f2;
    font-weight: 700;
    font-size: 0.8em;
    padding: 0.4em 1em;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      color: #ffffff;
      background-color: #df3e3e;
    }
  }
`;

//Detail Orders
export const OrdersDetailContainer = styled.div`
  margin-top: 2em;
  width: 100%;
`;
