import styled from "styled-components";
import { motion } from "framer-motion";
export const Contenedor = styled.div`
  margin: 5% 10%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5em;
  @media (max-width: 1000px) {
    display: block;
  }
  /* @media (max-width: 750px) {
    display: block;
  } */
`;
export const BoxContainer = styled.div`
  height: 100%;
  margin-top: 2em;
`;

export const Box = styled(motion.div)`
  display: grid;
  /* flex-wrap: wrap; */
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(270px, 270px));
  /* gap: 1rem; */
  /* margin-top: 2em; */
  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const BoxSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  width: 80%;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 1em;
`;
// export const SearchIcon = styled(BsSearch)`
//   position: absolute;
//   top: 50%;
//   right: 2%;
//   transform: translateY(-50%);
//   color: #35b9da;
//   cursor: pointer;
//   font-size: 1.5em;
// `;
export const NoDataBox = styled.div`
  height: 70%;
  display: grid;
  place-content: center;
  min-height: 300px;
`;

export const ErrorSearch = styled.div`
  color: #35b9da;
  display: flex;
  margin: auto;
  width: 80%;
  margin-top: 0.5em;
  height: 2em;
`;

export const PaginacionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px 0px;
  align-items: center;
  font-family: sans-serif;

  @media screen and (max-width: 1200px) {
    ${(props) => (props.detalles ? "flex-direction: column;" : null)}
    ${(props) => (props.detalles ? "gap: 20px;" : null)}
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const CantidadResultados = styled.div`
  padding-left: 20px;
  h3 {
    color: #7e84a3;
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 0px;
  }
`;
export const PaginacionBox = styled.div`
  ul.pagination {
    display: flex;
    padding: 0;
    margin: 0;
    gap: 0.4em;
    @media screen and (max-width: 600px) {
      gap: 0.1em;
    }
  }
  ul.pagination li {
    display: inline;
    cursor: pointer;
    gap: 20px;
  }

  ul.pagination li a {
    color: #000000;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    font-weight: bold;
    background-color: #e4e2e2;
    @media screen and (max-width: 400px) {
      padding: 8px 10px;
    }
  }

  ul.pagination li a:focus {
    background-color: #04cbe8;
    color: white;
  }

  ul.pagination li.anterior a:focus,
  ul.pagination li.siguiente a:focus {
    background-color: #72bcd5;
    color: white;
  }

  ul.pagination li a:hover {
    background-color: #a7d6f1;
    color: white;
  }
  ul.pagination li.anterior a:hover,
  ul.pagination li.siguiente a:hover {
    opacity: 0.5;
    color: white;
  }
  ul.pagination li.anterior a,
  ul.pagination li.siguiente a {
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    background-color: #5352b8;
    @media screen and (max-width: 400px) {
      padding: 9px 5px;
    }
  }
  .activePaginacionFondo {
    background-color: #72bcd5;
    color: white;
  }
  ul.pagination li a.activePaginacionFondo {
    color: white;
    background-color: #04cbe8;
  }
  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

// Filter
export const BoxFilterProduct = styled.div``;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  padding: 10px;
  width: 80%;
  svg {
    color: #00cde8;
  }
  &:hover {
    background-color: #00cde8;
    color: #ffffff;
  }
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#00CDE8" : "transparent"};
  color: ${({ $isSelected }) => ($isSelected ? "#ffffff" : "#000000")};
  border: 1px solid #cacaca;

  padding: 0.5em 0.5em;
  margin: 0.5em 0;
  font-weight: ${({ $isSelected }) => ($isSelected ? "700" : "400")};
  font-size: 0.9em;
  border-radius: 5px;
`;

export const SubMenu = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  margin-left: ${(props) => (props.$lv3 ? "2em" : "0px")};
  a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 700;
  }
`;

export const SubMenuItem = styled(MenuItem)`
  margin-left: 2em;
  padding-left: 1em;
  background-color: ${({ $more }) => $more && "#262525;"};
  border: ${({ $lv3 }) =>
    $lv3 ? "1px solid transparent;" : "#1px solid #cacaca;"};
  &:hover {
    svg {
      color: #ffffff;
    }
  }
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
`;

export const RangeInput = styled.input`
  width: 100%;
  transform: rotate(90deg);
  writing-mode: vertical-lr;
  margin-bottom: 5em;
  // accent-color: #72bcd5;
  outline: none;
  border: 0;
  accent-color: rgb(194, 198, 224);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  -webkit-appearance: none;
  background-color: #89e1e7;
  border-radius: 5px;
  height: 8px;
  cursor: grab;
  p {
    font-family: sans-serif;
  }
`;
