import React from "react";
import * as Style from "../styles/CategoriesStyles";
import Categories1 from "../assets/categorie1.png";
import Categories2 from "../assets/categorie2.png";
import Categories3 from "../assets/categorie3.png";
import Categories4 from "../assets/categorie4.png";
import { useNavigate } from "react-router-dom";

export const Categories = () => {
  const navigate = useNavigate();

  const handleClick = (slug) => {
    navigate(`/category/${slug}`);
  };

  return (
    <Style.BannerTopFooterContainer>
      <Style.BannerBox>
        <Style.ContainerData>
          <Style.ImageData>
            <h3>Pokémon TCG</h3>
            <button onClick={() => handleClick("pokemon")}>
              <h4>Ver más {">"}</h4>
            </button>
          </Style.ImageData>
          <img src={Categories1} alt="BannerT1" />
        </Style.ContainerData>
        <Style.ContainerData>
          <Style.ImageData>
            <h3>Yu-Gi-Oh!</h3>
            <button onClick={() => handleClick("yugioh")}>
              <h4>Ver más {">"}</h4>
            </button>
          </Style.ImageData>
          <img src={Categories2} alt="BannerT2" />
        </Style.ContainerData>
        <Style.ContainerData>
          <Style.ImageData>
            <h3>Accesorios TCG</h3>
            <button onClick={() => handleClick("accesorios")}>
              <h4>Ver más {">"}</h4>
            </button>
          </Style.ImageData>
          <img src={Categories3} alt="BannerT2" />
        </Style.ContainerData>
        <Style.ContainerData>
          <Style.ImageData>
            <h3>Juegos de mesa</h3>
            <button onClick={() => handleClick("juegos-de-mesa")}>
              <h4>Ver más {">"}</h4>
            </button>
          </Style.ImageData>
          <img src={Categories4} alt="BannerT2" />
        </Style.ContainerData>
      </Style.BannerBox>
    </Style.BannerTopFooterContainer>
  );
};
