/* eslint-disable no-undef */
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../context/product/ProductProvider";

export const CulqiBtnConfig = ({ listCart, costDelivery, userState }) => {
  const { orderPaymentCulqi, orderStatus, resetOrder, orderErrorMessage } =
    useContext(ProductContext);

  const navigate = useNavigate();

  const handleRedirectoOrderSuccess = () => {
    if (orderStatus) {
      resetOrder();
      Culqi.close();
      navigate("/order-success");
      window.Culqi.token = null;
    } else {
      setTimeout(() => {
        resetOrder();
      }, 5000);
    }
  };

  useEffect(() => {
    handleRedirectoOrderSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus, navigate, orderErrorMessage]);

  useEffect(() => {
    // Verifica que Culqi esté disponible
    const initializeCulqi = () => {
      // console.log(window.Culqi);

      if (window.Culqi) {
        Culqi.options({
          lang: "auto",
          installments: false,
          paymentMethods: {
            tarjeta: true,
            yape: true,
            bancaMovil: true,
            agente: true,
            billetera: true,
            cuotealo: true,
          },
          style: {
            logo: "https://res.cloudinary.com/doyjg2gyo/image/upload/v1677162053/wonderland/ISO_wonder_tjc5r7.png",
            bannerColor: "#00cde8", // hexadecimal
            buttonBackground: "#00cde8", // hexadecimal
            menuColor: "#00cde8", // hexadecimal
            linksColor: "#999999", // hexadecimal
            buttonTextColor: "#ffffff", // hexadecimal
            priceColor: "#00cde8", // hexadecimal
          },
        });

        // Asignar la función culqi al objeto window para que sea accesible por Culqi
        window.culqi = function () {
          if (Culqi.token) {
            // ¡Objeto Token creado exitosamente!
            // const token = Culqi.token.id;
            // console.log("Se ha creado un Token: ", token);
            // console.log(window.Culqi);
            const updatedUserState = {
              ...userState,
              culqi_response: Culqi.token,
            };

            console.log(updatedUserState);

            orderPaymentCulqi(
              costDelivery
                ? updatedUserState
                : {
                    culqi_response: Culqi.token,
                    shipping_method: "TIENDA",
                  }
            );
          } else if (Culqi.order) {
            // ¡Objeto Order creado exitosamente!
            const order = Culqi.order;
            console.log("Se ha creado el objeto Order: ", order);
            // Aquí puedes manejar el objeto order si es necesario
          } else {
            // Mostramos JSON de objeto error en consola
            console.log("Error : ", Culqi.error);
          }
        };
      } else {
        console.error(
          "Culqi no está definido. Asegúrate de que el SDK de Culqi se haya cargado correctamente."
        );
      }
    };

    // Espera un momento para asegurarte de que Culqi esté disponible
    setTimeout(initializeCulqi, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentClick = () => {
    if (window.Culqi) {
      // Aquí puedes configurar el monto y otra información del pago
      Culqi.settings({
        title: "Wonderland Store",
        currency: "PEN",
        description: "Compra en wonderland store",
        amount:
          (
            listCart?.cart_items_json?.final_price_with_card +
            (costDelivery ? 15 : 0)
          ).toFixed(2) * 100, // El monto en centavos, 10000 = 100.00 PEN
      });

      // Abre el modal de Culqi
      Culqi.open();
    } else {
      console.error("Culqi no está disponible para abrir el modal.");
    }
  };

  return (
    <div className="purchase-produtcs-orders" onClick={handlePaymentClick}>
      <h5>PAGA AQUÍ </h5>
    </div>
  );
};
