import { Swiper, SwiperSlide } from "swiper/react";
import * as Style from "../styles/CarrouselStyles";
// import CarrouselItem from "../assets/CarrouselItem1.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Loading } from "./Loading";

export const Carrousel = ({ productCaroucel, loadingCaroucel }) => {
  return (
    <Style.CarrouselContainer>
      {loadingCaroucel ? (
        <Loading height="50vh" />
      ) : (
        <Swiper
          style={{
            "--swiper-navigation-color": "#ffffff",
            "--swiper-pagination-color": "#00CDE8",
            backgroundColor: "transparent",
            height: "auto",
          }}
          autoHeight={true}
          speed={600}
          loop={true}
          autoplay={{ delay: 5000, disableOnInteraction: true }}
          parallax={false}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {productCaroucel.map((product, index) => (
            <SwiperSlide
              style={{
                padding: "0",
                margin: "0",
              }}
              key={index}
            >
              <Style.SliderContainer>
                <img src={product?.image} alt={product?.title} />
              </Style.SliderContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Style.CarrouselContainer>
  );
};
