import React, { useContext } from "react";
import { HeaderFooter } from "./HeaderFooter";
import { ProductContext } from "../context/product/ProductProvider";
import {
  ContainerPurchase,
  OrderSuccesBox,
  OrderText,
} from "../styles/FinalizePurchaseStyles";
import OrderImage from "../assets/order-success.png";
import {
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from "../styles/MyUserStyles";

export const OrdersThanks = () => {
  const { orderCart } = useContext(ProductContext);
  return (
    <HeaderFooter searchModal={true}>
      <ContainerPurchase>
        <OrderSuccesBox>
          <img src={OrderImage} alt="order-success" />
          <OrderText>
            <h4>¡Muchas gracias</h4>
            <h4>por tu compra!</h4>

            <p>Esperamos volver a verte pronto</p>
            <span>Hemos enviado una copia de tu</span>
            <br />
            <span>pedido a tu correo electrónico</span>
          </OrderText>
        </OrderSuccesBox>
        <br />
        <br />
        <TableWrapper>
          <TableHead>
            <TableHeaderCell colSpan={3}>Cantidad</TableHeaderCell>
            <TableHeaderCell colSpan={5}> Nombre de Producto</TableHeaderCell>
            <TableHeaderCell colSpan={2} $last_table>
              Precio
            </TableHeaderCell>
          </TableHead>
          {orderCart?.order?.cart_items.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10}>No hay productos agregados</TableCell>
            </TableRow>
          ) : (
            orderCart?.order?.cart_items?.map((product) => (
              <TableRow key={product.id}>
                <TableCell colSpan={3}>
                  {product.quantity < 10
                    ? `0${product.quantity}`
                    : product.quantity}
                </TableCell>
                <TableCell colSpan={5}> {product.item} </TableCell>
                <TableCell colSpan={2} $last_table>
                  {" "}
                  S/{product.checkout}
                </TableCell>
              </TableRow>
            ))
          )}
          <TableRow>
            <TableCell colSpan={3} $item_bold>
              Subtotal:
            </TableCell>
            <TableCell colSpan={5}></TableCell>
            <TableCell colSpan={2} $last_table>
              S/{orderCart?.order?.final_price}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} $item_bold>
              Envío
            </TableCell>
            <TableCell colSpan={5}></TableCell>
            <TableCell colSpan={2} $last_table>
              S/{orderCart?.shipping_method === "DELIVERY" ? 15 : 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} $item_bold>
              Comisión de tarjeta
            </TableCell>
            <TableCell colSpan={5}></TableCell>
            <TableCell colSpan={2} $last_table>
              {orderCart?.order?.final_price_with_card !== undefined &&
              orderCart?.order?.final_price !== undefined
                ? `S/${
                    orderCart?.order?.final_price_with_card -
                    orderCart?.order?.final_price
                  }`
                : "--"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} $item_bold>
              Total
            </TableCell>
            <TableCell colSpan={5}></TableCell>
            <TableCell colSpan={2} $last_table>
              {orderCart?.shipping_method === "DELIVERY"
                ? orderCart?.order?.final_price_with_card_and_ship !== undefined
                  ? `S/${orderCart?.order?.final_price_with_card_and_ship}`
                  : "--"
                : orderCart?.order?.final_price_with_card !== undefined
                ? `S/${orderCart?.order?.final_price_with_card}`
                : "--"}
            </TableCell>
          </TableRow>
        </TableWrapper>
      </ContainerPurchase>
    </HeaderFooter>
  );
};
