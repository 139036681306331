import React from "react";
import { HeaderFooter } from "./HeaderFooter";
import {
  BoxFAQ,
  ContactBox,
  ContactData,
  ContactMap,
  ContanierContactMain,
} from "../styles/GeneralStyles";
import { IoHomeSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useLoadScript } from "@react-google-maps/api";
import { MapStore } from "../components/MapStore";
import { ActionBtnUser, AddRegisterBox } from "../styles/MyUserStyles";
import { InputForm, TextAreaForm } from "../styles/LoginStyles";

export const ContactView = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBXcPFcED3EwuMHNuwVjnFsIs8Ni8XHLEU",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("address");
  };

  return (
    <HeaderFooter searchModal={true}>
      <ContanierContactMain>
        <BoxFAQ>
          <h2>¡Escríbenos y te respondemos al toque!</h2>
          <div>
            {/* <h6>
              Déjanos tus datos y nos pondremos en contacto contigo lo más
              pronto posible.
            </h6> */}
            <p>Déjanos tus datos y nos pondremos en contacto</p>
            <p>contigo lo más pronto posible.</p>
          </div>
        </BoxFAQ>
        <ContactBox>
          <ContactMap>{isLoaded ? <MapStore /> : null}</ContactMap>
          <ContactData>
            <h4>
              <IoHomeSharp /> Av. Aviación 5095, Santiago de Surco
            </h4>
            <h4>
              <MdEmail /> contacto@wonderland-store.com
            </h4>
            <h4>
              <BsTelephoneFill /> 916 997 793
            </h4>
            <h3>
              <FaFacebook size={20} /> <FaInstagram size={20} />{" "}
              <FaLinkedin size={20} />
            </h3>
            <AddRegisterBox style={{ gridColumn: "1 / 3" }}>
              <div>
                <InputForm
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="Nombre"
                />
              </div>
              <div>
                <InputForm
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Apellido"
                />
              </div>
              <div>
                <InputForm
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Correo electrónico"
                />
              </div>
              <div>
                <InputForm
                  type="number"
                  name="phone"
                  id="phone"
                  placeholder="Teléfono"
                />
              </div>
              <div className="block">
                <TextAreaForm
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Mensaje"
                />
              </div>
              <div className="btn">
                <ActionBtnUser $sendData={true}>
                  <button onClick={handleSubmit}>Guardar</button>
                </ActionBtnUser>
              </div>
            </AddRegisterBox>
          </ContactData>
        </ContactBox>
      </ContanierContactMain>
    </HeaderFooter>
  );
};
