import React, { useContext, useState } from "react";
import { InputForm } from "../styles/LoginStyles";
import {
  BtnCupon,
  BtnCuponStatus,
  CupontContainer,
} from "../styles/FinalizePurchaseStyles";
import { clienteAxios } from "../config/axios";
import { ProductContext } from "../context/product/ProductProvider";

export const CuponProduct = () => {
  const { getListCartUser, have_coupon, handleDeleteCupon } =
    useContext(ProductContext);
  const [cuponState, setCuponState] = useState("");
  const handleChange = (e) => {
    setCuponState(e.target.value);
  };

  const [cuponInvalide, setcuponInvalide] = useState(false);

  const handleSubmit = async () => {
    if (have_coupon) {
      handleDeleteCupon();
    }

    if (cuponState.trim() !== "") {
      try {
        const respuesta = await clienteAxios.post("cart/addcoupon/", {
          code_coupon: cuponState,
        });
        if (respuesta.status === 200) {
          setcuponInvalide(false);
          setCuponState("");
          getListCartUser();
        }
      } catch (error) {
        console.log(error.response);
        setcuponInvalide(true);
        setCuponState("");
        setTimeout(() => {
          setcuponInvalide(false);
        }, 5000);
      } finally {
      }
    }
  };

  return (
    <CupontContainer>
      <InputForm
        type="text"
        placeholder="Ingrésalo aquí"
        value={cuponState}
        onChange={handleChange}
      />
      <BtnCupon onClick={handleSubmit}>Aplicar cupón</BtnCupon>
      {/* <BtnCupon>¡Cupón aplicado!</BtnCupon>
      <BtnCupon>Cupón caducado</BtnCupon>
      <BtnCupon>Cupón inválido</BtnCupon> */}
      {have_coupon && (
        <BtnCuponStatus $bgcolor="#F2F2F2" $colorText="#000000">
          ¡Cupón aplicado!
        </BtnCuponStatus>
      )}
      {/* {!cuponApplied && cuponState !== "" && (
         <BtnCuponStatus $bgcolor="#EC4FA3" $colorText="#ffffff">
          Cupón caducado
        </BtnCuponStatus>
      )} */}
      {cuponInvalide && (
        <BtnCuponStatus $bgcolor="#FF004D" $colorText="#ffffff">
          Cupón inválido
        </BtnCuponStatus>
      )}
    </CupontContainer>
  );
};
