import React from "react";
import * as Style from "../styles/UpcomingEventsStyles";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Keyboard, Navigation } from "swiper/modules";
import Image1 from "../assets/league-challenge-tcg-142-en 1.png";

export const UpcomingEvents = ({ eventList }) => {
  return (
    <Style.CarrouselUpcomingEvents>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        loop={eventList.length > 1 ? true : false}
        navigation={true}
        modules={[Keyboard, Navigation]}
        style={{
          backgroundColor: "#5352b8",
          borderRadius: "4px",
          "--swiper-navigation-color": "#ffffff",
        }}
      >
        {eventList.length === 0 ? (
          <SwiperSlide>
            <Style.SlideData>
              <h2>Próximos Eventos</h2>
              <img src={Image1} alt="Image1" />
              <p>-----</p>
              <button>
                <h4>Proximamente</h4>
              </button>
            </Style.SlideData>
          </SwiperSlide>
        ) : (
          eventList.map((item, index) => (
            <SwiperSlide key={index}>
              <Style.SlideData>
                <h2> {item.title} </h2>
                <img src={item.image} alt={item.title} />
                <p>{item.body} </p>
                <a
                  href={item.button_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4> {item.button_title}</h4>
                </a>
              </Style.SlideData>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </Style.CarrouselUpcomingEvents>
  );
};
