import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoryContainer = styled.div`
  /* cursor: pointer; */
`;

export const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#00CDE8" : "#ffffff"};
  color: ${({ $isSelected }) => ($isSelected ? "#ffffff" : "#000000")};
  border: 1px solid #cacaca;
  padding: 0.5em 0.5em;
  margin: 0.5em 0;
  font-weight: 700;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
`;

export const CategoryImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

export const CategoryName = styled.h2`
  font-size: 18px;
  margin: 0;
`;

export const KindContainer = styled.div`
  margin-left: 40px;
`;

export const KindItem = styled.div`
  cursor: pointer;
  color: #000000;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 0.5em 0.5em;
  padding-left: 2em;
  margin: 0.5em 0;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#cacaca" : "#ffffff"};
  font-weight: bolder;
`;

export const SubKindContainer = styled.ul`
  margin-left: 20px;
  margin-bottom: 1em;
`;

export const SubKindItem = styled.li`
  cursor: pointer;
  background-color: transparent;
  list-style-type: none;
  display: flex;
  align-items: center;
  color: #3d3d3d;
  font-weight: ${({ $isSelected }) => ($isSelected ? "700" : "400")};
`;

export const HR = styled.hr`
  margin: 0.5em 0;
  width: 80%;
  border: 1px solid #b6b6b6;
`;

export const BtnLinkCategory = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  a {
    display: block;
    cursor: pointer;
    color: #ffffff;
    border-radius: 5px;
    width: 100%;
    background-color: #262525;
    font-weight: bolder;
    text-decoration: none;
    padding: 0.5em 0.5em;
    padding-left: 2em;
  }
`;

export const ContainerSliderRange = styled.div`
  margin-top: 3em;
  h4 {
    text-align: center;
    color: #2e2daa;
    font-weight: 900;
  }
  h5 {
    font-weight: 400;
  }
  button {
    cursor: pointer;
    margin-top: 1.5em;
    background-color: #00cde8;
    border: 2px solid #ffffff;
    box-shadow: 0px 1px 4px 0px #00000040;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0.5em 2em;
    text-align: center;
    border-radius: 5px;
  }
`;

export const BoxSliderRange = styled.div`
  width: 300px;
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const SliderInput = styled.input`
  flex: 1; /* Los controles deslizantes ocupan el mismo espacio */
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  /* border-radius: 5px; */
  background: #d9d9d9;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #00cde8;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #00cde8;
    cursor: pointer;
  }
`;
