import React from "react";
import styled from "styled-components";

const StatusDiv = styled.h4`
  width: 100%;
  padding: 5px 0;
  border-radius: 5px;
  color: #ffffff;
  background-color: ${(props) => props.$backgroundColor};
`;

export const StatusOrders = (status) => {
  let backgroundColor = "";
  let text = "";

  switch (status) {
    case "PENDING":
      backgroundColor = "#EC4FA3";
      text = "En espera";
      break;
    case "CANCELED":
      backgroundColor = "#FF004D";
      text = "Cancelado";
      break;
    case "COMPLETED":
      backgroundColor = "#5352B8";
      text = "Entregado";
      break;
    default:
      backgroundColor = "#00CDE8";
      text = ".....";
  }

  return <StatusDiv $backgroundColor={backgroundColor}>{text}</StatusDiv>;
};
