import React, { useContext, useState } from "react";
import { AuthContext } from "../context/auth/AuthProvider";
import {
  FormRegister,
  InputForm,
  LabelForm,
  SesionRegister,
} from "../styles/LoginStyles";
import { ActionBtnUser, OrdersUserContainer } from "../styles/MyUserStyles";
import { useNavigate } from "react-router-dom";
import { clienteAxios } from "../config/axios";

export const NewPassword = () => {
  const { usuarioData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userState, setUserState] = useState({
    password: "",
    confirm_password: "",
  });

  const { password, confirm_password } = userState;

  const [errors, setErrors] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = (e) => {
    setUserState({
      ...userState,
      [e.target.name]: e.target.value,
    });
  };

  const getData = async () => {
    setIsUpdating(true);
    try {
      const respuesta = await clienteAxios.put("account/change-password/", {
        new_password: userState.confirm_password,
        email: usuarioData?.email,
      });
      setUpdateSuccess(false);
      if (respuesta.status === 200) {
        setUpdateSuccess(true);
        setTimeout(() => {
          setUpdateSuccess(null);
        }, 5000);
        return;
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = {
      password: "Contraseña",
      confirm_password: "Nueva contraseña",
    };

    const newErrors = {};

    // Iterar sobre los campos y establecer los que estén en blanco o no cumplan con la validación como obligatorios
    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (!userState[fieldName].trim()) {
        newErrors[fieldName] = `${fieldLabel} es obligatorio`;
      } else {
        if (fieldName === "password" && userState[fieldName].length < 5) {
          newErrors[fieldName] = `La ${fieldLabel} debe tener mas de 4 digitos`;
        }
        if (
          fieldName === "confirm_password" &&
          userState[fieldName].length < 5
        ) {
          newErrors[fieldName] = `La ${fieldLabel} debe tener mas de 4 digitos`;
        }
      }
    });

    // Validar que las contraseñas sean iguales
    if (password !== confirm_password) {
      newErrors["confirm_password"] = "Las contraseñas no coinciden";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

      setTimeout(() => {
        setErrors({});
      }, 5000);
      return;
    }
    getData();
  };

  const handleCancel = () => {
    navigate("/");
  };
  return (
    <OrdersUserContainer>
      <SesionRegister $myUserData={true}>
        <h3>Cambiar mi contraseña</h3>
        <FormRegister>
          <div>
            <LabelForm
              htmlFor="password"
              className={errors?.password ? "error-form" : ""}
            >
              Contraseña <span>*</span>
              {errors?.password && ` - ${errors.password}`}
            </LabelForm>
            <div>
              <InputForm
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <LabelForm
              htmlFor="confirm_password"
              className={errors?.confirm_password ? "error-form" : ""}
            >
              Confirmar contraseña <span>*</span>{" "}
              {errors?.confirm_password && ` - ${errors.confirm_password}`}
            </LabelForm>
            <div>
              <InputForm
                type="password"
                name="confirm_password"
                id="confirm_password"
                value={confirm_password}
                onChange={handleChange}
              />
            </div>
          </div>
          <ActionBtnUser $sendData={true}>
            <button type="submit" onClick={handleSubmit}>
              {isUpdating ? "Validando..." : "Cambiar"}
            </button>
          </ActionBtnUser>
          <ActionBtnUser>
            <button type="button" onClick={handleCancel}>
              Cancelar
            </button>
          </ActionBtnUser>
        </FormRegister>
        <br />
        {updateSuccess && (
          <div className="update-user">
            <span>Contraseña cambiada</span>{" "}
          </div>
        )}
      </SesionRegister>
    </OrdersUserContainer>
  );
};
