import React from "react";
import styled from "styled-components";

const StatusContainer = styled.div`
  background-color: ${(props) => props.$statusColor};
  color: #ffffff;
  padding: ${({ $listClass }) => ($listClass ? "0.2em 1em" : "0.5em 3em")};
  border-radius: 5px;
  display: inline;
  position: absolute;
  left: ${({ $listClass }) => ($listClass ? "0.5em;" : "2em;")};
  top: ${({ $listClass }) => ($listClass ? "0.5em;" : "2em;")};
  z-index: 1;
  @media screen and (max-width: 500px) {
    padding: ${({ $listClass }) => ($listClass ? "0.2em 1em" : "0.5em 1em")};
    left: ${({ $listClass }) => ($listClass ? "0.5em;" : "0.5em;")};
  }
`;

export const StockStatus = ({ quantity, listClass = false }) => {
  let statusText = "";
  let statusColor = "";

  if (quantity === 0 || quantity < 0) {
    statusText = "Agotado";
    statusColor = "#FF004D";
  } else if (quantity === 1) {
    statusText = "1 en stock";
    statusColor = "#EC4FA3";
  } else if (quantity >= 2 && quantity <= 20) {
    statusText = `${quantity} en stock`;
    statusColor = "#5352B8";
  } else {
    statusText = "+20 en stock";
    statusColor = "#5352B8";
  }

  return (
    <StatusContainer $statusColor={statusColor} $listClass={listClass}>
      {statusText}
    </StatusContainer>
  );
};
