import styled from "styled-components";

export const CarrouselUpcomingEvents = styled.div`
  height: 400px;
  width: 400px;
  background-color: #5352b8;
  border-radius: 5px;
  @media (max-width: 450px) {
    height: 400px;
    width: 350px;
  }
`;

export const SlideData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  h2 {
    font-weight: 700;
    font-size: 1.5em;
    text-align: center;
  }
  p {
    font-weight: 700;
    font-size: 1em;
  }
  button {
    text-transform: uppercase;
    background-color: #ed1c24;
    color: white;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    h4 {
      padding: 0.2em 1.5em;
      font-weight: 700;
      font-size: 0.7em;
      text-align: center;
    }
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    background-color: #ed1c24;
    color: white;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    h4 {
      padding: 0.2em 1.5em;
      font-weight: 700;
      font-size: 0.7em;
      text-align: center;
    }
  }
  img {
    margin-top: 1em;
    height: 115px;
  }
`;
