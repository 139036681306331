import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ProductContext } from "../context/product/ProductProvider";
import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  gap: 2em;
  @media (max-width: 980px) {
    display: none;
  }
`;

const CategoryContainer = styled.div`
  /* cursor: pointer; */
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  color: ${({ $isSelected }) => ($isSelected ? "#00CDE8" : "#ffffff")};
  padding: 0.5em 0.5em;
  margin: 0.5em 0;
  font-weight: 700;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
`;

// const CategoryName = styled.h2`
//   font-size: 1em;
//   margin: 0;
//   position: relative;
// `;

const KindContainer = styled.div`
  position: absolute;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1em 0;
`;

const KindItem = styled.div`
  cursor: pointer;
  color: #000000;
  border-radius: 5px;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#cacaca" : "#ffffff"};
  &:hover {
    background-color: #00cde8;
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }
  a {
    margin: 0.5em 0;
    padding: 0.5em 4em;
    display: block;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    color: #000000;
    border-radius: 5px;
    font-weight: bolder;
  }
`;

const BtnLinkCategory = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    background-color: #262525;
    font-weight: bolder;
    text-decoration: none;
    padding: 0.5em 2em;
  }
`;

export const NavMenu = () => {
  const { categoryList } = useContext(ProductContext);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const toggleCategory = (categoryId) => {
    setExpandedCategory(categoryId === expandedCategory ? null : categoryId);
    setSelectedItemId(categoryId);
  };

  const dropdownCategoryRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownCategoryRef.current &&
        !dropdownCategoryRef.current.contains(event.target)
      ) {
        setExpandedCategory(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container>
      {categoryList.map((category) => (
        <CategoryContainer key={category.id}>
          <CategoryHeader
            onClick={() => toggleCategory(category.slug)}
            $isSelected={selectedItemId === category.slug}
          >
            <span>{category.name}</span>
            {selectedItemId === category.slug ? (
              <IoIosArrowDown color="#00CDE8" />
            ) : (
              <IoIosArrowDown color="#ffffff" />
            )}
          </CategoryHeader>
          {expandedCategory === category.slug && category.kinds.length > 0 && (
            <KindContainer ref={dropdownCategoryRef}>
              {category.kinds.slice(0, 4).map((kind) => (
                <Fragment key={kind.slug}>
                  <KindItem>
                    <Link
                      to={`/category/${selectedItemId}/kind/${kind.slug}`}
                      onClick={() => setExpandedCategory(null)}
                    >
                      {kind.name}
                    </Link>
                  </KindItem>
                </Fragment>
              ))}
              {category.kinds.length > 4 && (
                <BtnLinkCategory>
                  <Link
                    to={`/category/${selectedItemId}`}
                    onClick={() => setExpandedCategory(null)}
                  >
                    Ver más expansiones
                  </Link>
                </BtnLinkCategory>
              )}
            </KindContainer>
          )}
        </CategoryContainer>
      ))}
    </Container>
  );
};
