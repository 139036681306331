import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #000000;
`;

export const Footer = styled.div`
  background-color: #343434;
  display: flex;
  justify-content: space-evenly;
  /* margin-top: 180px; */
  padding: 3em 0em;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Img = styled.img`
  height: 51px;
`;

export const ImgBook = styled.img`
  height: 60px;
  margin-bottom: 1em;
  margin-top: 1em;
  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
`;

export const FooterSection = styled.div`
  color: #ffffff;
  h3 {
    margin-top: 1.5em;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
  }
  h4 {
    font-size: 0.875em;
    font-weight: 700;
    line-height: 2.6em;
  }
  a {
    text-decoration: none;
  }
  p {
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.5em;
    color: #ffffff;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const FooterSectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  h3 {
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
  }
`;

export const GroupLogo = styled.div`
  display: flex;
  gap: 0.5em;
  height: 80px;
  padding-top: 1em;
  img {
    cursor: pointer;
  }
`;

export const GroupLogoMobile = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    margin-top: 2em;
    display: flex;
    gap: 0.5em;
    height: 80px;
    img {
      cursor: pointer;
    }
  }
`;

export const FooterEnd = styled.div`
  background-color: #000000;
  height: 2.93em;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: #ffffff;
    font-weight: 700;
    font-size: 0.82em;
    line-height: 2.6em;
  }
`;
