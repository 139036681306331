import React, { useContext } from "react";
import { DropDownUser } from "../components/DropDownUser";
import { Outlet } from "react-router-dom";
import { OutletContainer, UserContainer } from "../styles/MyUserStyles";
import { AuthContext } from "../context/auth/AuthProvider";
import { HeaderFooter } from "./HeaderFooter";

export const MyUser = () => {
  const { cerrarSesion, usuarioAnonymus } = useContext(AuthContext);

  const handleExitSession = () => {
    cerrarSesion();
    usuarioAnonymus(false);
  };

  return (
    <HeaderFooter disabledOnClick={true} searchModal={true}>
      <UserContainer>
        <DropDownUser userDataStyle={true} cerrarSesion={handleExitSession} />
        <OutletContainer>
          <Outlet />
        </OutletContainer>
      </UserContainer>
    </HeaderFooter>
  );
};
