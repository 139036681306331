import {
  BoxLoading,
  ContainerLoading,
  Cube,
  Cube2,
  Cube3,
  Cube4,
} from "../styles/GeneralStyles";

export const Loading = ({ height = "100vh", loadingWidth = "40px" }) => {
  return (
    <BoxLoading $height={height}>
      <ContainerLoading $loadingWidth={loadingWidth}>
        <Cube />
        <Cube2 />
        <Cube4 />
        <Cube3 />
      </ContainerLoading>
    </BoxLoading>
  );
};
