import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  OrdersDetailContainer,
  OrdersUserContainer,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableMobileContainer,
  TableRow,
  TableWrapper,
} from "../styles/MyUserStyles";
import { clienteAxios } from "../config/axios";
import { Loading } from "./Loading";
import { FormattedDateCell } from "../hooks/UseFormattedDate";
import { StatusOrders } from "../hooks/StatusOrders";
import { UseScrollToTopOnMount } from "../hooks/UseScrollToTopOnMount";

export const MyOrdersItem = () => {
  UseScrollToTopOnMount();
  const { id } = useParams();
  const [myOrders, setMyOrders] = useState({});
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const respuesta = await clienteAxios.get(`order/orderitems/${id}`);
      setMyOrders(respuesta.data);
      setLoading(true);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setMyOrders({});
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <OrdersUserContainer>
      <h3>Mis pedidos</h3>
      {loading ? (
        <div>
          <TableWrapper>
            <TableHead>
              <TableHeaderCell colSpan={2}>Fecha</TableHeaderCell>
              <TableHeaderCell colSpan={2}>Total de compra</TableHeaderCell>
              <TableHeaderCell colSpan={4}>Tipo de entrega</TableHeaderCell>
              <TableHeaderCell colSpan={2} $last_table>
                Estado
              </TableHeaderCell>
            </TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                {" "}
                <FormattedDateCell date={myOrders?.modified} />{" "}
              </TableCell>
              <TableCell colSpan={2}>
                S/.
                {myOrders.shipping_method === "TIENDA"
                  ? myOrders.cart_payload.final_price_with_card
                  : myOrders.cart_payload.final_price_with_card_and_ship}
              </TableCell>
              <TableCell colSpan={4}> {myOrders.shipping_method} </TableCell>
              <TableCell colSpan={2} $last_table $content>
                {StatusOrders(myOrders.status)}
              </TableCell>
            </TableRow>
          </TableWrapper>
          <TableMobileContainer>
            <div className="tabla-mobile">
              <TableHead>
                <TableHeaderCell $first_table colSpan={5}>
                  Fecha
                </TableHeaderCell>
                <TableHeaderCell $last_table $first_table colSpan={5}>
                  Total de compra
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell colSpan={5}>
                  <FormattedDateCell date={myOrders?.modified} />
                </TableCell>
                <TableCell colSpan={5} $last_table>
                  S/.
                  {myOrders.shipping_method === "TIENDA"
                    ? myOrders.cart_payload.final_price_with_card
                    : myOrders.cart_payload.final_price_with_card_and_ship}
                </TableCell>
              </TableRow>
              <TableHead>
                <TableHeaderCell colSpan={5}>Tipo de entrega</TableHeaderCell>
                <TableHeaderCell $last_table colSpan={5}>
                  Estado
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell colSpan={5}>{myOrders.shipping_method} </TableCell>
                <TableCell $last_table colSpan={5}>
                  {StatusOrders(myOrders.status)}
                </TableCell>
              </TableRow>
            </div>
          </TableMobileContainer>

          <OrdersDetailContainer>
            <TableWrapper>
              <TableHead>
                <TableHeaderCell colSpan={2}>Cantidad</TableHeaderCell>
                <TableHeaderCell colSpan={6}>
                  Nombre de Producto
                </TableHeaderCell>
                <TableHeaderCell colSpan={2} $last_table>
                  Precio
                </TableHeaderCell>
              </TableHead>
              {myOrders?.cart_payload?.cart_items.map((order, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={2}>
                    {" "}
                    {order.quantity < 10
                      ? `0${order.quantity}`
                      : order.quantity}{" "}
                  </TableCell>
                  <TableCell colSpan={6}> {order.item} </TableCell>
                  <TableCell colSpan={2} $last_table>
                    S/ {order.checkout}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} $item_bold>
                  Subtotal:
                </TableCell>
                <TableCell colSpan={4}></TableCell>
                <TableCell colSpan={2} $last_table>
                  S/{myOrders?.cart_payload?.final_price}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} $item_bold>
                  Envío
                </TableCell>
                <TableCell colSpan={4}></TableCell>
                <TableCell colSpan={2} $last_table>
                  S/{" "}
                  {myOrders.shipping_method === "TIENDA"
                    ? 0
                    : myOrders?.cart_payload?.shipping_payment}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} $item_bold>
                  Comisión de tarjeta
                </TableCell>
                <TableCell colSpan={4}></TableCell>
                <TableCell colSpan={2} $last_table>
                  S/
                  {myOrders?.cart_payload?.final_price_with_card -
                    myOrders?.cart_payload?.final_price}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} $item_bold>
                  Total:
                </TableCell>
                <TableCell colSpan={4}></TableCell>
                <TableCell colSpan={2} $last_table>
                  S/
                  {myOrders.shipping_method === "TIENDA"
                    ? myOrders.cart_payload.final_price_with_card
                    : myOrders.cart_payload.final_price_with_card_and_ship}
                </TableCell>
              </TableRow>
            </TableWrapper>
            <TableMobileContainer>
              {myOrders?.cart_payload?.cart_items.map((order, index) => (
                <div className="tabla-mobile" key={index}>
                  <TableHead>
                    <TableHeaderCell $last_table $first_table colSpan={10}>
                      Cantidad
                    </TableHeaderCell>
                  </TableHead>
                  <TableRow>
                    <TableCell $last_table colSpan={10}>
                      {order.quantity < 10
                        ? `0${order.quantity}`
                        : order.quantity}
                    </TableCell>
                  </TableRow>
                  <TableHead>
                    <TableHeaderCell $last_table colSpan={10}>
                      Nombre de Producto
                    </TableHeaderCell>
                  </TableHead>
                  <TableRow>
                    <TableCell $last_table colSpan={10}>
                      {order.item}
                    </TableCell>
                  </TableRow>
                  <TableHead>
                    <TableHeaderCell $last_table colSpan={10}>
                      Precio
                    </TableHeaderCell>
                  </TableHead>
                  <TableRow>
                    <TableCell $last_table colSpan={10}>
                      S/{order.checkout}
                    </TableCell>
                  </TableRow>
                </div>
              ))}
              <br />
              <br />
              <TableHead>
                <TableHeaderCell $last_table $first_table colSpan={10}>
                  Subtotal:
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell colSpan={10} $last_table>
                  S/{myOrders?.cart_payload?.final_price}
                </TableCell>
              </TableRow>
              <br />
              <TableHead>
                <TableHeaderCell $last_table $first_table colSpan={10}>
                  Envío:
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell colSpan={10}>
                  S/{" "}
                  {myOrders.shipping_method === "TIENDA"
                    ? 0
                    : myOrders?.cart_payload?.shipping_payment}
                </TableCell>
              </TableRow>
              <br />
              <TableHead>
                <TableHeaderCell $last_table $first_table colSpan={10}>
                  Comisión de tarjeta
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell colSpan={10} $last_table>
                  S/
                  {myOrders?.cart_payload?.final_price_with_card -
                    myOrders?.cart_payload?.final_price}
                </TableCell>
              </TableRow>
              <br />
              <TableHead>
                <TableHeaderCell $last_table $first_table colSpan={10}>
                  Total:
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell colSpan={10} $last_table $item_bold>
                  S/
                  {myOrders.shipping_method === "TIENDA"
                    ? myOrders.cart_payload.final_price_with_card
                    : myOrders.cart_payload.final_price_with_card_and_ship}
                </TableCell>
              </TableRow>
            </TableMobileContainer>
          </OrdersDetailContainer>
        </div>
      ) : (
        <Loading height="50vh" />
      )}
    </OrdersUserContainer>
  );
};
