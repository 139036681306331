import React, { useContext } from "react";
import { HeaderFooter } from "./HeaderFooter";
import {
  ContainerPurchase,
  PurchaseBox,
  PurchaseDetail,
  PurchaseTotal,
  Checkout,
  StyledLink,
  ContentResumen,
  BtnCuponResumen,
} from "../styles/FinalizePurchaseStyles";
import {
  TableCell,
  TableHead,
  TableHeaderCell,
  TableMobileContainer,
  TableRow,
  TableWrapper,
} from "../styles/MyUserStyles";
import { ProductContext } from "../context/product/ProductProvider";
import { MdDeleteForever } from "react-icons/md";
import { StockSelectProduct } from "../components/StockSelectProduct";
import { IoCart } from "react-icons/io5";
import { FaUserAltSlash } from "react-icons/fa";
import { CuponProduct } from "../components/CuponProduct";
import { StockSelectCart } from "../components/StockSelectCard";

export const FinalizePurchase = () => {
  const {
    listCart,
    deleteCartItem,
    have_coupon,
    handleDeleteCupon,
    updateCartItem,
  } = useContext(ProductContext);

  const handleDeleteItem = (id) => {
    deleteCartItem({
      cart_item_id: id,
    });
  };

  const handleSelectChange = (newValue, item) => {
    updateCartItem({
      combination_id: item.combination_id,
      quantity: newValue,
    });
  };

  return (
    <HeaderFooter searchModal={true}>
      <ContainerPurchase>
        <PurchaseBox>
          <PurchaseDetail>
            <h3>Finalizar compra</h3>
            <h4>¿Tienes un código de descuento?</h4>
            <CuponProduct />
            <TableWrapper>
              <TableHead>
                <TableHeaderCell colSpan={3}>Cantidad</TableHeaderCell>
                <TableHeaderCell colSpan={4}>
                  {" "}
                  Nombre de Producto
                </TableHeaderCell>
                <TableHeaderCell colSpan={2}>Precio</TableHeaderCell>
                <TableHeaderCell colSpan={1} $last_table>
                  &nbsp;&nbsp;
                </TableHeaderCell>
              </TableHead>
              {listCart?.cart_items_json?.cart_items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>No hay productos agregados</TableCell>
                </TableRow>
              ) : (
                listCart?.cart_items_json?.cart_items.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell colSpan={3}>
                      {/* <StockSelectProduct
                        value={product.quantity}
                        stockProduct={product.quantity}
                        key={product.id}
                      /> */}
                      <StockSelectCart
                        stock={product.combination_quantity}
                        onChange={(newValue) =>
                          handleSelectChange(newValue, product)
                        }
                        value={product.quantity}
                      />
                    </TableCell>
                    <TableCell colSpan={4}> {product.item} </TableCell>
                    <TableCell colSpan={2}> S/{product.checkout}</TableCell>
                    <TableCell colSpan={1} $last_table>
                      <MdDeleteForever
                        color="#00CDE8"
                        size={30}
                        onClick={() => handleDeleteItem(product.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableWrapper>
            <TableMobileContainer>
              {listCart?.cart_items_json?.cart_items.length === 0 ? (
                <TableHead>
                  <TableHeaderCell colSpan={10}>
                    No hay productos agregados
                  </TableHeaderCell>
                </TableHead>
              ) : (
                listCart?.cart_items_json?.cart_items.map((product) => (
                  <div className="tabla-mobile" key={product.id}>
                    <TableHead>
                      <TableHeaderCell $last_table $first_table colSpan={10}>
                        Cantidad
                      </TableHeaderCell>
                    </TableHead>
                    <TableRow>
                      <TableCell $last_table colSpan={10}>
                        <StockSelectProduct
                          value={product.quantity}
                          stockProduct={product.quantity}
                          key={product.id}
                        />
                      </TableCell>
                    </TableRow>
                    <TableHead>
                      <TableHeaderCell $last_table colSpan={10}>
                        Nombre de Producto{" "}
                      </TableHeaderCell>
                    </TableHead>
                    <TableRow>
                      <TableCell $last_table colSpan={10}>
                        {" "}
                        {product.item}{" "}
                      </TableCell>
                    </TableRow>
                    <TableHead>
                      <TableHeaderCell $last_table colSpan={10}>
                        Precio
                      </TableHeaderCell>
                    </TableHead>
                    <TableRow>
                      <TableCell $last_table colSpan={10}>
                        {" "}
                        S/{product.checkout}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={10} $last_table>
                        <MdDeleteForever
                          color="#00CDE8"
                          size={30}
                          onClick={() => handleDeleteItem(product.id)}
                        />
                      </TableCell>
                    </TableRow>
                  </div>
                ))
              )}
            </TableMobileContainer>
            {listCart?.cart_items_json?.cart_items.length !== 0 && (
              <div className="price-total">
                <h5>TOTAL: </h5>
                <p> S/{listCart?.cart_items_json?.final_price} </p>
              </div>
            )}
          </PurchaseDetail>
          <PurchaseTotal>
            <div className="resumen">
              <h4>Resumen del pedido:</h4>
            </div>
            <ContentResumen>
              <div className="items">
                <h4>Subtotal: </h4>
                <p>
                  S/
                  {listCart?.cart_items_json?.final_price +
                    listCart?.cart_items_json?.discount_with_coupon}
                </p>
              </div>
              <div className="items">
                <h4>Cupón: </h4>
                <div className="cupon-item">
                  {have_coupon ? (
                    <>
                      <BtnCuponResumen onClick={handleDeleteCupon}>
                        Eliminar x
                      </BtnCuponResumen>{" "}
                      -S/
                      {listCart?.cart_items_json?.discount_with_coupon}
                    </>
                  ) : (
                    "S/0"
                  )}
                </div>
              </div>
              <div className="items">
                <h4>Subtotal: </h4>
                <p>S/{listCart?.cart_items_json?.final_price}</p>
              </div>
            </ContentResumen>
            {/* <div className="price-total">
              <h4>Subtotal: </h4>
              <p>S/{listCart?.cart_items_json?.final_price}</p>
            </div>
            <div className="price-total">
              <h4>Cupón: </h4>
            </div> */}

            {listCart?.anonymus_cart ? (
              <Checkout>
                <StyledLink
                  to={"/register"}
                  disabled={false}
                  className="btn-price"
                >
                  <FaUserAltSlash size={20} /> Registrarse
                </StyledLink>
              </Checkout>
            ) : (
              listCart?.cart_items_json?.cart_items.length !== 0 && (
                <Checkout>
                  <StyledLink
                    to={"/shipping-method"}
                    disabled={false}
                    className="btn-price"
                  >
                    <IoCart size={20} /> Terminar compra
                  </StyledLink>
                </Checkout>
              )
            )}
          </PurchaseTotal>
        </PurchaseBox>
        <br />
      </ContainerPurchase>
    </HeaderFooter>
  );
};
