import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import {
  OrdersUserContainer,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableMobileContainer,
  TableRow,
  TableWrapper,
} from "../styles/MyUserStyles";
import { clienteAxios } from "../config/axios";
import { Loading } from "./Loading";
import {
  NoDataBox,
  PaginacionBox,
  PaginacionContainer,
} from "../styles/ProductListStyles";
import { NoData } from "./NoData";
import ReactPaginate from "react-paginate";
import { StatusOrders } from "../hooks/StatusOrders";
import { Link } from "react-router-dom";
import { FormattedDateCell } from "../hooks/UseFormattedDate";

export const MyOrders = () => {
  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [countOrders, setCountOrders] = useState(0);

  const getData = async (page = 1) => {
    try {
      const respuesta = await clienteAxios.get(
        `order/myorders/?page_size=10&page=${page}`
      );
      setMyOrders(respuesta.data.results);
      setCountOrders(respuesta.data.count);
      setLoading(true);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setMyOrders([]);
      setCountOrders(0);
    }
  };

  useEffect(() => {
    getData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageClick = (data) => {
    let pagina = data.selected + 1;
    // console.log(pagina);
    setPage(pagina);
    getData(pagina);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const totalDePaginas = Math.ceil(countOrders / 10);

  return (
    <OrdersUserContainer>
      <h3>Mis pedidos</h3>
      <>
        {loading ? (
          <>
            {countOrders === 0 ? (
              <NoDataBox>
                <NoData />
              </NoDataBox>
            ) : (
              <>
                <TableWrapper>
                  <TableHead>
                    <TableHeaderCell colSpan={2}>Fecha</TableHeaderCell>
                    <TableHeaderCell colSpan={2}>
                      Total de compra
                    </TableHeaderCell>
                    <TableHeaderCell colSpan={2}>
                      Tipo de entrega
                    </TableHeaderCell>
                    <TableHeaderCell colSpan={2}>Estado</TableHeaderCell>
                    <TableHeaderCell colSpan={2} $last_table>
                      &nbsp;&nbsp;
                    </TableHeaderCell>
                  </TableHead>
                  {myOrders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell colSpan={2}>
                        {" "}
                        <FormattedDateCell date={order.modified} />{" "}
                      </TableCell>
                      <TableCell colSpan={2}>
                        S/.
                        {order.shipping_method === "TIENDA"
                          ? order.cart_payload.final_price_with_card
                          : order.cart_payload.final_price_with_card_and_ship}
                      </TableCell>
                      <TableCell colSpan={2}>
                        {" "}
                        {order.shipping_method}{" "}
                      </TableCell>
                      <TableCell colSpan={2} $content>
                        {" "}
                        {StatusOrders(order.status)}{" "}
                      </TableCell>
                      <TableCell colSpan={2} $last_table $content>
                        <Link to={`/my-user/orders/${order.id}`}>
                          Ver detalle
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableWrapper>
                <TableMobileContainer>
                  {myOrders.map((order) => (
                    <div className="tabla-mobile" key={order.id}>
                      <TableHead>
                        <TableHeaderCell $first_table colSpan={5}>
                          Fecha
                        </TableHeaderCell>
                        <TableHeaderCell $last_table $first_table colSpan={5}>
                          Total de compra
                        </TableHeaderCell>
                      </TableHead>
                      <TableRow>
                        <TableCell colSpan={5}>12/01/2023</TableCell>
                        <TableCell colSpan={5} $last_table>
                          S/.
                          {order.shipping_method === "TIENDA"
                            ? order.cart_payload.final_price_with_card
                            : order.cart_payload.final_price_with_card_and_ship}
                        </TableCell>
                      </TableRow>
                      <TableHead>
                        <TableHeaderCell colSpan={5}>
                          Tipo de entrega
                        </TableHeaderCell>
                        <TableHeaderCell $last_table colSpan={5}>
                          Estado
                        </TableHeaderCell>
                      </TableHead>
                      <TableRow>
                        <TableCell colSpan={5}>
                          {order.shipping_method}
                        </TableCell>
                        <TableCell $last_table colSpan={5}>
                          {StatusOrders(order.status)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={10} $last_table>
                          <Link to={`/my-user/orders/${order.id}`}>
                            Ver detalle
                          </Link>
                        </TableCell>
                      </TableRow>
                    </div>
                  ))}
                </TableMobileContainer>
              </>
            )}
            {countOrders !== 0 && (
              <PaginacionContainer>
                <PaginacionBox>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    pageCount={totalDePaginas}
                    marginPagesDisplayed={3}
                    // pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousClassName={"anterior"}
                    nextClassName={"siguiente"}
                    activeLinkClassName={"activePaginacionFondo"}
                    activeClassName={"activePaginacionFondo"}
                  />
                </PaginacionBox>
              </PaginacionContainer>
            )}
          </>
        ) : (
          <Loading height="50vh" />
        )}
      </>
    </OrdersUserContainer>
  );
};
