import React, { useEffect, useState } from "react";
import {
  ContainerCategory,
  ListCategoryContainer,
  RedirectCategory,
} from "../styles/CategoriesStyles";
import BGCategory from "../assets/BG-category.png";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { clienteAxios } from "../config/axios";
import { Loading } from "../components/Loading";
import { CategorieItem } from "../components/CategorieItem";
import { HeaderFooter } from "./HeaderFooter";
import { NoData } from "../components/NoData";
import { NoDataBox } from "../styles/ProductListStyles";

export const CategoryList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const goBack = () => {
    navigate(-1); // Navegar hacia atrás en la historia
  };

  useEffect(() => {
    const getData = async () => {
      const res = await clienteAxios.get(`product/kind?category=${slug}`);
      // console.log(res);
      setItems(res.data);
      setLoading(true);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <HeaderFooter searchModal={true}>
      <ContainerCategory>
        <RedirectCategory>
          <img src={BGCategory} alt="bg-category" />
          <div>
            <FaArrowLeft onClick={goBack} color="#ffffff" size={20} />
            <h4>Tienda/</h4>
            <h6> {slug} </h6>
          </div>
        </RedirectCategory>
        <>
          {loading ? (
            <>
              <ListCategoryContainer>
                {items.map((item) => {
                  return (
                    <CategorieItem
                      key={item.slug}
                      item={item}
                      slugCategory={slug}
                    />
                  );
                })}
                {items.length === 0 && (
                  <NoDataBox>
                    <NoData />
                  </NoDataBox>
                )}
              </ListCategoryContainer>
            </>
          ) : (
            <Loading height="50vh" />
          )}
        </>
      </ContainerCategory>
    </HeaderFooter>
  );
};
