import styled from "styled-components";

export const CartSidebarContainer = styled.div`
  p {
    margin-top: 1em;
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
  }
`;
export const CerrarModalStyle = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background-color: #ffffff;
  svg {
    cursor: pointer;
  }
`;

export const BoxCart = styled.div`
  margin-top: 4em;
`;

export const HR = styled.hr`
  border: 1px solid #d9d9d9;
`;

export const CartItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2em 4em;
  gap: 2em;
  img {
    max-width: 150px !important;
    height: 150px;
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 3em;
  }
`;

export const CartData = styled.div`
  margin-top: 1em;
  h5 {
    font-size: 1em;
    font-weight: 700;
  }
  p {
    font-size: 1em;
    font-weight: 400;
    text-align: start;
  }
  @media screen and (max-width: 900px) {
    div {
      display: flex;
      gap: 1em;
      align-items: center;
    }
  }
`;

export const CartAction = styled.div`
  display: flex;
  margin-top: 1em;
  gap: 1em;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const Checkout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2em auto;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1em;
    border: none;
    background-color: #00cde8;
    color: #ffffff;
    padding: 0.5em 3em;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const NoItemsCart = styled.div`
  height: 60vh;
  display: grid;
  place-content: center;
`;
