import React, { useContext } from "react";
import { Carrousel } from "../components/Carrousel";
import { ModuleEventAndProduct } from "./ModuleEventAndProduct";
import { BannerTopFooter } from "../components/BannerTopFooter";
import { ProductList } from "../components/ProductList";
import { ProductContext } from "../context/product/ProductProvider";
import { HeaderFooter } from "./HeaderFooter";

export const Ecommerce = () => {
  const { handleAddToCart, productCaroucel, loadingCaroucel, eventList } =
    useContext(ProductContext);

  return (
    <HeaderFooter>
      <div>
        <Carrousel
          productCaroucel={productCaroucel}
          loadingCaroucel={loadingCaroucel}
        />
        <ModuleEventAndProduct eventList={eventList} />
        <ProductList onAddToCart={handleAddToCart} />
        <BannerTopFooter />
      </div>
    </HeaderFooter>
  );
};
