import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderContainer = styled.header`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  top: 0;
  position: fixed;
  z-index: 999;
`;

export const Header = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  width: 100%;
  height: auto;
  @media (max-width: 1350px) {
    padding: 50px 10px;
  }
  @media (max-width: 700px) {
    padding: 50px 20px;
  }
  @media (max-width: 450px) {
    padding: 20px 10px;
  }

  @media (max-height: 500px) {
    padding: 10px 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 3em;
  margin-right: 2em;
  svg {
    cursor: pointer;
  }
  @media (max-width: 700px) {
    margin-right: 0;
    gap: 1em;
  }
`;

export const Img = styled.img`
  height: 51px;
  @media (max-width: 900px) {
    width: 150px;
    object-fit: contain;
  }
  @media (max-width: 450px) {
    width: 100px;
  }
`;

export const BoxSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  width: 550px;
  @media (max-width: 1350px) {
    width: 450px;
  }
  @media (max-width: 980px) {
    display: none;
  }
`;

export const BoxSearchMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  width: 95%;
`;

export const SelectCategory = styled.div`
  position: relative;
  div {
    background-color: #00cde8;
    padding: 14px 10px;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    color: #ffffff;
    border: 1px solid #2e2daa;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    span {
      font-size: 0.9em;
    }
    cursor: pointer;
    @media (max-width: 1350px) {
      padding: 13.5px 10px;
      span {
        font-size: 0.8em;
      }
    }
  }
`;

export const SelectCategoryBox = styled.div`
  span {
    white-space: nowrap;
    text-transform: capitalize;
  }
`;

export const Dropdown = styled.div`
  background-color: #ededed;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  border-radius: 0 0 4px 4px;
  display: ${({ $isOpenCategory }) => ($isOpenCategory ? "block" : "none")};
  z-index: 1500;
  padding: 0.5em 1em;
  p {
    margin: 0.5em;
    padding: 0.8em 2em;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 14px 20px;
  border: 1px solid #2e2daa;
  border-left: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  outline: none;
  font-size: 1em;
  @media (max-width: 1350px) {
    font-size: 0.8em;
  }
`;
export const SearchIcon = styled(BsSearch)`
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  color: #383838;
  cursor: pointer;
  font-size: 1.5em;
`;

export const BtnLogin = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;

export const BoxUser = styled.div`
  display: flex;
  position: relative;
  gap: 0.5em;
  align-items: center;
  &:hover {
    color: #000000;
  }
  p {
    color: #555555;
    font-weight: 700;
    cursor: pointer;
    text-transform: capitalize;
    &.user-data {
      color: #00cde8;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 1em;
    }
  }
  p {
    @media (max-width: 900px) {
      display: none;
    }
  }
  @media (max-width: 1350px) {
    gap: 0.1em;
    p {
      font-size: 0.8em;
    }
  }
  @media (max-width: 450px) {
    p {
      font-size: 0.6em;
    }
  }
`;

export const BoxIconCartFavorite = styled.div`
  display: flex;
  gap: 1em;
`;

//whatsApp
export const WhatsAppBtn = styled.button`
  border: 2px solid #ffffff;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0.5em 1em;
  background-color: #21cf12;
  color: #ffffff;
  p {
    font-weight: 700;
    font-size: 0.7em;
  }
`;

// Multimenu
export const NavArea = styled.div`
  /* position: fixed; */
  /* z-index: 999; */
  width: 100%;
  background-color: #5352b8;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0.5em 0px;
  gap: 4em;
  /* height: 58px; */
  @media (max-width: 980px) {
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
  }
`;

export const ShowCategories = styled.div`
  @media (max-width: 900px) {
    display: none; /* Ocultar el menú en pantallas pequeñas */
  }
`;

export const ContainerMenuBurguer = styled.div``;

export const MenusHeader = styled.ul`
  display: flex;
  list-style: none;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 0.75em;
  font-weight: 400;
  /* flex-direction: column; */
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const BurgerMenuIcon = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 980px) {
    display: flex;
    color: #ffffff;
    align-items: center;
    gap: 0.8em;
  }
`;

export const BurgerMenu = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: ${(props) => (props.$isOpen ? "block" : "none")};
  }
`;

export const MenuItemsHeader = styled.li`
  position: relative;
  font-size: 14px;
  a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    padding: 1em 1.5em;
    text-align: left;
  }
  button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 1em 1.5em;

    text-align: left;
    svg {
      transition: transform 0.3s ease;
    }
    &:hover svg {
      transform: rotate(-90deg);
    }
  }
  &:hover {
    background-color: #5c5abb;
  }
`;

export const DropdownHeaderMenu = styled.ul`
  position: absolute;
  right: 0;
  left: 3em;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  width: 100%;
  margin-top: -1em;
  list-style: none;
  background-color: #5352b8;
  border-radius: 0.5rem;
  display: ${(props) => (props.$show === "true" ? "block" : "none")};

  &.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 2em;
  }
`;

export const DropdownMenu = styled.div`
  position: ${(props) => (props.$userData ? "relative" : "absolute")};
  top: ${(props) => (props.$userData ? "0" : "3em")};
  left: ${(props) => (props.$userData ? "0" : "-4em")};
  background-color: #ffffff;
  z-index: ${(props) => (props.$userData ? "0" : "1500")};
  border-radius: 10px;
  min-width: 220px;
  height: ${(props) => (props.$userData ? "100%" : "auto")};
  font-weight: 500;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  padding: ${(props) => (props.$userData ? "0.5em 0" : "0")};
`;

export const MenuItem = styled.div`
  a {
    padding: 0.5em 2em;
    margin-top: ${(props) => (props.$espace ? "1.5em" : "0em")};
    margin-bottom: ${(props) => (props.$exit ? "1.5em" : "0em")};
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.7em;
    width: 100%;
    /* color: ${(props) => (props.$exit ? "#FF0000" : "#3D3D3D")}; */
    color: ${(props) => (props.$activeLink ? "#ffffff" : "#3D3D3D")};
    font-weight: ${(props) => (props.$exit ? "bold" : "500")};
    text-decoration: none;
    background-color: ${(props) => (props.$activeLink ? "#00CDE8" : "#ffffff")};
    &:hover {
      background-color: ${(props) =>
        props.$activeLink ? "#1ad4ec" : "#efefef"};
    }
    p {
      display: flex;
      align-items: center;
      gap: 0.7em;
      color: ${(props) => (props.$exit ? "#FF0000" : "#3D3D3D")};
    }
  }
`;

//Sidebar animation

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${(props) => (props.$activar ? "block" : "none")};
`;

export const SidebarStyle = styled.div`
  position: fixed;
  padding-top: 30px;
  height: 100%;
  background: #ffffff;
  width: 550px;
  right: ${(props) => (props.$activar ? "0" : "-550px")};
  transition: all 0.5s ease-in-out;
  overflow-y: auto; /* Agregar scroll vertical */
  z-index: 1500;
  @media screen and (max-width: 900px) {
    width: 350px;
    right: ${(props) => (props.$activar ? "0" : "-350px")};
  }
`;

//Cart icon

export const CartIcon = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    color: #555555;
    &:hover {
      color: #00cde8;
    }
  }
`;

export const CartCount = styled.div`
  position: absolute;
  top: 10px;
  right: -4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #00cde8;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
