import React, { Fragment, useContext, useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  BoxSliderRange,
  BtnLinkCategory,
  CategoryContainer,
  CategoryHeader,
  CategoryImage,
  CategoryName,
  Container,
  ContainerSliderRange,
  HR,
  KindContainer,
  KindItem,
  SliderInput,
  SubKindContainer,
  SubKindItem,
} from "../styles/FilterStyles";
import { ProductContext } from "../context/product/ProductProvider";

export const ExpanseFilter = () => {
  const {
    categoryList,
    listProductFilter,
    categoryFilter,
    kindFilter,
    subkindFilter,
    // changeCategory,
    changeKind,
    changeSubKind,
  } = useContext(ProductContext);

  const [expandedCategory, setExpandedCategory] = useState(
    categoryFilter ? categoryFilter : null
  );
  const [expandedKinds, setExpandedKinds] = useState({
    [categoryFilter]: {
      [kindFilter]: true, // Establece el kindFilter como expandido inicialmente
    },
  });
  const [selectedItemId, setSelectedItemId] = useState(
    categoryFilter ? categoryFilter : null
  );
  const [selectedSubItemId, setSelectedSubItemId] = useState(
    kindFilter ? kindFilter : null
  );
  const [selectedSubKinds, setSelectedSubKinds] = useState(
    subkindFilter ? subkindFilter : null
  );

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExpandedCategory(categoryFilter);
    setSelectedItemId(categoryFilter);
    setSelectedSubItemId(kindFilter);
    setExpandedKinds({
      [categoryFilter]: {
        [kindFilter]: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter, kindFilter]);

  useEffect(() => {
    if (isMounted) {
      handleFilterListProdutc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId, selectedSubItemId, selectedSubKinds]);

  const toggleCategory = async (categoryId) => {
    setExpandedCategory(categoryId === expandedCategory ? null : categoryId);
    setSelectedItemId(categoryId);
    // setSelectedSubItemId(kindFilter ? kindFilter : null);
    setSelectedSubItemId(null);
    setSelectedSubKinds(null);
    // changeCategory({ category: categoryId });
  };

  const toggleKind = (categoryId, kindId) => {
    setExpandedKinds((prevState) => {
      const newExpandedKinds = { ...prevState };
      if (newExpandedKinds[categoryId]) {
        Object.keys(newExpandedKinds[categoryId]).forEach((key) => {
          if (key !== kindId.toString()) {
            newExpandedKinds[categoryId][key] = false;
          }
        });
      }
      newExpandedKinds[categoryId] = {
        ...newExpandedKinds[categoryId],
        [kindId]: !prevState[categoryId]?.[kindId],
      };
      return newExpandedKinds;
    });
    setSelectedSubItemId(kindId);
    changeKind({ kind: kindId });
  };

  const toggleSubKind = (id, event) => {
    event.stopPropagation();
    setSelectedSubKinds(id);
    changeSubKind({ subkind: id });
  };

  //Range
  const [minValue, setMinValue] = useState(10);
  const [maxValue, setMaxValue] = useState(500);

  const handleMinChange = (event) => {
    setMinValue(parseInt(event.target.value));
  };

  const handleMaxChange = (event) => {
    setMaxValue(parseInt(event.target.value));
  };

  const handleFilterListProdutc = async () => {
    await listProductFilter({
      page_size: 16,
      page: 1,
      category: selectedItemId,
      kind: selectedSubItemId ? selectedSubItemId : "",
      subkind: selectedSubKinds ? selectedSubKinds : "",
    });
  };

  return (
    <Container>
      {categoryList.map((category) => (
        <CategoryContainer key={category.id}>
          <CategoryHeader
            onClick={() => toggleCategory(category.slug)}
            $isSelected={selectedItemId === category.slug}
          >
            <CategoryImage src={category.image} alt={category.name} />
            <CategoryName>{category.name}</CategoryName>
          </CategoryHeader>
          {expandedCategory === category.slug && category.kinds.length > 0 && (
            <KindContainer>
              {category.kinds.slice(0, 4).map((kind) => (
                <Fragment key={kind.slug}>
                  <KindItem
                    onClick={() => toggleKind(category.slug, kind.slug)}
                    $isSelected={selectedSubItemId === kind.slug}
                  >
                    {kind.name}
                  </KindItem>
                  {expandedKinds[category.slug]?.[kind.slug] &&
                    kind.subkinds.length > 0 && (
                      <SubKindContainer>
                        {kind.subkinds.map((subkind, index) => (
                          <Fragment key={subkind.slug}>
                            <SubKindItem
                              //   onClick={(event) => event.stopPropagation()}
                              onClick={(event) =>
                                toggleSubKind(subkind.slug, event)
                              }
                              $isSelected={selectedSubKinds === subkind.slug}
                            >
                              {selectedSubKinds === subkind.slug ? (
                                <IoIosArrowForward color="#00CDE8" />
                              ) : (
                                <IoIosArrowForward color="transparent" />
                              )}
                              {subkind.name}
                            </SubKindItem>
                            {index < kind.subkinds.length - 1 && <HR />}
                          </Fragment>
                        ))}
                      </SubKindContainer>
                    )}
                </Fragment>
              ))}
              {category.kinds.length > 4 && (
                <BtnLinkCategory>
                  <Link to={`/category/${selectedItemId}`}>
                    Ver más expansiones
                  </Link>
                </BtnLinkCategory>
              )}
            </KindContainer>
          )}
        </CategoryContainer>
      ))}
      {false && (
        <ContainerSliderRange>
          <h4>Rango de precios</h4>
          <BoxSliderRange>
            <SliderInput
              type="range"
              min={10}
              max={249}
              value={minValue}
              onChange={handleMinChange}
            />
            <SliderInput
              type="range"
              min={250}
              max={500}
              value={maxValue}
              onChange={handleMaxChange}
            />
          </BoxSliderRange>
          <h5>
            <strong>Precio:</strong> S/{minValue} - S/{maxValue}{" "}
          </h5>
          <button onClick={handleFilterListProdutc}>Filtrar</button>
        </ContainerSliderRange>
      )}
    </Container>
  );
};
