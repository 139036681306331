import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth/AuthProvider";
import {
  FormRegister,
  InputForm,
  LabelForm,
  SelectForm,
  SesionRegister,
} from "../styles/LoginStyles";
import { ActionBtnUser } from "../styles/MyUserStyles";
import { useNavigate } from "react-router-dom";
import { clienteAxios } from "../config/axios";

export const MyDataUser = () => {
  const { usuarioData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userState, setUserState] = useState({
    email: "",
    first_name: "",
    last_name: "",
    gender: "",
    phone: "",
    dni: "",
    birth_date: "",
  });

  useEffect(() => {
    if (usuarioData) {
      setUserState({
        email: usuarioData.email || "",
        first_name: usuarioData.first_name || "",
        last_name: usuarioData.last_name || "",
        gender: usuarioData.gender || "",
        phone: usuarioData.phone || "",
        dni: usuarioData.dni || "",
        birth_date: usuarioData.birth_date || "",
        // password: "",
        // confirm_password: "",
      });
    }
  }, [usuarioData]);

  const { email, first_name, last_name, gender, phone, dni, birth_date } =
    userState;

  const [errors, setErrors] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = (e) => {
    setUserState({
      ...userState,
      [e.target.name]: e.target.value,
    });
  };

  const getData = async () => {
    setIsUpdating(true);
    try {
      const respuesta = await clienteAxios.patch("account/detail/", userState);
      setUpdateSuccess(false);
      if (respuesta.status === 200) {
        setUpdateSuccess(true);
        setTimeout(() => {
          setUpdateSuccess(null);
        }, 5000);
        return;
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = {
      email: "Email",
      // password: "Contraseña",
      first_name: "Nombres",
      last_name: "Apellidos",
      gender: "Género",
      phone: "Teléfono",
      dni: "DNI",
      birth_date: "Fecha de nacimiento",
    };

    const newErrors = {};

    // Iterar sobre los campos y establecer los que estén en blanco o no cumplan con la validación como obligatorios
    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (!userState[fieldName].trim()) {
        newErrors[fieldName] = `${fieldLabel} es obligatorio`;
      } else {
        // Aquí agregamos la validación específica para dni y teléfono
        if (fieldName === "dni" && userState[fieldName].length !== 8) {
          newErrors[fieldName] = `El ${fieldLabel} debe tener 8 dígitos`;
        }
        if (fieldName === "phone" && userState[fieldName].length !== 9) {
          newErrors[fieldName] = `El ${fieldLabel} debe tener 9 dígitos`;
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

      setTimeout(() => {
        setErrors({});
      }, 5000);
      return;
    }
    getData();
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <SesionRegister $myUserData={true}>
      <h3>Mis datos</h3>
      <FormRegister>
        <div>
          <LabelForm
            htmlFor="first_name"
            className={errors?.first_name ? "error-form" : ""}
          >
            Nombres <span>*</span>
            {errors?.first_name && ` - ${errors.first_name}`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="first_name"
              id="first_name"
              value={first_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="last_name"
            className={errors?.last_name ? "error-form" : ""}
          >
            Apellidos <span>*</span>
            {errors?.last_name && ` - ${errors.last_name}`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="last_name"
              id="last_name"
              value={last_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm htmlFor="type">
            Tipo de documento <span>*</span>
          </LabelForm>
          <div>
            <SelectForm id="type" defaultValue={"DNI"}>
              <option value="DNI">DNI</option>
            </SelectForm>
          </div>
        </div>
        <div>
          <LabelForm htmlFor="dni" className={errors?.dni ? "error-form" : ""}>
            Número de Doc. <span>*</span>
            {errors?.dni && ` - ${errors.dni}`}
          </LabelForm>
          <div>
            <InputForm
              maxLength="8"
              minLength="8"
              type="number"
              name="dni"
              id="dni"
              value={dni}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm htmlFor="email">
            Correo electrónico <span>*</span>
          </LabelForm>
          <div>
            <InputForm
              type="email"
              name="email"
              id="email"
              value={email}
              disabled
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="phone"
            className={errors?.phone ? "error-form" : ""}
          >
            Número de teléfono <span>*</span>
            {errors?.phone && ` - ${errors.phone}`}
          </LabelForm>
          <div>
            <InputForm
              type="number"
              name="phone"
              id="phone"
              value={phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="gender"
            className={errors?.gender ? "error-form" : ""}
          >
            Genero {errors?.gender && ` - ${errors.gender}`}
          </LabelForm>
          <div>
            <SelectForm
              name="gender"
              id="gender"
              value={gender}
              onChange={handleChange}
            >
              <option value="">Elegir una opción</option>
              <option value="HOMBRE">Masculino</option>
              <option value="MUJER">Femenino</option>
              <option value="NO_RESPONDE">Otro</option>
            </SelectForm>
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="birth_date"
            className={errors?.birth_date ? "error-form" : ""}
          >
            Cumpleaños {errors?.birth_date && ` - ${errors.birth_date}`}
          </LabelForm>
          <div>
            <InputForm
              type="date"
              name="birth_date"
              id="birth_date"
              value={birth_date}
              onChange={handleChange}
            />
          </div>
        </div>
        <ActionBtnUser $sendData={true}>
          <button type="submit" onClick={handleSubmit}>
            {isUpdating ? "Actualizando..." : "Guardar"}
          </button>
        </ActionBtnUser>
        <ActionBtnUser>
          <button type="button" onClick={handleCancel}>
            Cancelar
          </button>
        </ActionBtnUser>
      </FormRegister>
      <br />
      {updateSuccess && (
        <div className="update-user">
          {" "}
          <span>Se actualizo correctamente</span>{" "}
        </div>
      )}
    </SesionRegister>
  );
};
