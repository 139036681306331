import React, { useState } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

const StockText = styled.div`
  background-color: #ff004d;
  color: #ffffff;
  padding: 0.5em 1em;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const StockSelectContainer = styled.div`
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  select {
    appearance: none;
    border: 1px solid #ced4da;
    background-color: transparent;
    border: none;
    font-size: inherit;
    border-radius: 4px;
    padding: 0.5em 2.2em;
    padding-left: 1.5em;
    width: 100px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    &:focus {
      border: 1px solid #00cde8;
      outline: none;
    }
    option {
      border: 1px solid #00cde8;
    }
  }
  select:hover {
    border-color: #999999;
  }
`;

const CustomIcon = styled(IoIosArrowDown)`
  position: absolute;
  right: 8px;
  z-index: -1;
  color: #555555 !important;
`;

export const StockSelectProduct = ({
  value,
  stockProduct = 1,
  onChange,
  isCart = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(stockProduct);
  const [changeValue, setChangeValue] = useState(false);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
      setChangeValue(true);
      if (isCart) {
        setTimeout(() => {
          setChangeValue(false);
        }, 1000);
      }
    }
  };

  if (value === 0 || value < 0) {
    return <StockText>Agotado</StockText>;
  } else {
    const options = [];
    for (let i = 1; i <= value; i++) {
      options.push(
        <option key={i} value={i}>
          {i < 10 && 0}
          {i}
        </option>
      );
    }
    return (
      <StockSelectContainer>
        <select
          value={changeValue ? selectedValue : stockProduct}
          onChange={handleChange}
        >
          {options}
        </select>
        <CustomIcon />
      </StockSelectContainer>
    );
  }
};
