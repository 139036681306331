import React from "react";
import {
  BtnPurchaseSummary,
  ShippingPrice,
} from "../styles/FinalizePurchaseStyles";
import { InputForm, LabelForm, SelectForm } from "../styles/LoginStyles";
import { AddRegisterBox } from "../styles/MyUserStyles";

export const HomeDelivery = ({
  userState,
  setUserState,
  errors,
  handleSubmit,
}) => {
  const {
    department,
    province,
    district,
    dni,
    address,
    postal_code,
    celphone,
    reference,
  } = userState;

  // const [errors, setErrors] = useState(null);

  const handleChange = (e) => {
    setUserState({
      ...userState,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const requiredFields = {
  //     department: "Departamento",
  //     province: "Provincia",
  //     district: "Distrito",
  //     dni: "DNI",
  //     address: "Dirección",
  //     postal_code: "Codigo postal",
  //     celphone: "Celular",
  //     reference: "Referencia",
  //   };

  //   const newErrors = {};

  //   // Iterar sobre los campos y establecer los que estén en blanco o no cumplan con la validación como obligatorios
  //   Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
  //     if (!userState[fieldName].trim()) {
  //       newErrors[fieldName] = `${fieldLabel} es obligatorio`;
  //     } else {
  //       // Aquí agregamos la validación específica para dni y teléfono
  //       if (fieldName === "dni" && userState[fieldName].length !== 8) {
  //         newErrors[fieldName] = `El ${fieldLabel} debe tener 8 dígitos`;
  //       }
  //       if (fieldName === "celphone" && userState[fieldName].length !== 9) {
  //         newErrors[fieldName] = `El ${fieldLabel} debe tener 9 dígitos`;
  //       }
  //     }
  //   });

  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
  //     setTimeout(() => {
  //       setErrors({});
  //     }, 5000);
  //     return;
  //   }
  //   if (disabled) {
  //     setErrors({
  //       accept_term: "Es obligatorio",
  //     });
  //     setTimeout(() => {
  //       setErrors({});
  //     }, 5000);
  //     return;
  //   }
  //   setShowShipping(false);
  // };

  return (
    <>
      <AddRegisterBox>
        <div>
          <LabelForm
            htmlFor="department"
            className={errors?.department ? "error-form" : ""}
          >
            Región / Departamento <span>*</span>
            {errors?.first_name && ` -Obligatorio`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="department"
              id="department"
              value={department}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="province"
            className={errors?.province ? "error-form" : ""}
          >
            Provincia <span>*</span>
            {errors?.province && ` -Obligatorio`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="province"
              id="province"
              value={province}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="district"
            className={errors?.district ? "error-form" : ""}
          >
            Distrito <span>*</span>
            {errors?.district && ` -Obligatorio`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="district"
              id="district"
              value={district}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="address"
            className={errors?.address ? "error-form" : ""}
          >
            Dirección <span>*</span>
            {errors?.address && ` -Obligatorio`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="address"
              id="address"
              value={address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="postal_code"
            className={errors?.postal_code ? "error-form" : ""}
          >
            Código Postal
            {errors?.address && ` -postal_code`}
          </LabelForm>
          <div>
            <InputForm
              type="number"
              name="postal_code"
              id="postal_code"
              value={postal_code}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm
            htmlFor="celphone"
            className={errors?.celphone ? "error-form" : ""}
          >
            Número de teléfono<span>*</span>
            {errors?.celphone && ` - ${errors.celphone}`}
          </LabelForm>
          <div>
            <InputForm
              type="number"
              name="celphone"
              id="celphone"
              value={celphone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm htmlFor="type">
            Tipo de documento <span>*</span>
          </LabelForm>
          <div>
            <SelectForm name="type" id="type" defaultValue="DNI">
              <option value="DNI">DNI</option>
            </SelectForm>
          </div>
        </div>
        <div>
          <LabelForm htmlFor="dni" className={errors?.dni ? "error-form" : ""}>
            Número de Doc. <span>*</span>
            {errors?.dni && ` - ${errors.dni}`}
          </LabelForm>
          <div>
            <InputForm
              maxLength="8"
              minLength="8"
              type="number"
              name="dni"
              id="dni"
              value={dni}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="block">
          <LabelForm
            htmlFor="reference"
            className={errors?.reference ? "error-form" : ""}
          >
            Referencias de la ubicación:
            {errors?.dni && ` - ${errors.reference}`}
          </LabelForm>
          <div>
            <InputForm
              type="text"
              name="reference"
              id="reference"
              value={reference}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <LabelForm className={errors?.accept_term ? "error-form" : ""}>
            Acepta los terminos y condiciones <span>*</span>
            {errors?.accept_term && ` - ${errors.accept_term}`}
          </LabelForm>
        </div>
      </AddRegisterBox>

      <ShippingPrice>
        <p>
          Precio de envío: &nbsp;&nbsp; &nbsp;&nbsp; <span>S/15</span>
        </p>
        <div>
          <BtnPurchaseSummary
            // disabled={disabled}
            // onClick={() => setShowShipping(!showShipping)}
            onClick={handleSubmit}
          >
            Siguiente{" "}
          </BtnPurchaseSummary>
        </div>
      </ShippingPrice>
    </>
  );
};
