import { createGlobalStyle } from "styled-components";
import "@fontsource/montserrat";

const GlobalStyles = createGlobalStyle`

    /* *{
        outline: 1px solid red !important;
    } */

    *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
`;
export default GlobalStyles;
