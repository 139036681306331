import React from "react";
import * as Style from "../styles/ModuleEventAndProductStyle";
import { UpcomingEvents } from "../components/UpcomingEvents";
import { FeaturedProducts } from "../components/FeaturedProducts";
import { Categories } from "../components/Categories";
import { OffersAndNews } from "../components/OffersAndNews";

export const ModuleEventAndProduct = ({ eventList }) => {
  return (
    <>
      <Style.ContainerView>
        <UpcomingEvents eventList={eventList} />
        <FeaturedProducts />
      </Style.ContainerView>
      <Categories />
      <OffersAndNews />
    </>
  );
};
