import { useState, useEffect } from "react";

export const useFormattedDate = (initialDate) => {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const dateObject = new Date(initialDate);

    // Obtener los componentes de la fecha
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();

    // Formatear la fecha en el formato deseado (DD/MM/YYYY)
    const formattedDate = `${day}/${month}/${year}`;

    // Establecer la fecha formateada en el estado
    setFormattedDate(formattedDate);
  }, [initialDate]);

  return formattedDate;
};

export const FormattedDateCell = ({ date }) => {
  const formattedDate = useFormattedDate(date);

  return formattedDate;
};
