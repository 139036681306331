import React, { useContext } from "react";
import { ProductContext } from "../context/product/ProductProvider";
import {
  BtnCuponResumen,
  BtnPurchaseProducts,
  PurchaseDetail,
} from "../styles/FinalizePurchaseStyles";
import {
  TableCell,
  TableHead,
  TableHeaderCell,
  TableMobileContainer,
  TableRow,
  TableWrapper,
} from "../styles/MyUserStyles";
import { UseScrollToTopOnMount } from "../hooks/UseScrollToTopOnMount";
import { CulqiBtnConfig } from "./CulqiBtnConfig";

export const PurchaseSummary = ({ costDelivery, userState }) => {
  // console.log(costDelivery, "costDelivery");
  // console.log(userState, "userState");

  UseScrollToTopOnMount();
  const { listCart, have_coupon, handleDeleteCupon } =
    useContext(ProductContext);
  // const [showModal, setShowModal] = useState(false);
  // console.log(listCart, "listCart");

  return (
    <PurchaseDetail>
      <h3>Resumen de tu compra y envío</h3>
      {/* <p>¿Tienes un código de descuento?</p> */}
      <TableWrapper>
        <TableHead>
          <TableHeaderCell colSpan={3}>Cantidad</TableHeaderCell>
          <TableHeaderCell colSpan={5}> Nombre de Producto</TableHeaderCell>
          <TableHeaderCell colSpan={2} $last_table>
            Precio
          </TableHeaderCell>
        </TableHead>
        {listCart?.cart_items_json?.cart_items.length === 0 ? (
          <TableRow>
            <TableCell colSpan={10}>No hay productos agregados</TableCell>
          </TableRow>
        ) : (
          listCart?.cart_items_json?.cart_items.map((product) => (
            <TableRow key={product.id}>
              <TableCell colSpan={3}>
                {product.quantity < 10
                  ? `0${product.quantity}`
                  : product.quantity}
              </TableCell>
              <TableCell colSpan={5}> {product.item} </TableCell>
              <TableCell colSpan={2} $last_table>
                {" "}
                S/{product.checkout}
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TableCell colSpan={3} $item_bold>
            Subtotal:
          </TableCell>
          <TableCell colSpan={5}></TableCell>
          <TableCell colSpan={2} $last_table>
            S/
            {listCart?.cart_items_json?.final_price +
              listCart?.cart_items_json?.discount_with_coupon}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} $item_bold>
            Cupón:
          </TableCell>
          <TableCell
            colSpan={7}
            $last_table
            style={{
              justifyContent: "flex-end",
              paddingRight: have_coupon ? "10%" : "13%",
            }}
          >
            {have_coupon ? (
              <>
                <BtnCuponResumen onClick={handleDeleteCupon}>
                  Eliminar x
                </BtnCuponResumen>{" "}
                &nbsp;&nbsp;&nbsp; -S/
                {listCart?.cart_items_json?.discount_with_coupon}
              </>
            ) : (
              "S/0"
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} $item_bold>
            Envío
          </TableCell>
          <TableCell colSpan={5}></TableCell>
          <TableCell colSpan={2} $last_table>
            S/{costDelivery ? 15 : 0}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} $item_bold>
            Comisión de tarjeta
          </TableCell>
          <TableCell colSpan={5}></TableCell>
          <TableCell colSpan={2} $last_table>
            S/
            {(
              listCart?.cart_items_json?.final_price_with_card -
              listCart?.cart_items_json?.final_price
            ).toFixed(2)}
          </TableCell>
        </TableRow>
      </TableWrapper>
      <TableMobileContainer>
        {listCart?.cart_items_json?.cart_items.length === 0 ? (
          <TableHead>
            <TableHeaderCell colSpan={10}>
              No hay productos agregados
            </TableHeaderCell>
          </TableHead>
        ) : (
          listCart?.cart_items_json?.cart_items.map((product) => (
            <div className="tabla-mobile" key={product.id}>
              <TableHead>
                <TableHeaderCell $last_table $first_table colSpan={10}>
                  Cantidad
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell $last_table colSpan={10}>
                  {product.quantity < 10
                    ? `0${product.quantity}`
                    : product.quantity}
                </TableCell>
              </TableRow>
              <TableHead>
                <TableHeaderCell $last_table colSpan={10}>
                  Nombre de Producto{" "}
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell $last_table colSpan={10}>
                  {" "}
                  {product.item}{" "}
                </TableCell>
              </TableRow>
              <TableHead>
                <TableHeaderCell $last_table colSpan={10}>
                  Precio
                </TableHeaderCell>
              </TableHead>
              <TableRow>
                <TableCell $last_table colSpan={10}>
                  {" "}
                  S/{product.checkout}
                </TableCell>
              </TableRow>
            </div>
          ))
        )}
        <br />
        <br />
        <TableHead>
          <TableHeaderCell $last_table $first_table colSpan={10}>
            Subtotal:
          </TableHeaderCell>
        </TableHead>
        <TableRow>
          <TableCell colSpan={10} $last_table>
            S/
            {listCart?.cart_items_json?.final_price +
              listCart?.cart_items_json?.discount_with_coupon}
          </TableCell>
        </TableRow>
        <br />
        <TableHead>
          <TableHeaderCell $last_table $first_table colSpan={10}>
            Cupón:
          </TableHeaderCell>
        </TableHead>
        <TableRow>
          <TableCell colSpan={10} $item_bold>
            {have_coupon ? (
              <>
                <BtnCuponResumen onClick={handleDeleteCupon}>
                  Eliminar x
                </BtnCuponResumen>{" "}
                &nbsp;&nbsp;&nbsp; -S/
                {listCart?.cart_items_json?.discount_with_coupon}
              </>
            ) : (
              "S/0"
            )}
          </TableCell>
        </TableRow>
        <br />
        <TableHead>
          <TableHeaderCell $last_table $first_table colSpan={10}>
            Envío:
          </TableHeaderCell>
        </TableHead>
        <TableRow>
          <TableCell colSpan={10} $item_bold>
            S/{costDelivery ? 15 : 0}
          </TableCell>
        </TableRow>
        <br />
        <TableHead>
          <TableHeaderCell $last_table $first_table colSpan={10}>
            Comisión de tarjeta
          </TableHeaderCell>
        </TableHead>
        <TableRow>
          <TableCell colSpan={10} $last_table>
            S/
            {(
              listCart?.cart_items_json?.final_price_with_card -
              listCart?.cart_items_json?.final_price
            ).toFixed(2)}
          </TableCell>
        </TableRow>
      </TableMobileContainer>
      {listCart?.cart_items_json?.cart_items.length !== 0 && (
        <BtnPurchaseProducts>
          <div className="purchase-produtcs">
            <h5>TOTAL: </h5>
            <p>
              {" "}
              S/{" "}
              {(
                listCart?.cart_items_json?.final_price_with_card +
                (costDelivery ? 15 : 0)
              ).toFixed(2)}{" "}
            </p>
          </div>
          {/* <div
            className="purchase-produtcs yape-button"
            onClick={handleModalYape}
          >
            <h5>Yapear </h5>
            <p>
              {" "}
              S/{" "}
              {(
                listCart?.cart_items_json?.final_price_with_card +
                (costDelivery ? 15 : 0)
              ).toFixed(2)}{" "}
            </p>
          </div> */}
          {/* <div
            className="purchase-produtcs-orders"
            onClick={handlePaymentClick}
          >
            <h5>PAGA AQUÍ </h5>
          </div> */}
          <CulqiBtnConfig
            costDelivery={costDelivery}
            userState={userState}
            listCart={listCart}
          />
        </BtnPurchaseProducts>
      )}
      {/* {showModal && (
        <Overlay className="card-modal">
          <ModalContent>
            <CulqiPaymentForm
              handleModalToggle={handleModalToggle}
              costDelivery={costDelivery}
              userState={userState}
            />
            <CulqiScript />
          </ModalContent>
        </Overlay>
      )} */}
      {/* {showModalYape && (
        <YapeModal
          handleModalToggle={handleModalYape}
          costDelivery={costDelivery}
          userState={userState}
        />
      )} */}
    </PurchaseDetail>
  );
};
