import styled from "styled-components";

export const CarrouselContainer = styled.div`
  margin-top: 215px;
  width: auto;
  @media screen and (max-width: 600px) {
    margin-top: 160px;
  }
  div {
    /* &.mySwiper {
      padding-top: 51em;
    } */
  }
`;

export const SliderContainer = styled.div`
  img {
    width: 100%;
    object-fit: cover;
    height: 550px;
  }
  @media screen and (max-width: 800px) {
    img {
      object-fit: contain;
      height: 275px;
    }
  }
  @media screen and (max-width: 500px) {
    img {
      object-fit: cover;
      height: 140px;
    }
  }
`;

export const DataSlider = styled.div`
  h1 {
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1%.5em;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1.25em;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 0.7em;
    text-align: center;
    line-height: 1;
  }
`;
export const ImageSlider = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 1000px) {
    padding-top: 2em;
    img {
      width: 400px;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 300px;
      object-fit: cover;
    }
  }
`;

export const Btn = styled.button`
  margin-top: 2em;
  background-color: #00cde8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
`;
