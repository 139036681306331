import * as Style from "../styles/FooterStyles";
import { Link } from "react-router-dom";
import Logo from "../assets/LogoFooter.png";
import Book from "../assets/Vector.png";
import FaceLogo from "../assets/FaceLogo.svg";
import InstaLogo from "../assets/InstaLogo.svg";
import TikTokLogo from "../assets/TikTokLogo.svg";

export const Footer = () => {
  return (
    <Style.FooterContainer>
      <Style.Footer>
        <div>
          <Link to="/">
            <Style.Img src={Logo} alt="logo-wonderland" />
          </Link>
        </div>
        <Style.FooterSection>
          <h4>Nuestras Categorias</h4>
          <br />
          <Link to={"/category/pokemon"}>
            <p>Pokémon</p>
          </Link>
          <Link to={"/category/yugioh"}>
            <p>Yu-Gi-Oh!</p>
          </Link>
          <Link to={"/category/juegos-de-mesa"}>
            <p>Juegos de mesa</p>
          </Link>
          <Link to={"/category/accesorios"}>
            <p>Accesorios</p>
          </Link>
        </Style.FooterSection>
        <Style.FooterSection>
          <h4>Páginas de Interés</h4>
          <br />
          <Link to={"/terms-and-conditions"}>
            <p>Términos y Condiciones</p>
          </Link>
          <Link to={"/privacy-policy"}>
            <p>Políticas de Privacidad</p>
          </Link>
          <Link to={"/about-us"}>
            <p>Sobre Nosotros</p>
          </Link>
          <Link to={"/faq"}>
            <p>Preguntas Frecuentes</p>
          </Link>
          <Link to={"/contact"}>
            <p>Contacto</p>
          </Link>
        </Style.FooterSection>
        <Style.GroupLogoMobile>
          <img src={FaceLogo} alt="Facebook" width="20" height="20" />
          <img src={InstaLogo} alt="Facebook" width="20" height="20" />
          <img src={TikTokLogo} alt="Facebook" width="20" height="20" />
        </Style.GroupLogoMobile>
        <Style.FooterSectionImg>
          <Style.ImgBook src={Book} alt="Libro de reclamaciones" />
          <h3>Libro de</h3>
          <h3>Reclamaciones</h3>
        </Style.FooterSectionImg>
        <Style.FooterSection>
          <h3>Nuestras Redes</h3>
          <Style.GroupLogo>
            <a
              href="https://www.facebook.com/wonderlandgamesperu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FaceLogo} alt="Facebook" width="15" height="15" />
            </a>
            <a
              href="https://www.instagram.com/wonderlandgamesperu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={InstaLogo} alt="Facebook" width="15" height="15" />
            </a>
            <a
              href="https://www.tiktok.com/@wonderlandgamesperu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TikTokLogo} alt="Facebook" width="15" height="15" />
            </a>
          </Style.GroupLogo>
        </Style.FooterSection>
      </Style.Footer>
      <Style.FooterEnd>
        <h2>www.wonderland-store.com | Todos los derechos</h2>
      </Style.FooterEnd>
    </Style.FooterContainer>
  );
};
