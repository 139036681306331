import styled from "styled-components";

export const ContainerView = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #ffffff;
  margin: 4em;
  margin-bottom: 2em;
  gap: 2em;
  @media screen and (max-width: 950px) {
    margin: 4em 0em;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
`;
