import React from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

const StockSelectContainer = styled.div`
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  select {
    appearance: none;
    border: 1px solid #ced4da;
    background-color: transparent;
    border: none;
    font-size: inherit;
    border-radius: 4px;
    padding: 0.5em 2.2em;
    padding-left: 1.5em;
    width: 100px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    &:focus {
      border: 1px solid #00cde8;
      outline: none;
    }
    option {
      border: 1px solid #00cde8;
    }
  }
  select:hover {
    border-color: #999999;
  }
`;

const CustomIcon = styled(IoIosArrowDown)`
  position: absolute;
  right: 8px;
  z-index: -1;
  color: #555555 !important;
`;

export const StockSelectCart = ({ stock, onChange, value }) => {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    onChange(newValue);
  };

  return (
    <StockSelectContainer>
      <select value={value} onChange={handleChange}>
        {[...Array(stock)].map((_, index) => (
          <option key={index + 1} value={index + 1}>
            {index + 1}
          </option>
        ))}
      </select>
      <CustomIcon />
    </StockSelectContainer>
  );
};
