import { types } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case types.LIST_PRODUCT:
      return {
        ...state,
        productList: action.payload.data.results,
        loading: action.payload.status === 200 ? false : true,
        count: action.payload.data.count,
        categoryFilter: "pokemon",
        kindFilter: null,
        subkindFilter: null,
        // totalPage: action.payload.data.totalPages,
      };
    case types.ERROR_LIST_PRODUCT:
      return {
        ...state,
        productList: [],
        errorProduct: true,
        count: 0,
        totalPage: 1,
        categoryFilter: "pokemon",
        kindFilter: null,
        subkindFilter: null,
      };

    case types.CATEGORY_FILTER_HEADER:
      return {
        ...state,
        categoryFilter: action.payload,
      };

    case types.KIND_FILTER:
      return {
        ...state,
        kindFilter: action.payload,
      };

    case types.SUB_KIND_FILTER:
      return {
        ...state,
        subkindFilter: action.payload,
      };

    case types.SEARCH_VALUE_PRODUCT:
      return {
        ...state,
        categoryFilter: action.payload.category,
        kindFilter: action.payload.kind,
      };

    case types.LIST_CATEGORY_FILTER:
      return {
        ...state,
        categoryList: action.payload.data,
        loading: action.payload.status === 200 ? false : true,
      };
    case types.ERROR_LIST_CATEGORY_FILTER:
      return {
        ...state,
        categoryList: [],
        errorCategory: true,
      };
    case types.LIST_PRODUCT_CAROUSEL:
      return {
        ...state,
        productCaroucel: action.payload.data,
        loadingCaroucel: action.payload.status === 200 ? false : true,
      };
    case types.EVENTS_LIST_SUCCESS:
      return {
        ...state,
        eventList: action.payload.data,
      };
    case types.EVENTS_LIST_ERROR:
      return {
        ...state,
        eventList: [],
      };
    case types.PRODUCT_FEATURED_LIST_SUCCESS:
      return {
        ...state,
        productFeatured: action.payload.data?.results,
      };
    case types.PRODUCT_FEATURED_ERROR:
      return {
        ...state,
        productFeatured: [],
      };
    case types.ERROR_LIST_PRODUCT_CAROUSEL:
      return {
        ...state,
        productCaroucel: [],
        errorProductCaroucel: true,
      };
    case types.LIST_PRODUCT_FILTER:
      return {
        ...state,
        productList: action.payload.data,
        loading: action.payload.loading,
        count: action.payload.count,
        categoryFilter:
          action.payload.params.category !== ""
            ? action.payload.params.category
            : null,
        kindFilter:
          action.payload.params.kind !== "" ? action.payload.params.kind : null,
        subkindFilter:
          action.payload.params.subkind !== ""
            ? action.payload.params.subkind
            : null,
      };
    case types.LIST_PRODUCT_SEARCH:
      return {
        ...state,
        productList: action.payload.data,
        loading: action.payload.status === 200 ? false : true,
        count: action.payload.data.length,
        totalPage: 1,
      };

    case types.LIST_PRODUCT_FILTER_PRICE:
      return {
        ...state,
        productList: action.payload.data.docs,
        loading: action.payload.status === 200 ? false : true,
        count: action.payload.data.totalDocs,
        totalPage: action.payload.data.totalPages,
      };

    //CART USER
    case types.LISTAR_CART_USER:
      return {
        ...state,
        listCart: action.payload.data,
        loadingAddCart: action.payload.status === 200 ? false : true,
        countCart: action.payload.data?.cart_items_json?.quantity_cart,
        have_coupon: action.payload.data?.have_coupon,
      };
    case types.AGREGAR_CARRO:
      return {
        ...state,
        loadingAddCart: action.payload.status === 201 ? false : true,
        addCartStatus: action.payload.status === 201 ? true : false,
      };
    case types.ERROR_AGREGAR_CARRO:
      return {
        ...state,
        errorAddCart: action.payload.data.error,
        loadingAddCart: action.payload.status === 400 ? false : true,
        addCartStatus: false,
      };
    case types.DELETE_CART:
      return {
        ...state,
        loading: action.payload.status === 201 ? false : true,
      };
    //ORDER_PAYMENT
    case types.ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        orderStatus: action.payload.status === 200 ? true : false,
        orderCart: action.payload.data?.message,
      };
    case types.ORDER_PAYMENT_ERROR:
      return {
        ...state,
        orderStatus: false,
        orderCart: [],
        orderErrorMessage: "Error en la compra",
      };
    case types.RESET_ORDER_PAYMENT:
      return {
        ...state,
        orderStatus: false,
        addCartStatus: false,
        errorAddCart: "",
        orderErrorMessage: null,
      };

    case types.RESET_LIST_ORDER:
      return {
        ...state,
        orderCart: [],
        orderStatus: false,
        orderErrorMessage: null,
      };

    default:
      return state;
  }
};
