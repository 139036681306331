import React from "react";
import {
  ContainerAboutUs,
  ContanierAboutUsMain,
  MapContainer,
} from "../styles/GeneralStyles";
import Nosotros from "../assets/Nosotros.png";
import { MapStore } from "../components/MapStore";
import { useLoadScript } from "@react-google-maps/api";
import { HeaderFooter } from "./HeaderFooter";

export const AboutUs = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBXcPFcED3EwuMHNuwVjnFsIs8Ni8XHLEU",
  });
  return (
    <HeaderFooter searchModal={true}>
      <ContanierAboutUsMain>
        <ContainerAboutUs>
          <img src={Nosotros} alt="sobre nosotros" />
          <div>
            <h1>¡Bienvenido a Wonderland Playground!</h1>
            <br />
            <p>
              Somos una tienda apasionada por los videojuegos, Trading Card
              Games (TCG) y juegos de mesa. En Wonderland Playground, nos
              encanta llevar experiencias únicas a nuestros clientes, creando un
              espacio donde puedan sumergirse en emocionantes aventuras y
              conectar con otros entusiastas del mundo del entretenimiento.
            </p>
            <p>
              Desde el momento en que pusimos un pie en este universo, nos
              comprometimos a ofrecer no solo productos de alta calidad, sino
              también un ambiente acogedor y lleno de energía positiva. Nos
              esforzamos por ser mucho más que una simple tienda: somos un
              destino donde los jugadores pueden encontrar todo lo que necesitan
              para disfrutar al máximo de su pasión.
            </p>
            <p>
              Nuestro equipo está formado por amantes de los videojuegos,
              coleccionistas de TCG y fanáticos de los juegos de mesa. Estamos
              aquí para ayudarte a encontrar ese juego que has estado buscando,
              para compartir consejos y estrategias, o simplemente para charlar
              sobre tus juegos favoritos.
            </p>
            <p>
              En Wonderland Playground, nos comprometemos a ofrecerte una
              experiencia excepcional en cada visita. ¡Únete a nosotros y
              adéntrate en un mundo de diversión y aventura!
            </p>
            <br />
            <br />
            <h4>¡Nos vemos en la tienda!</h4>
          </div>
        </ContainerAboutUs>
        <MapContainer>
          <div>{isLoaded ? <MapStore /> : null}</div>
        </MapContainer>
      </ContanierAboutUsMain>
    </HeaderFooter>
  );
};
