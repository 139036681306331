import {
  BoxCartContainer,
  BoxDataCart,
  BtnAction,
  BtnLinkPurchase,
  CartModalAction,
  CerrarModalStyle,
  ContenedorModal,
  Overlay,
} from "../styles/ModalStyles";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { IoCart } from "react-icons/io5";
import { StockSelectProduct } from "./StockSelectProduct";
import { useContext, useState } from "react";
import { ProductContext } from "../context/product/ProductProvider";
import { Link } from "react-router-dom";
import { clienteAxios } from "../config/axios";

export const ModalAddCart = ({ modal, setModal, product }) => {
  const { addCartStatus, errorAddCart, resetOrder, getListCartUser } =
    useContext(ProductContext);

  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const CerrarModal = () => {
    resetOrder();
    setModal(false);
  };

  const [changeValue, setChangeValue] = useState(false);
  const [valueCartItem, setValueCartItem] = useState(null);

  const handleSelectChange = (newValue) => {
    setValueCartItem(newValue);
    setChangeValue(true);
  };

  const updateItemCart = async () => {
    // updateCartItem({
    //   combination_id: product.combination_data.id,
    //   quantity: valueCartItem,
    // });
    setIsUpdating(true);
    try {
      const respuesta = await clienteAxios.post("cart/cartitemupgrade/", {
        combination_id: product.combination_data.id,
        quantity: valueCartItem,
      });

      setUpdateSuccess(false);
      if (respuesta.status === 200) {
        getListCartUser();
        setUpdateSuccess(true);
        setTimeout(() => {
          setUpdateSuccess(null);
        }, 5000);
        return;
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      {modal && (
        <Overlay>
          <ContenedorModal>
            <CerrarModalStyle onClick={CerrarModal}>
              <IoIosCloseCircleOutline color="#818181" size={40} />
            </CerrarModalStyle>
            <BoxCartContainer>
              <img src={product?.image_principal} alt={product?.title} />
              <BoxDataCart>
                {addCartStatus ? (
                  <h4>Producto agregado al carrito</h4>
                ) : (
                  <h4> {errorAddCart} </h4>
                )}

                <h2>{product?.title}</h2>
                {addCartStatus && (
                  <CartModalAction>
                    <h6>Cantidad: </h6>
                    <StockSelectProduct
                      value={product.combination_data?.quantity}
                      onChange={handleSelectChange}
                    />
                    <button
                      onClick={updateItemCart}
                      disabled={!changeValue}
                      // className="update-cart"
                      className={
                        isUpdating ? "update-detail-process" : "update-cart"
                      }
                    >
                      {isUpdating ? "Actualizando..." : "Actualizar"}
                    </button>
                  </CartModalAction>
                )}
                <BtnAction className="btn-eye" onClick={CerrarModal}>
                  <IoEye color="#ffffff" size={20} />
                  <p>Seguir comprando</p>
                </BtnAction>
                {/* <BtnAction className="btn-cart">
                  <IoCart color="#ffffff" size={20} />
                  <p>Terminar compra</p>
                </BtnAction> */}
                <BtnLinkPurchase>
                  <Link to={"/purchase"}>
                    <IoCart size={20} /> Terminar compra
                  </Link>
                </BtnLinkPurchase>
                {updateSuccess && (
                  <BtnAction className="btn-process-update">
                    <span>Se actualizo correctamente</span>
                  </BtnAction>
                )}
              </BoxDataCart>
            </BoxCartContainer>
          </ContenedorModal>
        </Overlay>
      )}
    </>
  );
};
