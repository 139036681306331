import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Error404 } from "./components/Error404";
import { Ecommerce } from "./view/Ecommerce";
import { Login } from "./view/Login";
import { Register } from "./view/Register";
import { TermsAndConditions } from "./view/TermsAndConditions";
import { PrivacyPolicy } from "./view/PrivacyPolicy";
import { AboutUs } from "./view/AboutUs";
import { DetailProduct } from "./view/DetailProduct";
import { CategoryList } from "./view/CategoryList";
import { MyUser } from "./view/MyUser";
import { MyDataUser } from "./components/MyDataUser";
import { MyAddress } from "./components/MyAddress";
import { FAQ } from "./view/FAQ";
import { ContactView } from "./view/ContactView";
import { MyOrders } from "./components/MyOrders";
import { ProductContext } from "./context/product/ProductProvider";
import { useContext, useEffect } from "react";
import { CategoryKindList } from "./view/CategoryKindList";
import { FinalizePurchase } from "./view/FinalizePurchase";
import { ShippingMethodPurchase } from "./view/ShippingMethodPurchase";
import { OrdersThanks } from "./view/OrdersThanks";
import { MyOrdersItem } from "./components/MyOrdersItem";
import { CategoryKindSubKindList } from "./view/CategoryKindSubKindList";
import { NewPassword } from "./components/NewPassword";

export const RoutesApp = ({ autenticado, user_anonymus, exitCession }) => {
  const {
    listProductFilter,
    getCategoryFilter,
    checkOrderCart,
    categoryFilter,
    kindFilter,
    subkindFilter,
    getProductCarrousel,
    getEventList,
    getProductFeaturedList,
  } = useContext(ProductContext);

  // const tokenValid = localStorage.getItem("token-wonderland-store");
  // const tokenAnonymus = localStorage.getItem("tk-wonderland-store-anonymus");
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const regexKind = /^\/category\/([^/]+)\/kind\/([^/]+)$/;
    const regexSubKind =
      /^\/category\/([^/]+)\/kind\/([^/]+)\/subkind\/([^/]+)$/;

    const token = localStorage.getItem("token-wonderland-store");
    const anonymusToken = localStorage.getItem("tk-wonderland-store-anonymus");

    if (token || anonymusToken) {
      // Si al menos uno de los tokens está presente, ejecuta las funciones
      getProductCarrousel();
      getEventList();
      getProductFeaturedList();
      if (!pathname.match(regexKind) && !pathname.match(regexSubKind)) {
        listProductFilter({
          page_size: 16,
          page: 1,
          category: categoryFilter ? categoryFilter : "pokemon",
          kind: kindFilter ? kindFilter : "",
          subkind: subkindFilter ? subkindFilter : "",
        });
      }

      getCategoryFilter();
      checkOrderCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autenticado, user_anonymus, exitCession]);

  return (
    <>
      <Routes>
        {!autenticado && (
          <>
            <Route path="/" element={<Ecommerce />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<ContactView />} />
            <Route path="/product/:slug" element={<DetailProduct />} />
            <Route path="/category/:slug" element={<CategoryList />} />
            <Route
              path="/category/:slug/kind/:slugKind"
              element={<CategoryKindList />}
            />
            <Route
              path="/category/:slug/kind/:slugKind/subkind/:slugsubKind"
              element={<CategoryKindSubKindList />}
            />
            <Route path="/purchase" element={<FinalizePurchase />} />
            {/* <Route
              path="/shipping-method"
              element={<ShippingMethodPurchase />}
            /> */}
          </>
        )}
        {autenticado && (
          <>
            <Route path="/" element={<Ecommerce />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<ContactView />} />
            <Route path="/product/:slug" element={<DetailProduct />} />
            <Route path="/category/:slug" element={<CategoryList />} />
            <Route
              path="/category/:slug/kind/:slugKind"
              element={<CategoryKindList />}
            />
            <Route
              path="/category/:slug/kind/:slugKind/subkind/:slugsubKind"
              element={<CategoryKindSubKindList />}
            />
            <Route path="/my-user" element={<MyUser />}>
              <Route index element={<MyDataUser />} />
              <Route path="orders" element={<MyOrders />} />
              <Route path="change-password" element={<NewPassword />} />
              <Route path="address" element={<MyAddress />} />
              <Route path="orders/:id" element={<MyOrdersItem />} />
            </Route>
            <Route path="/purchase" element={<FinalizePurchase />} />
            <Route
              path="/shipping-method"
              element={<ShippingMethodPurchase />}
            />
            <Route path="/order-success" element={<OrdersThanks />} />
          </>
        )}
        <Route path="404" element={<Error404 to={"/404"} />} />
        <Route path="*" element={<Navigate to={"/404"} />} />
      </Routes>
    </>
  );
};
