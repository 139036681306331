import React, { useState } from "react";
import * as Style from "../styles/OffersAndNewsStyles";
import Enviar from "../assets/IconEnviar.svg";

export const OffersAndNews = () => {
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const handleKeyDown = (event) => {
    console.log(event.keyCode);
  };

  return (
    <Style.OffersAndNewsContainer id="sectionToNavigate">
      <Style.BoxMain>
        <h5>¡Déjanos tu correo para recibir nuestras ofertas y novedades!</h5>
        <Style.BoxSearch>
          <Style.SearchInput
            name="text"
            type="text"
            placeholder="Déjanos el correo que más usas :)"
            onChange={(e) => handleSearch(e)}
            value={search}
            onKeyDown={handleKeyDown}
          />
          <button>
            <img src={Enviar} alt="" />
          </button>
        </Style.BoxSearch>
      </Style.BoxMain>
    </Style.OffersAndNewsContainer>
  );
};
