import styled from "styled-components";

export const CarrouselFeaturedProducts = styled.div`
  background-color: #f4f4f4;
  width: 70%;
  height: auto;
  padding: 2em;
  h2 {
    padding-left: 2em;
    margin-bottom: 1em;
    color: #5352b8;
    font-weight: 600;
    font-size: 1.5em;
  }
  div {
    div {
      &.swiper-slide {
        /* background-color: #5352b8 !important; */
        padding: 2em 0;
      }
    }
  }
  a {
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 950px) {
    padding: 2em 0.9em;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;

export const SlideData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  p {
    margin-top: 0.5em;
    font-weight: 700;
    font-size: 0.9em;
    color: #000000;
    white-space: pre-wrap;
  }
  button {
    background-color: #f2f2f2;
    color: #000000;
    padding: 5px 20px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1em;
    font-weight: 700;
  }
  img {
    margin-top: 1em;
    height: 113px;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  background-color: #fff;
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: 1px solid #ebebeb;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1rem; */
  position: relative;
  min-height: 285px;
  min-width: 270px;
  a {
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const BoxCartPricce = styled.div`
  display: flex;
  gap: 1em;
`;

export const BtnCart = styled.button`
  /* background-color: #00cde8 !important; */
  background-color: ${({ $disabled }) =>
    $disabled ? "#d4d4d4 !important" : "#00cde8 !important"};

  border: 2px solid #ffffff !important;
  box-shadow: 0px 1px 4px 0px #00000040;
  display: flex;
  align-items: center;
  p {
    color: #ffffff;
    padding: 0;
    margin: auto 0;
    text-align: center;
  }
`;
