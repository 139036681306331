import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { UseScrollToTopOnMount } from "../hooks/UseScrollToTopOnMount";

export const HeaderFooter = ({
  children,
  disabledOnClick = false,
  searchModal = false,
}) => {
  UseScrollToTopOnMount();

  return (
    <>
      <Header disabledOnClick={disabledOnClick} searchModal={searchModal} />
      {children}
      <Footer />
    </>
  );
};
