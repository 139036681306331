import React, { useContext, useEffect, useRef } from "react";
import {
  BoxCart,
  CartAction,
  CartData,
  CartItem,
  CartSidebarContainer,
  CerrarModalStyle,
  Checkout,
  HR,
  NoItemsCart,
} from "../styles/CartSidebarStyles";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { IoCart } from "react-icons/io5";
import { ProductContext } from "../context/product/ProductProvider";
import { SidebarStyle } from "../styles/HeaderStyles";
import { NoData } from "./NoData";
import { Link } from "react-router-dom";
import { StockSelectCart } from "./StockSelectCard";

const CartProduct = ({ item, deleteCartItem, updateCartItem }) => {
  const handleDeleteItem = (id) => {
    deleteCartItem({
      cart_item_id: id,
    });
  };

  const handleSelectChange = (newValue) => {
    updateCartItem({
      combination_id: item.combination_id,
      quantity: newValue,
    });
  };

  return (
    <>
      <HR />
      <CartItem>
        <img
          src={`http://back.wonderland-store.com/media/${item.image}`}
          alt={item.item}
        />
        <CartData>
          <h5>{item.item}</h5>
          <div>
            <p>S/{item?.original_price} </p>
            <CartAction>
              {/* <StockSelectProduct
                value={item.quantity}
                stockProduct={item.quantity}
                key={item.id}
                onChange={handleSelectChange}
                isCart={true}
              /> */}
              <StockSelectCart
                stock={item.combination_quantity}
                onChange={handleSelectChange}
                value={item.quantity}
              />
              <MdDeleteForever
                color="#00CDE8"
                size={25}
                onClick={() => handleDeleteItem(item.id)}
              />
              <div></div>
            </CartAction>
          </div>
        </CartData>
      </CartItem>
    </>
  );
};

export const CartSidebar = ({ setCartSidebar, cartSidebar }) => {
  const { listCart, deleteCartItem, updateCartItem } =
    useContext(ProductContext);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Si se hace clic fuera del sidebar, cerrarlo
        setCartSidebar(false);
      }
    };

    // Agregar event listener cuando se monta el componente
    document.addEventListener("mousedown", handleOutsideClick);

    // Limpiar el event listener cuando se desmonta el componente
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setCartSidebar]);

  return (
    <SidebarStyle ref={sidebarRef} $activar={cartSidebar}>
      <CartSidebarContainer>
        <CerrarModalStyle onClick={() => setCartSidebar(false)}>
          <IoIosCloseCircleOutline color="#818181" size={30} />
        </CerrarModalStyle>
        <p>Carrito de compra:</p>
        <BoxCart>
          {listCart?.cart_items_json?.cart_items.length === 0 ? (
            <NoItemsCart>
              <NoData text1="No hay productos" text2="" />
            </NoItemsCart>
          ) : (
            listCart?.cart_items_json?.cart_items.map((product) => (
              <CartProduct
                key={product.id}
                item={product}
                deleteCartItem={deleteCartItem}
                updateCartItem={updateCartItem}
                cartUpdateTrigger={listCart}
              />
            ))
          )}
        </BoxCart>
        {listCart?.cart_items_json?.cart_items.length !== 0 && (
          <>
            <HR />
            <Checkout>
              <Link to={"/purchase"}>
                <IoCart size={20} /> Terminar compra
              </Link>
            </Checkout>
          </>
        )}
      </CartSidebarContainer>
    </SidebarStyle>
  );
};
