/* eslint-disable react/prop-types */
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ProductContext } from "../context/product/ProductProvider";
import { useContext, useState } from "react";
import {
  BoxCartPricce,
  BtnCart,
  SlideData,
} from "../styles/FeaturedProductsStyles";
import { IoCart } from "react-icons/io5";
import { StockStatus } from "./StockStatus";
import { ModalAddCart } from "./ModalAddCart";
import { BsFillCartXFill } from "react-icons/bs";

const Contenedor = styled(motion.div)`
  margin: 0;
`;
const CardContainer = styled.div`
  width: 100%;
  background-color: #fff;
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: 1px solid #ebebeb;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1rem; */
  position: relative;
  min-height: 285px;
  min-width: 270px;
  a {
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
`;
// eslint-disable-next-line react/prop-types
const Product = ({ product }) => {
  const { addCartItem } = useContext(ProductContext);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState({});

  const handleAddCartUser = (product) => {
    setItem(product);
    addCartItem({
      combination: product.combination_data.id,
      quantity: 1,
    });
    setModal(true);
  };

  return (
    <div>
      <ModalAddCart modal={modal} setModal={setModal} product={item} />
      <Contenedor
        layout
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0 }}
        exit={{ opacity: 0, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CardContainer>
          <Link to={`/product/${product.slug}`}>
            <SlideData>
              <img src={product.image_principal} alt={product.title} />
              <p>{product.title}</p>
              <BoxCartPricce>
                <button>S/{product.combination_data?.price}</button>
                <BtnCart
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddCartUser(product);
                  }}
                  disabled={product.combination_data?.quantity === 0}
                  $disabled={product.combination_data?.quantity === 0}
                >
                  {product.combination_data?.quantity === 0 ? (
                    <BsFillCartXFill size={30} color="#ffffff" />
                  ) : (
                    <IoCart size={30} color="#ffffff" />
                  )}
                  <p>Añadir</p>
                </BtnCart>
              </BoxCartPricce>
              <StockStatus
                quantity={product.combination_data?.quantity}
                listClass={true}
              />
            </SlideData>
          </Link>
        </CardContainer>
      </Contenedor>
    </div>
  );
};

export default Product;
