import React, { useContext } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { ProductContext } from "../context/product/ProductProvider";
import { NoData } from "./NoData";
import {
  Box,
  BoxContainer,
  BoxFilterProduct,
  Contenedor,
  NoDataBox,
  PaginacionBox,
  PaginacionContainer,
} from "../styles/ProductListStyles";
import { AnimatePresence } from "framer-motion";
import ReactPaginate from "react-paginate";
import Product from "./Product";
import { ExpanseFilter } from "./ExpanseFilter";
import { useWindowSize } from "../hooks/UseWindowSize";
// import { SelectStatusProduct } from "./SelectStatusProduct";

export const ProductList = ({ onAddToCart, listMain = true }) => {
  const {
    productList,
    listProductFilter,
    count,
    categoryFilter,
    kindFilter,
    subkindFilter,
  } = useContext(ProductContext);
  const { width } = useWindowSize();

  const handlePageClick = async (data) => {
    let pagina = data.selected + 1;
    await listProductFilter({
      page: pagina,
      category: categoryFilter ? categoryFilter : "",
      kind: kindFilter ? kindFilter : "",
      subkind: subkindFilter ? subkindFilter : "",
    });
    if (width >= 1000) {
      const listProductElement = document.getElementById("sectionToNavigate");
      if (listProductElement) {
        listProductElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
    // else {
    //   const listProductElement = document.getElementById("#list-product");
    //   if (listProductElement) {
    //     listProductElement.scrollIntoView({
    //       behavior: "smooth",
    //     });
    //   }
    // }
  };

  const totalDePaginas = Math.ceil(count / 16);

  return (
    <Contenedor id="#list-product">
      <BoxFilterProduct>
        <ExpanseFilter />
      </BoxFilterProduct>
      <BoxContainer>
        {/* <SelectStatusProduct /> */}
        {count === 0 ? (
          <NoDataBox>
            <NoData listProduct={true} />
          </NoDataBox>
        ) : (
          <Box layout>
            <AnimatePresence>
              {productList.map((product) => {
                return (
                  <Product
                    key={product.id}
                    product={product}
                    onAddToCart={onAddToCart}
                    listMain={listMain}
                  />
                );
              })}
            </AnimatePresence>
          </Box>
        )}
        {count !== 0 && (
          <PaginacionContainer>
            <PaginacionBox>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                pageCount={totalDePaginas}
                marginPagesDisplayed={3}
                // pageRangeDisplayed={6}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousClassName={"anterior"}
                nextClassName={"siguiente"}
                activeLinkClassName={"activePaginacionFondo"}
                activeClassName={"activePaginacionFondo"}
              />
            </PaginacionBox>
          </PaginacionContainer>
        )}
      </BoxContainer>
    </Contenedor>
  );
};
