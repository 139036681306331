import React from "react";
import { CategorieBox } from "../styles/CategoriesStyles";
import { Link } from "react-router-dom";

export const CategorieItem = ({ item, slugCategory }) => {
  return (
    <CategorieBox>
      <div>
        <img src={item.image_principal} alt={item.name} />
        <Link to={`/category/${slugCategory}/kind/${item.slug}`}>
          <h5>Ver expansión</h5>
        </Link>
      </div>
      <div className="subkins">
        {item.subkinds.map((subkind, index) => {
          return (
            <React.Fragment key={subkind.id}>
              <Link
                to={`/category/${slugCategory}/kind/${item.slug}/subkind/${subkind.slug}`}
              >
                <h6>{subkind.name}</h6>
              </Link>{" "}
              {/* Colocar "|" solo si no es el último elemento */}
              {index < item.subkinds.length - 1 && <span> | </span>}
            </React.Fragment>
          );
        })}
      </div>
    </CategorieBox>
  );
};
