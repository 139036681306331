import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
// import { FaMapMarkerAlt } from "react-icons/fa";

export const MapStore = ({ widthMap = "100%", heightMap = "500px" }) => {
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Agregar un nuevo marcador después de 1 segundo
      setMarkers([
        {
          id: 1,
          name: "Wonderland",
          position: { lat: -12.126394089303895, lng: -76.99970865615994 },
        },
      ]);
    }, 1000);

    // Limpiar el temporizador al desmontar el componente
    return () => clearTimeout(timer);
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={{ width: widthMap, height: heightMap }}
      center={{ lat: -12.126394089303895, lng: -76.99970865615994 }}
      zoom={17}
    >
      {markers.map((marker) => (
        <Marker key={marker.id} position={marker.position}>
          {/* <InfoWindow position={marker.position}>
            <div
              style={{
                background: "transparent",
                boxShadow: "none",
                border: "none",
              }}
            >
              <FaMapMarkerAlt color="#e73d3d" size={24} />
            </div>
          </InfoWindow>
          <div
            style={{
              background: "transparent",
              boxShadow: "none",
              border: "none",
            }}
          >
            <FaMapMarkerAlt color="#e4e4e4" size={24} />
          </div> */}
        </Marker>
      ))}
    </GoogleMap>
  );
};
