import * as Style from "../styles/BannerTopFooterStyles";
import BannerT1 from "../assets/BannerT1.png";
import BannerT2 from "../assets/BannerT2.png";

export const BannerTopFooter = () => {
  return (
    <Style.BannerTopFooterContainer>
      <Style.BannerBox>
        <Style.ContainerData>
          <Style.ImageData>
            <h3>¡TE SACAMOS DE MISIO!</h3>
            <p>Compramos cartas sueltas</p>
            {/* <button>
              <h4>Ver más</h4>
            </button> */}
          </Style.ImageData>
          <img src={BannerT2} alt="BannerT1" />
        </Style.ContainerData>
        <Style.ContainerData>
          <Style.ImageData>
            <h3>¡JUEGA AL TOQUE!</h3>
            <p>Armamos tu deck </p>
            {/* <button>
              <h4>Ver más</h4>
            </button> */}
          </Style.ImageData>
          <img src={BannerT1} alt="BannerT2" />
        </Style.ContainerData>
      </Style.BannerBox>
    </Style.BannerTopFooterContainer>
  );
};
