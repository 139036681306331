import GlobalStyles from './styles/GlobalStyles';
import { useContext, useEffect } from 'react';
import { AuthContext } from './context/auth/AuthProvider';
import { RoutesApp } from './RoutesApp';
import { Loading } from './components/Loading';

function App() {

  const { cargando,  usuarioAutenticado, autenticado ,usuarioAnonymus, user_anonymus , exitCession , token_anonymus  } =
    useContext(AuthContext);
  useEffect(() => {
    usuarioAnonymus();
    usuarioAutenticado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyles />
      {cargando ? <Loading /> : <RoutesApp autenticado={autenticado} user_anonymus={user_anonymus} exitCession={exitCession} token_anonymus={token_anonymus} /> }
    </>
  );
}

export default App;
