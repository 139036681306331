import React, { Fragment, useContext, useState } from "react";
import { ProductContext } from "../context/product/ProductProvider";
import { IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  BoxSearchMobile,
  SearchIcon,
  SearchInput,
} from "../styles/HeaderStyles";
import { CategorieSelectHeader } from "./CategoriSelectHeader";
import { SearchDropDown } from "./SearchDropDown";

const Container = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: initial;
    position: absolute;
    background-color: #5352b8;
    gap: 2em;
    width: 100%;
    left: 0;
    margin-top: 1.2em;
    padding-bottom: 1em;
  }
`;

const CategoryContainer = styled.div`
  padding-left: 2em;
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  color: ${({ $isSelected }) => ($isSelected ? "#00CDE8" : "#ffffff")};
  padding: 0.5em 0.5em;
  font-weight: 700;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
`;

const KindContainer = styled.div`
  border-radius: 5px;
  padding: 0 0;
`;

const KindItem = styled.div`
  cursor: pointer;
  border-radius: 5px;
  /* padding: 0.5em 4em; */
  /* background-color: ${({ $isSelected }) =>
    $isSelected ? "#cacaca" : "#ffffff"}; */
  &:hover {
    background-color: #00cde8;
  }
  a {
    padding: 0.5em 4em;
    display: block;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    border-radius: 5px;
    font-weight: bolder;
  }
`;

const BtnLinkCategory = styled.div`
  margin-top: 0.5em;
  margin-bottom: 1em;
  margin-left: 2em;
  a {
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    background-color: #262525;
    font-weight: bolder;
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
  }
`;

export const NavMenuHamburguer = ({
  handleSearchApi,
  showDropSearch,
  loadingSearch,
  handleRedirectAndScroll,
  searchMobile,
  handleSearch,
  handleRedirectProductSearch,
}) => {
  const { categoryList } = useContext(ProductContext);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const toggleCategory = (categoryId) => {
    setExpandedCategory(categoryId === expandedCategory ? null : categoryId);
    setSelectedItemId(categoryId);
  };

  const NoExpandeCategory = () => {
    setExpandedCategory(null);
    setSelectedItemId(null);
  };

  return (
    <Container>
      {categoryList.map((category) => (
        <CategoryContainer key={category.id}>
          <CategoryHeader
            onClick={() => toggleCategory(category.slug)}
            $isSelected={selectedItemId === category.slug}
          >
            <span>{category.name}</span>
            {selectedItemId === category.slug ? (
              <IoIosArrowForward color="#00CDE8" />
            ) : (
              <IoIosArrowForward color="#ffffff" />
            )}
          </CategoryHeader>
          {expandedCategory === category.slug && category.kinds.length > 0 && (
            <KindContainer>
              {category.kinds.slice(0, 4).map((kind) => (
                <Fragment key={kind.slug}>
                  <KindItem>
                    <Link to={`/category/${selectedItemId}/kind/${kind.slug}`}>
                      {kind.name}
                    </Link>
                  </KindItem>
                </Fragment>
              ))}
              {category.kinds.length > 4 && (
                <BtnLinkCategory>
                  <Link to={`/category/${selectedItemId}`}>
                    Ver más expansiones
                  </Link>
                </BtnLinkCategory>
              )}
            </KindContainer>
          )}
        </CategoryContainer>
      ))}
      <br />
      <BoxSearchMobile>
        <CategorieSelectHeader />
        <SearchInput
          name="text"
          type="text"
          placeholder=""
          onChange={(e) => handleSearch(e)}
          value={searchMobile || ""}
          onKeyDown={handleSearch}
          onClick={NoExpandeCategory}
          // onKeyUp={(e) => {
          //   if (e.key === "Backspace" && !search) {
          //     clearSearch();
          //   }
          //  }}
        />
        <SearchIcon onClick={() => handleSearchApi(searchMobile)} />
        {showDropSearch && searchMobile.length > 2 && (
          <SearchDropDown
            loadingSearch={loadingSearch}
            handleRedirectAndScroll={handleRedirectAndScroll}
            handleRedirectProductSearch={handleRedirectProductSearch}
          />
        )}
      </BoxSearchMobile>
    </Container>
  );
};
