import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderFooter } from "./HeaderFooter";
// import { clienteAxios } from "../config/axios";
import { Loading } from "../components/Loading";
import {
  ContainerCategory,
  ContainerCategoryKind,
  RedirectCategory,
} from "../styles/CategoriesStyles";
import BGCategory from "../assets/BG-category.png";
import { FaArrowLeft } from "react-icons/fa";
import { ProductContext } from "../context/product/ProductProvider";
import { ExpanseFilter } from "../components/ExpanseFilter";
import { NoData } from "../components/NoData";
import {
  Box,
  BoxContainer,
  BoxFilterProduct,
  NoDataBox,
  PaginacionBox,
  PaginacionContainer,
} from "../styles/ProductListStyles";
import { AnimatePresence } from "framer-motion";
import Product from "../components/Product";
import ReactPaginate from "react-paginate";

export const CategoryKindList = () => {
  const navigate = useNavigate();
  const { slug, slugKind } = useParams();
  const {
    handleAddToCart,
    listProductFilter,
    subkindFilter,
    productList,
    count,
    changeCategory,
    changeKind,
    categoryFilter,
    kindFilter,
  } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  // const [items, setItems] = useState([]);
  // const [countCategory, setcountCategory] = useState([]);
  const goBack = () => {
    navigate(`/category/${slug}`); // Navegar hacia atrás en la historia
  };
  useEffect(() => {
    changeCategory({ category: slug });
    changeKind({ kind: slugKind });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      await listProductFilter({
        page_size: 16,
        page: 1,
        category: slug,
        kind: slugKind ? slugKind : "",
        subkind: subkindFilter ? subkindFilter : "",
      });
      // setItems(res);
      setLoading(true);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, slugKind]);

  const handlePageClick = async (data) => {
    let pagina = data.selected + 1;
    await listProductFilter({
      page_size: 16,
      page: pagina,
      category: categoryFilter,
      kind: kindFilter ? kindFilter : "",
      subkind: subkindFilter ? subkindFilter : "",
    });
    // setItems(res);
  };

  const totalDePaginas = Math.ceil(count / 16);

  return (
    <HeaderFooter>
      <ContainerCategory>
        <RedirectCategory>
          <img src={BGCategory} alt="bg-category" />
          <div>
            <FaArrowLeft onClick={goBack} color="#ffffff" size={20} />
            <h4>Tienda/</h4>
            <h6>{slug} /</h6>
            <h6> {slugKind}</h6>
          </div>
        </RedirectCategory>
        <>
          {loading ? (
            <div>
              <ContainerCategoryKind>
                <BoxFilterProduct>
                  <ExpanseFilter />
                </BoxFilterProduct>
                <BoxContainer>
                  {count === 0 ? (
                    <NoDataBox>
                      <NoData />
                    </NoDataBox>
                  ) : (
                    <Box layout>
                      <AnimatePresence>
                        {productList.map((product) => {
                          return (
                            <Product
                              key={product.id}
                              product={product}
                              onAddToCart={handleAddToCart}
                              listMain={true}
                            />
                          );
                        })}
                      </AnimatePresence>
                    </Box>
                  )}
                  {count !== 0 && (
                    <PaginacionContainer>
                      <PaginacionBox>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          pageCount={totalDePaginas}
                          marginPagesDisplayed={3}
                          // pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          previousClassName={"anterior"}
                          nextClassName={"siguiente"}
                          activeLinkClassName={"activePaginacionFondo"}
                          activeClassName={"activePaginacionFondo"}
                        />
                      </PaginacionBox>
                    </PaginacionContainer>
                  )}
                </BoxContainer>
              </ContainerCategoryKind>
            </div>
          ) : (
            <Loading height="50vh" />
          )}
        </>
      </ContainerCategory>
    </HeaderFooter>
  );
};
