import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerPurchase = styled.div`
  @media screen and (min-width: 1600px) {
    padding: 10% 20%;
  }
  padding: 10% 10%;
  margin-top: 155px;
  @media screen and (max-width: 900px) {
    margin: 20% 2%;
    margin-top: 155px;
    padding-top: 20%;
  }
  min-height: 60vh;
  @media screen and (min-height: 1000px) {
    min-height: 70vh;
  }
`;

export const PurchaseBox = styled.div`
  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: 2fr 1fr;
  gap: 3em;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const PurchaseDetail = styled.div`
  h4 {
    margin-top: 2em;
  }

  .price-total {
    margin-top: 2em;
    padding: 0.8em 2em;
    border: 1px solid #d9d9d9;
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const BtnPurchaseProducts = styled.div`
  display: flex;
  gap: 2em;
  margin-top: 2em;
  color: #ffffff;
  @media (max-width: 850px) {
    flex-direction: column;
  }

  .purchase-produtcs {
    background-color: #2e2daa;
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em 2em;
    border-radius: 5px;
    @media (max-width: 850px) {
      width: 100%;
    }
    &.yape-button {
      background-color: #81308d;
    }
  }
  .purchase-produtcs-orders {
    text-align: center;
    cursor: pointer;
    padding: 0.8em 3em;
    border-radius: 5px;
    background-color: #4646eb;
    display: flex;
    align-items: center;
  }
`;

export const PurchaseTotal = styled.div`
  h4 {
    font-size: 0.9em;
    font-weight: 700;
  }
  .resumen {
    padding: 0.8em 2em;
    border: 1px solid #d9d9d9;
    width: 330px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  .price-total {
    padding: 0.8em 2em;
    border: 1px solid #d9d9d9;
    border-top: none;
    width: 330px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const ContentResumen = styled.div`
  width: 330px;
  border: 1px solid #d9d9d9;
  border-top: none;
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em 2em;
    .cupon-item {
      display: flex;
      gap: 1em;
      align-items: center;
    }
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const BtnCuponResumen = styled.button`
  background-color: #f2f2f2;
  cursor: pointer;
  border: none;
  padding: 0.5em;
  border-radius: 5px;
  &:hover {
    opacity: 0.5;
  }
`;

export const Checkout = styled.div`
  margin-top: 3em;
  width: 330px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

//Shipping Method
export const ContainerTab = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f1f1f1;
  margin: 50px auto;
  word-break: break-all;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 5px;

  width: 100%;
`;

export const TabContainer = styled.div`
  display: flex;
`;

export const TabButton = styled.button`
  border: none;
  border-top-right-radius: ${({ $isSelected }) =>
    $isSelected ? "5px" : "none"};
  border-top-left-radius: ${({ $isSelected }) =>
    $isSelected ? "5px" : "none"};
  padding: 15px;
  text-align: center;
  width: 50%;
  background: ${({ $isSelected }) => ($isSelected ? "white" : "white")};
  cursor: pointer;
  border-bottom: ${({ $isSelected }) =>
    $isSelected ? "none" : "1px solid #D9D9D9"};
  border-top: ${({ $isSelected }) =>
    $isSelected ? "1px solid #D9D9D9" : "none"};
  border-right: ${({ $isSelected }) =>
    $isSelected ? "1px solid #D9D9D9" : "none"};
  border-left: ${({ $isSelected }) =>
    $isSelected ? "1px solid #D9D9D9" : "none"};
  position: relative;
  outline: none;
  div {
    background-color: #00cde8;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 0.7em 0;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 700;
    &.store-buy {
      background-color: #5352b8;
    }
    @media screen and (max-width: 1100px) {
      gap: 0.4em;
    }
    @media screen and (max-width: 550px) {
      font-size: 0.7em;
      padding: 0.7em 0.2em;
      span {
        display: none;
      }
    }
  }
`;

export const TabContentContainer = styled.div`
  flex-grow: 1;
  text-align: left;
`;

export const TabContent = styled.div`
  background: #ffffff;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  p {
    font-size: 0.6em;
    color: #878787;

    &.term {
      margin-top: 2em;
    }
  }
`;

export const CheckItem = styled.div`
  display: flex;
  gap: 3em;
  div {
    display: flex;
    align-items: center;
    gap: 1em;
    label {
      font-size: 0.7em;
      a {
        text-decoration: none;
        color: #878787;
      }
    }
  }
  input[type="checkbox"] {
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #2e2daa;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }
  input[type="checkbox"]:checked {
    background-color: #2e2daa;
  }
`;

export const ShippingPrice = styled.div`
  display: flex;
  gap: 1em;
  p {
    border: 1px solid #2e2daa;
    display: inline-block;
    padding: 0.9em 1.5em;
    border-radius: 5px;
    width: 250px;
    color: #878787;
    font-weight: 700;
    font-size: 0.7em;
    margin-bottom: 1em;
    white-space: nowrap;
    span {
      font-weight: 400;
    }
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    p {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  div {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  color: #ffffff;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ disabled }) => (disabled ? "gray" : "#00cde8")};
  padding: 0.9em 0;
  font-size: 0.7em;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  border: 1px solid ${({ disabled }) => (disabled ? "gray" : "#00cde8")};
  &.btn-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 0.5em 3em;
  }
`;

export const BtnPurchaseSummary = styled.button`
  color: #ffffff;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ disabled }) => (disabled ? "gray" : "#00cde8")};
  padding: 0.9em 0;
  font-size: 0.72em;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  border: 1px solid ${({ disabled }) => (disabled ? "gray" : "#00cde8")};
  &.btn-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 0.5em 3em;
  }
`;

// ORDER SUCCESS
export const OrderSuccesBox = styled.div`
  display: flex;
  gap: 4em;
  @media screen and (max-width: 1000px) {
    img {
      width: 250px;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
    }
  }
`;

export const OrderText = styled.div`
  h4 {
    color: #00cde8;
    font-size: 2em;
  }
  p {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: 900;
  }
  @media screen and (max-width: 900px) {
    span {
      width: 100%;
      display: inline-block;
      margin: 0;
      padding: 0;
    }
    h4,
    p,
    span {
      text-align: center;
    }
  }
`;

//CUPON
export const CupontContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1em;
  margin-top: 1em;
  input {
    grid-column: span 4;
    border: 1px solid #d9d9d9;
  }
  @media screen and (max-width: 650px) {
    gap: 0.5em;
    input {
      grid-column: span 5;
    }
  }
`;

export const BtnCupon = styled.button`
  background-color: #00cde8;
  color: #ffffff;
  border: none;
  display: inline;
  width: 100%;
  grid-column: span 3;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 650px) {
    grid-column: span 5;
  }
`;

export const BtnCuponStatus = styled.button`
  background-color: ${(props) => props.$bgcolor || "#ffffff"};
  color: ${(props) => props.$colorText || "#ffffff"};
  border: none;
  display: inline;
  width: 100%;
  grid-column: span 3;
  border-radius: 5px;
  font-weight: 700;

  @media screen and (max-width: 650px) {
    grid-column: span 10;
    height: 35px;
  }
`;
