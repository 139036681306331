import styled from "styled-components";

export const BannerTopFooterContainer = styled.div`
  background-color: #5352b8;
  width: auto;
`;

export const BannerBox = styled.div`
  padding: 5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
`;

export const ContainerData = styled.div`
  position: relative;
  margin: 0 1em;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const ImageData = styled.div`
  position: absolute;
  color: #ffffff;
  top: 6em;
  left: 1.5em;
  h3 {
    width: 60%;
    font-weight: 700;
    font-size: 2em;
  }
  p {
    margin-top: 1em;
    font-weight: 400;
    font-size: 1em;
  }
  button {
    margin-top: 2em;
    text-transform: uppercase;
    background-color: #5352b8;
    color: white;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    h4 {
      padding: 0.2em 1.5em;
      font-weight: 700;
      font-size: 1.25em;
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    top: 3em;
    left: 1em;
    h3 {
      font-size: 1.5em;
    }
    p {
      font-size: 0.8em;
    }
  }
`;
