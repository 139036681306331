export const types = {
  LOGIN_EXITOSO: "LOGIN_EXITOSO",
  LOGIN_ERROR: "LOGIN_ERROR",
  CERRAR_SESION: "CERRAR_SESION",
  RESET__ERROR: "RESET__ERROR",
  OBTENER_USUARIO: "OBTENER_USUARIO",
  //USER - ANONYMUS
  OBTENER_USUARIO_ANONIMO: "OBTENER_USUARIO_ANONIMO",
  ERROR_USUARIO_ANONIMO: "ERROR_USUARIO_ANONIMO",
  LOADING_USUARIO_ANONIMO: "LOADING_USUARIO_ANONIMO",
  //Product
  LIST_PRODUCT: "LIST_PRODUCT",
  ERROR_LIST_PRODUCT: "ERROR_LIST_PRODUCT",
  LIST_PRODUCT_CAROUSEL: "LIST_PRODUCT_CAROUSEL",
  ERROR_LIST_PRODUCT_CAROUSEL: "ERROR_LIST_PRODUCT_CAROUSEL",
  SEARCH_VALUE_PRODUCT: "SEARCH_VALUE_PRODUCT",
  //Event
  EVENTS_LIST_SUCCESS: "EVENTS_LIST_SUCCESS",
  EVENTS_LIST_ERROR: "EVENTS_LIST_ERROR",
  //PRODUCT_FEATURED
  PRODUCT_FEATURED_LIST_SUCCESS: "PRODUCT_FEATURED_LIST_SUCCESS",
  PRODUCT_FEATURED_ERROR: "PRODUCT_FEATURED_ERROR",
  //Category
  LIST_CATEGORY_FILTER: "LIST_CATEGORY_FILTER",
  ERROR_LIST_CATEGORY_FILTER: "ERROR_LIST_CATEGORY_FILTER",
  CATEGORY_FILTER_HEADER: "CATEGORY_FILTER_HEADER",
  KIND_FILTER: "KIND_FILTER",
  SUB_KIND_FILTER: "SUB_KIND_FILTER",
  //Filter
  LIST_PRODUCT_FILTER: "LIST_PRODUCT_FILTER",
  //Search
  LIST_PRODUCT_SEARCH: "LIST_PRODUCT_SEARCH",
  //Filter price
  LIST_PRODUCT_FILTER_PRICE: "LIST_PRODUCT_FILTER_PRICE",

  /* Producto */
  REGISTRO_EXITOSO_PRODUCTO: "REGISTRO_EXITOSO_PRODUCTO",
  REGISTRO_ERROR_PRODUCTO: "REGISTRO_ERROR_PRODUCTO",

  //Colaborador
  /*REGISTRAR USUARIO*/
  REGISTRO_EXITOSO: "REGISTRO_EXITOSO",
  REGISTRO_ERROR: "REGISTRO_ERROR",
  /*LISTAR COLABORADOR*/
  OBTENER_COLABORADORES: "OBTENER_COLABORADORES",
  OBTENER_ERROR: "OBTENER_ERROR",
  RESETEAR_ERROR_REGISTRO: "RESETEAR_ERROR_REGISTRO",
  RESET_LOADING: "RESET_LOADING",
  COLABORADOR_ACTUAL: "COLABORADOR_ACTUAL",
  COLABORADOR_ACTUAL_RESET: "COLABORADOR_ACTUAL_RESET",
  ACTUALIZAR_INPUT: "ACTUALIZAR_INPUT",
  ACTUALIZAR_COLABORADOR: "ACTUALIZAR_COLABORADOR",
  /* Eliminar */
  ELIMINAR_COLABORADOR: "ELIMINAR_COLABORADOR",

  /**** Carrito ****/
  AGREGAR_CARRO: "AGREGAR_CARRO",
  LISTAR_CART_USER: "LISTAR_CART_USER",
  ERROR_LISTAR_CART_USER: "ERROR_LISTAR_CART_USER",
  ERROR_AGREGAR_CARRO: "ERROR_AGREGAR_CARRO",
  DELETE_CART: "DELETE_CART",
  ERROR_DELETE_CART: "ERROR_DELETE_CART",

  /****  Registrar Anonimus*****/
  REGISTER_ANONIMUS: "REGISTER_ANONIMUS",

  //Oder
  ORDER_PAYMENT_SUCCESS: "ORDER_PAYMENT_SUCCESS",
  ORDER_PAYMENT_ERROR: "ORDER_PAYMENT_ERROR",
  RESET_ORDER_PAYMENT: "RESET_ORDER_PAYMENT",
  RESET_LIST_ORDER: "RESET_LIST_ORDER",
  //CheckCart
  ORDER_CHECK_CART_SUCCESS: "ORDER_CHECK_CART_SUCCESS",
  ORDER_CHECK_CART_ERROR: "ORDER_CHECK_CART_ERROR",
};
