import styled from "styled-components";

export const BannerTopFooterContainer = styled.div`
  background-color: #ffffff;
  width: auto;
  margin-bottom: 1em;
`;

export const BannerBox = styled.div`
  padding: 1em 4em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
`;
export const ContainerData = styled.div`
  position: relative;
`;
export const ImageData = styled.div`
  position: absolute;
  color: #ffffff;
  top: 6em;
  left: 1.5em;
  h3 {
    width: 60%;
    font-weight: 700;
    font-size: 2em;
  }
  p {
    margin-top: 1em;
    font-weight: 400;
    font-size: 1em;
  }
  button {
    margin-top: 2em;
    background-color: #5352b8;
    color: white;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    h4 {
      padding: 0.2em 1.5em;
      font-weight: 700;
      font-size: 1.25em;
      text-align: center;
    }
  }
`;

//View Categoria
export const ContainerCategory = styled.div`
  @media screen and (min-width: 1600px) {
    // padding: 10% 20%;
  }
  /* padding: 10% 10%; */
  margin-top: 155px;
  @media screen and (max-width: 900px) {
    /* margin: 20% 10%;
    margin-top: 155px;
    padding-top: 20%; */
  }
  @media screen and (max-width: 450px) {
    margin-top: 115px;
  }
`;

export const RedirectCategory = styled.div`
  position: relative;
  padding-top: 60px;
  img {
    width: 100%;
    height: 100%;
  }
  div {
    height: 70%;
    width: 100%;
    margin-top: 60px;
    padding-left: 15%;
    position: absolute;
    top: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 0.4em;
    h4 {
      font-size: 1.5em;
      font-weight: 600;
    }
    h6 {
      font-size: 0.9em;
      font-weight: 700;
      margin-top: 0.4em;
      text-transform: capitalize;
    }
    svg {
      margin-right: 3em;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 900px) {
    padding-top: 48px;

    div {
      margin-top: 48px;
      height: 50%;
      h4 {
        font-size: 1em;
      }
      h6 {
        font-size: 0.7em;
        margin-top: 0.2em;
      }
    }
  }
  @media screen and (max-width: 450px) {
    div {
      padding-left: 5%;
      svg {
        margin-right: 1em;
        cursor: pointer;
      }
    }
  }
`;

export const ListCategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3em;
  @media screen and (min-width: 1600px) {
    padding: 2% 20%;
  }
  padding: 5% 10%;
  @media screen and (max-width: 900px) {
    margin: 20% 10%;
  }
  @media screen and (max-width: 450px) {
    margin: 10% 5%;
  }
`;

export const CategorieBox = styled.div`
  div {
    border: 1px solid #cacaca;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    img {
      width: 180px;
      height: 80px;
      object-fit: contain;
      margin: 2em;
    }
    a {
      color: #ffffff;
      text-decoration: none;
      font-weight: 700;
      font-size: 0.95em;
      width: 100%;
      h5 {
        background-color: #00cde8;
        text-align: center;
        padding: 1em 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &.subkins {
      margin-top: 0.3em;
      flex-direction: row;
      justify-content: space-around;
      a {
        color: #000000;
        h6 {
          cursor: pointer;
          text-align: center;
          width: 100%;
          padding: 0.4em 0em;
          font-size: 700;
          font-size: 0.9em;
        }
      }
      span {
        color: #cacaca;
        font-size: 1.5em;
      }
    }
  }
`;

//Category Kind
export const ContainerCategoryKind = styled.div`
  margin: 5% 10%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5em;
  @media (max-width: 750px) {
    display: block;
  }
`;
