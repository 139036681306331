import styled from "styled-components";

export const Overlay = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  @media screen and (max-width: 1000px) {
    position: fixed;
    height: 100%;
  }
  &.modalDelete {
    position: fixed;
  }
  &.card-modal {
    text-align: left;
  }
  &.overlay-yape {
    padding: 0;
  }
`;

export const ContenedorModal = styled.div`
  position: relative;
  max-width: 800px;
  max-height: 700px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px;
  overflow-y: auto;

  &.yape-modal-container {
    padding: 0;
  }

  @media screen and (max-height: 1000px) {
    /* height: 820px; */
    padding: 0;
  }

  @media screen and (max-width: 550px) {
    max-height: 550px;
  }
`;
export const CerrarModalStyle = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background-color: #ffffff;
  svg {
    cursor: pointer;
  }
  &.yape-close {
    background-color: transparent;
    right: 5px;
  }
`;

export const BoxCartContainer = styled.div`
  display: flex;
  padding: 1em 2em;
  img {
    margin: 2em 3em;
    width: 250px;
    object-fit: cover;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 1em 0em;
    align-items: center;
    img {
      margin: 0em 3em;
      width: 10em;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 1em 0em;
    img {
      margin: 0em;
      width: 8em;
      object-fit: cover;
    }
  }
`;

export const BoxDataCart = styled.div`
  margin-top: 2em;
  text-align: justify;
  white-space: pre-line;
  h4 {
    color: #00cde8;
    font-size: 0.95em;
    font-weight: 900;
  }
  h2 {
    text-align: start;
    margin-top: 1em;
    font-size: 1.5em;
    font-weight: 600;
  }
  @media screen and (max-width: 900px) {
    padding: 0 2em;
    margin-bottom: 2em;
    h4 {
      font-size: 1em;
      text-align: center;
    }
    h2 {
      font-size: 1em;
      font-weight: 900;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 0em;
    h2 {
      font-size: 0.8em;
      font-weight: 900;
    }
  }
`;

export const CartModalAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5em;
  gap: 1em;
  h6 {
    font-size: 1em;
    font-weight: 400;
    line-height: 2em;
    letter-spacing: 0em;
  }
  .update-cart {
    display: flex;
    align-items: center;
    padding: 0.65em 2em;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    cursor: pointer;
  }
  .update-detail {
    padding: 0.65em 2em;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  .update-detail-process {
    padding: 0.65em 2em;
    border-radius: 4px;
    border: 1px solid #00cde8;
    background-color: #00cde8;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
  }
  .update-detail-process-id {
    padding: 0.65em 2em;
    border-radius: 4px;
    border: 1px solid #00cde8;
    background-color: #00cde8;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    .update-cart {
      /* padding: 0.65em 8em; */
      width: 100%;
      text-align: center;
      justify-content: center;
      padding: 0.65em 0em;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 1em;
    h2 {
      font-size: 0.5em;
    }
  }
`;

export const BtnAction = styled.button`
  margin-top: 2em;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 1.5em;
  border-radius: 4px;
  color: #ffffff;
  padding: 0.65em 1em;
  font-weight: 800;
  width: 240px;
  cursor: pointer;
  &.btn-eye {
    border: 1px solid #00cde8;
    background-color: #00cde8;
  }
  &.btn-cart {
    margin-top: 1.5em;
    border: 1px solid #2a2a2a;
    background-color: #2a2a2a;
  }
  &.btn-process-update {
    border: 1px solid #5352b8;
    background-color: #5352b8;
  }
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    &.btn-eye,
    &.btn-cart {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 1em;
  }
`;

export const BtnLinkPurchase = styled.div`
  margin-top: 1em;

  a {
    width: 240px;
    font-weight: 800;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background-color: #2a2a2a;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 1.5em;
    padding: 0.65em 1em;
    font-size: 0.85em;
    border-radius: 5px;
  }
  @media screen and (max-width: 900px) {
    a {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    margin-top: 0.5em;
  }
`;
// Yape
export const BoxCartContainerYape = styled.div`
  max-width: 700px;
  width: 680px;
  border-radius: 5px;
  @media screen and (max-width: 800px) {
    width: 560px;
  }
  @media screen and (max-width: 650px) {
    width: 480px;
  }
  @media screen and (max-width: 450px) {
    width: 350px;
  }
  .qr-yape {
    border: 6px solid #ffffff;
    position: relative;
    top: -25px;
  }
`;

export const ModalHeaderYape = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #873f91;
  padding: 1em 0;
  margin: 0;
  padding-bottom: 3em;
  /* @media screen and (max-width: 450px) {
    width: 350px;
  } */
  h2 {
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-family: Montserrat;
    font-size: 1.2em;
    @media screen and (max-width: 650px) {
      font-size: 1em;
    }
  }
`;

export const ModalInfoYape = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;
  @media screen and (max-width: 450px) {
    width: 350px;
  }
  .destino-yape {
    margin-top: -2em;
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
      background-color: #f0f0f0;
      border-radius: 5px;
      padding: 1em 3em;
      text-align: center;
    }
    span {
      font-weight: 400;
    }
  }
  h3 {
    display: inline-block;
    white-space: pre-line;
  }
`;
