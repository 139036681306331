import React, { useContext, useEffect, useState } from "react";
import {
  ContainerDetailProduct,
  DetailActionPoruct,
  DetailActionWish,
  DetailBox,
  DetailDataProduct,
  DetailImage,
  MessageUpdate,
  NoDataContainer,
  ProductDataBox,
  WhatsAppBtn,
} from "../styles/DetailProductStyles";
import { useParams } from "react-router-dom";
import { clienteAxios } from "../config/axios";
import { Loading } from "../components/Loading";
import { StockStatus } from "../components/StockStatus";
// import { MdFavorite } from "react-icons/md";
import { StockSelectProduct } from "../components/StockSelectProduct";
import { IoCart } from "react-icons/io5";
import { SiWhatsapp } from "react-icons/si";
import { ModalAddCart } from "../components/ModalAddCart";
import { HeaderFooter } from "./HeaderFooter";
import { ProductContext } from "../context/product/ProductProvider";
import { CartModalAction } from "../styles/ModalStyles";
import { NoData } from "../components/NoData";

export const DetailProduct = () => {
  const { slug } = useParams();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [valueUpdate, setValueUpdate] = useState(1);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [existItem, setExistItem] = useState(false);

  const { addCartItem, getListCartUser, listCart } = useContext(ProductContext);

  // const [disabledUpdate, setDisabledUpdate] = useState(true);
  const redirectToWhatsApp = () => {
    const paginaActual = window.location.href;
    const urlImagenProducto = `${item.image_principal}`;
    const mensaje = encodeURIComponent(
      `¡Hola! Estoy interesado en este producto: ${paginaActual}. Aquí está la imagen: ${urlImagenProducto}`
    );

    window.open(`https://wa.me/961998673/?text=${mensaje}`, "_blank");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await clienteAxios.get(`product/products/${slug}`);
        setItem(res.data);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setItem(null);
      } finally {
        setLoading(true);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleAddCartUser = async (product) => {
    const isInCart = checkItemInCart(item.combination_data?.id);
    if (isInCart) {
      setIsUpdating(true);
      try {
        const respuesta = await clienteAxios.post("cart/cartitemupgrade/", {
          combination_id: product?.id,
          quantity: valueUpdate,
        });

        setUpdateSuccess(false);
        if (respuesta.status === 200) {
          getListCartUser();
          setUpdateSuccess(true);
          setTimeout(() => {
            setUpdateSuccess(null);
          }, 5000);
          return;
        }
      } catch (error) {
        console.log(error.response);
      } finally {
        setIsUpdating(false);
      }
    } else {
      addCartItem({
        combination: product.id,
        quantity: valueUpdate,
      });
      setModal(true);
    }
  };

  const handleSelectChange = (newValue) => {
    setValueUpdate(newValue);
  };

  const checkItemInCart = (itemIdToCheck) => {
    const cartItemIds = listCart.cart_items_json.cart_items.map(
      (item) => item.combination_id
    );
    const isInCartItems = (itemId) => {
      return cartItemIds.includes(itemId);
    };
    return isInCartItems(itemIdToCheck);
  };

  const updateItemCart = async () => {
    const isInCart = checkItemInCart(item.combination_data?.id);
    if (isInCart) {
      setIsUpdating(true);
      try {
        const respuesta = await clienteAxios.post("cart/cartitemupgrade/", {
          combination_id: item.combination_data?.id,
          quantity: valueUpdate,
        });

        setUpdateSuccess(false);
        if (respuesta.status === 200) {
          getListCartUser();
          setUpdateSuccess(true);
          setTimeout(() => {
            setUpdateSuccess(null);
          }, 5000);
          return;
        }
      } catch (error) {
        console.log(error.response);
      } finally {
        setIsUpdating(false);
      }
    } else {
      setUpdateSuccess(true);
      setExistItem(true);
      setTimeout(() => {
        setUpdateSuccess(null);
        setExistItem(false);
      }, 5000);
      return;
    }
  };

  return (
    <HeaderFooter searchModal={true}>
      <ContainerDetailProduct>
        <ModalAddCart modal={modal} setModal={setModal} product={item} />
        {loading ? (
          item !== null ? (
            <DetailBox>
              <h2>{item.title} </h2>
              <DetailImage>
                <img src={item.image_principal} alt={item.title} />
                <StockStatus quantity={item.combination_data?.quantity} />
              </DetailImage>
              <ProductDataBox>
                <DetailDataProduct>
                  <h2>{item.title} </h2>
                  <h1>S/{item.combination_data?.price}</h1>
                  <p>Contenido:</p>
                  {item.description === "" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.short_description,
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  )}
                </DetailDataProduct>
                <br />
                <DetailActionWish>
                  {/* <button className="btn-wishlist">
                    <MdFavorite color="#00CDE8" size={20} />
                    <p>Añadir a Wishlist</p>
                  </button> */}
                  <DetailActionPoruct>
                    <StockSelectProduct
                      value={item.combination_data?.quantity}
                      stockProduct={1}
                      onChange={handleSelectChange}
                    />
                    <button
                      disabled={
                        item.combination_data?.quantity === 0 ||
                        item.combination_data?.quantity < 0
                      }
                      onClick={() => handleAddCartUser(item.combination_data)}
                      className={
                        item.combination_data?.quantity === 0 ||
                        item.combination_data?.quantity < 0
                          ? "disabled"
                          : ""
                      }
                    >
                      <IoCart color="#ffffff" size={20} />
                      <p>Añadir</p>
                    </button>
                  </DetailActionPoruct>
                  <CartModalAction>
                    <button
                      onClick={updateItemCart}
                      disabled={
                        updateSuccess || item.combination_data?.quantity === 0
                      }
                      className={
                        isUpdating
                          ? "update-detail-process-id"
                          : "update-detail"
                      }
                    >
                      {isUpdating ? "Actualizando..." : "Actualizar"}
                    </button>
                  </CartModalAction>
                  <div>
                    <WhatsAppBtn onClick={redirectToWhatsApp}>
                      <SiWhatsapp size={20} />
                      <p>Comprar por Whatsapp</p>
                    </WhatsAppBtn>
                    {updateSuccess && (
                      <MessageUpdate>
                        {" "}
                        {!existItem ? (
                          <span>Se actualizo correctamente</span>
                        ) : (
                          <span>Primero agrega el producto carrito</span>
                        )}
                      </MessageUpdate>
                    )}
                  </div>
                </DetailActionWish>
              </ProductDataBox>
            </DetailBox>
          ) : (
            <NoDataContainer>
              <NoData />
            </NoDataContainer>
          )
        ) : (
          <Loading />
        )}
      </ContainerDetailProduct>
    </HeaderFooter>
  );
};
