import styled, { keyframes } from "styled-components";

export const ContainerLinks = styled.div`
  padding: 15% 20%;
  margin-top: 155px;
  h2 {
    text-align: center;
    color: #00cde8;
    font-size: 2.25em;
    font-weight: 600;
    letter-spacing: 0em;
  }
  h6 {
    margin-top: 2em;
    color: #00cde8;
    font-size: 0.95em;
    font-weight: 700;
  }
  p {
    margin-top: 1em;
    text-align: justify;
    white-space: pre-line;
    font-size: 0.95em;
    font-weight: 700;
  }
  @media screen and (max-width: 900px) {
    margin: 20% 10%;
    margin-top: 155px;
  }
`;

// about us
export const ContanierAboutUsMain = styled.div`
  padding: 15% 20%;
  margin-top: 155px;
  @media screen and (max-width: 900px) {
    margin: 25% 5%;
    margin-top: 155px;
    margin-bottom: 2em;
  }
  @media screen and (max-width: 500px) {
    margin: 20% 0%;
    margin-top: 155px;
  }
`;

export const ContainerAboutUs = styled.div`
  /* padding: 15% 20%;
  margin-top: 155px; */
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  img {
    max-width: 100%;
    height: auto;
  }
  h1 {
    color: #00cde8;
    letter-spacing: 0em;
    font-size: 2.25em;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
  }
  p {
    margin-top: 1em;
    text-align: justify;
    white-space: pre-line;
    font-size: 0.94em;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }
  h4 {
    font-size: 1.19em;
    font-weight: bolder;
    line-height: 23px;
    letter-spacing: 0em;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    margin-bottom: 2em;
  }
`;

export const MapContainer = styled.div`
  margin-top: 4em;
  margin-bottom: 4em;
  img {
    width: 100%;
    height: auto;
  }
  box-shadow: 0px 0px 12px 4px #0000001a;
  div {
    border-radius: 5px;
  }
`;

// FAQ
export const ContainerFAQ = styled.div`
  margin-top: 155px;
  padding-top: 60px;
  min-height: 70vh;
`;

export const BoxFAQ = styled.div`
  background-color: #00cde8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5em;
  padding: 3em 0;
  color: #ffffff;
  h6 {
    font-size: 1em;
  }
  p {
    font-weight: 400;
    font-size: 0.9em;
  }
  @media screen and (max-width: 1000px) {
    h2 {
      font-size: 1em;
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 1em;
    padding: 2em 0;
    h6 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`;

export const BoxQuestions = styled.div`
  padding: 5% 10%;
  @media screen and (min-width: 1600px) {
    padding: 2% 20%;
  }
  @media screen and (max-width: 900px) {
    margin: 10% 0%;
  }
  @media screen and (max-width: 600px) {
    padding: 5% 2%;
  }
`;

export const AccordionBox = styled.div`
  border: 1px solid #d9d9d9;
  div {
    &.accordion-content {
      padding: 1em 2.5em;
      white-space: pre-line;
      text-align: justify;
      border-bottom: 1px solid #d9d9d9;
      p {
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 0.9em;
      }
      @media screen and (max-width: 600px) {
        padding: 1em 1.2em;
      }
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 2.5em;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  h5 {
    font-size: 1em;
    font-weight: 700;
    color: ${({ $active }) => ($active ? "#ffffff" : "#555555")};
  }
  svg {
    color: ${({ $active }) => ($active ? "#ffffff" : "#555555")};
  }
  background-color: ${({ $active }) => ($active ? "#00CDE8" : "transparent")};
  @media screen and (max-width: 600px) {
    padding: 1em 1.2em;
  }
`;

// Contact
export const ContanierContactMain = styled.div`
  margin-top: 155px;
  padding-top: 60px;
  min-height: 90vh;
`;

export const ContactBox = styled.div`
  padding: 5% 10%;
  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 2em;
  @media screen and (min-width: 1600px) {
    padding: 2% 20%;
  }
  @media screen and (max-width: 1380px) {
    grid-template-columns: 1fr 3fr;
  }
  @media screen and (max-width: 900px) {
    margin: 10% 0%;
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 600px) {
    padding: 5% 2%;
  }
`;

export const ContactMap = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const ContactData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em 2em;
  height: 50px;
  color: #5352b8;
  h4,
  h3 {
    display: flex;
    align-items: center;
    color: #5352b8;
    gap: 0.5em;
  }
  h3 {
    gap: 1.5em;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    place-items: center;
  }
`;

//Loading

export const rotateZ = keyframes`
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
`;

export const BoxLoading = styled.div`
  width: 100%;
  height: 100vh;
  height: ${(props) => props.$height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerLoading = styled.div`
  /* margin: 20px auto; */
  width: ${(props) => props.$loadingWidth};
  height: ${(props) => props.$loadingWidth};
  position: relative;
  transform: rotateZ(45deg);
`;

export const Cube = styled.div`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00cde8;
    animation: ${rotateZ} 2.4s infinite linear both;
    transform-origin: 100% 100%;
  }
`;

export const Cube2 = styled(Cube)`
  transform: scale(1.1) rotateZ(90deg);
`;

export const Cube3 = styled(Cube)`
  transform: scale(1.1) rotateZ(180deg);
`;

export const Cube4 = styled(Cube)`
  transform: scale(1.1) rotateZ(270deg);
`;
