import React, { useState } from "react";
import {
  AccordionBox,
  AccordionHeader,
  BoxFAQ,
  BoxQuestions,
  ContainerFAQ,
} from "../styles/GeneralStyles";
import { FaPlus, FaMinus } from "react-icons/fa";
import { HeaderFooter } from "./HeaderFooter";

const data = [
  {
    question: "¿Cómo comprar por la página web?",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.

    Desde el momento en que pusimos un pie en este universo, nos comprometimos a ofrecer no solo productos de alta calidad, sino también un ambiente acogedor y lleno de energía positiva. Nos esforzamos por ser mucho más que una simple tienda: somos un destino donde los jugadores pueden encontrar todo lo que necesitan para disfrutar al máximo de su pasión.
    
    Nuestro equipo está formado por amantes de los videojuegos, coleccionistas de TCG y fanáticos de los juegos de mesa. Estamos aquí para ayudarte a encontrar ese juego que has estado buscando, para compartir consejos y estrategias, o simplemente para charlar sobre tus juegos favoritos.
    
    En Wonderland Playground, nos comprometemos a ofrecerte una experiencia excepcional en cada visita. ¡Únete a nosotros y adéntrate en un mundo de diversión y aventura!`,
  },

  {
    question: "Pregunta 2",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.
  Desde el momento en que pusimos un pie en este universo, nos comprometimos a ofrecer no solo productos de alta calidad, sino también un ambiente acogedor y lleno de energía positiva. Nos esforzamos por ser mucho más que una simple tienda: somos un destino donde los jugadores pueden encontrar todo lo que necesitan para disfrutar al máximo de su pasión.
  Nuestro equipo está formado por amantes de los videojuegos, coleccionistas de TCG y fanáticos de los juegos de mesa. Estamos aquí para ayudarte a encontrar ese juego que has estado buscando, para compartir consejos y estrategias, o simplemente para charlar sobre tus juegos favoritos.
  En Wonderland Playground, nos comprometemos a ofrecerte una experiencia excepcional en cada visita. ¡Únete a nosotros y adéntrate en un mundo de diversión y aventura!`,
  },
  {
    question: "Pregunta 3",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.`,
  },
  {
    question: "Pregunta 4",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.`,
  },
  {
    question: "Pregunta 5",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.`,
  },
  {
    question: "Pregunta 6",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.`,
  },
  {
    question: "Pregunta 7",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.`,
  },
  {
    question: "Pregunta 8",
    answer: `Somos una tienda apasionada por los videojuegos, Trading Card Games (TCG) y juegos de mesa. En Wonderland Playground, nos encanta llevar experiencias únicas a nuestros clientes, creando un espacio donde puedan sumergirse en emocionantes aventuras y conectar con otros entusiastas del mundo del entretenimiento.`,
  },
];

export const FAQ = () => {
  const [accordions, setAccordions] = useState(
    new Array(data.length).fill(false)
  );

  const toggleAccordion = (index) => {
    const newAccordions = [...accordions];
    // Si el acordeón actual está cerrado, lo abrimos y cerramos los demás
    if (!newAccordions[index]) {
      newAccordions.fill(false); // Cerrar todos los acordeones
      newAccordions[index] = true; // Abrir el acordeón seleccionado
    } else {
      newAccordions[index] = false; // Cerrar el acordeón seleccionado
    }

    setAccordions(newAccordions);
  };
  return (
    <HeaderFooter searchModal={true}>
      <ContainerFAQ>
        <BoxFAQ>
          <h1>Preguntas frecuentes</h1>
          <div>
            <h6>¿Tienes dudas sobre nuestra tienda?</h6>
            <p>Aquí las resolvemos todas :)</p>
          </div>
        </BoxFAQ>
        <BoxQuestions>
          <AccordionBox>
            {data.map((item, index) => (
              <div key={index} className="accordion">
                <AccordionHeader
                  className="accordion-header"
                  onClick={() => toggleAccordion(index)}
                  $active={accordions[index]}
                >
                  <h5>{item.question}</h5>
                  {accordions[index] ? (
                    <FaMinus size={13} />
                  ) : (
                    <FaPlus size={13} />
                  )}
                </AccordionHeader>
                {accordions[index] && (
                  <div className="accordion-content">
                    {item.answer.split("\n").map((paragraph, idx) => (
                      <p key={idx}>{paragraph}</p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </AccordionBox>
        </BoxQuestions>
      </ContainerFAQ>
    </HeaderFooter>
  );
};
